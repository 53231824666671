import { Constants } from "@eagerdog/constants";
import { IEventRegisterRow } from "../../../services/helper.service.js";

export namespace DockJumping {
  export const form: IEventRegisterRow[] = [
    {
      allowDuplicates: false,
      inputs: [
        {
          type: "checkbox",
          label: Constants.dog_class_element.dock_jumping_air,
          multiSelect: true,
          options: [
            {
              value: Constants.dog_class_element_level.dock_jumping_splash_1,
              id: Constants.dog_class_element_level.dock_jumping_splash_1,
            },
            {
              value: Constants.dog_class_element_level.dock_jumping_splash_2,
              id: Constants.dog_class_element_level.dock_jumping_splash_2,
            },
            {
              value: Constants.dog_class_element_level.dock_jumping_splash_3,
              id: Constants.dog_class_element_level.dock_jumping_splash_3,
            },
            {
              value: Constants.dog_class_element_level.dock_jumping_splash_4,
              id: Constants.dog_class_element_level.dock_jumping_splash_4,
            },
            {
              value: Constants.dog_class_element_level.dock_jumping_splash_5,
              id: Constants.dog_class_element_level.dock_jumping_splash_5,
            },
            {
              value: Constants.dog_class_element_level.dock_jumping_splash_6,
              id: Constants.dog_class_element_level.dock_jumping_splash_6,
            },
          ],
        },
      ],
    },
    {
      allowDuplicates: false,
      inputs: [
        {
          type: "checkbox",
          label: Constants.dog_class_element.dock_jumping_fetch_it,
          multiSelect: true,
          options: [
            {
              value: Constants.dog_class_element_level.dock_jumping_fetch_it,
              id: Constants.dog_class_element_level.dock_jumping_fetch_it,
            },
          ],
        },
      ],
    },
    {
      allowDuplicates: false,
      inputs: [
        {
          type: "checkbox",
          label: Constants.dog_class_element.dock_jumping_vertical,
          multiSelect: true,
          options: [
            {
              value: Constants.dog_class_element_level.dock_jumping_vertical,
              id: Constants.dog_class_element_level.dock_jumping_vertical,
            },
          ],
        },
      ],
    },
  ];
}
