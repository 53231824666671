import React, { useState } from "react";
import {IShow, IRunningOrder, IShowRing  } from "@eagerdog/interfaces";

import Button from "src/components/Button/Button";
import Checkbox from "src/components/Checkbox/Checkbox";

import styles from "./SelectJudges.module.scss";

interface IProps {
  show: IShow,
  onFinish(judges: ISelectedJudge[]): void
}

export interface ISelectedJudge {
  judge_name: string,
  judge_number: string
}

const SelectJudges: React.FC<IProps> = (props) => {
  const [judges, setJudges] = useState<ISelectedJudge[]>([]);

  const ringHasJudges = (r: IShowRing) => {
    return r.running_order && r.running_order.length > 0;
  }

  const hasJudge = (name: string, num: string) => {
    let _judges:ISelectedJudge[] = JSON.parse(JSON.stringify(judges));
    return _judges.filter((j: ISelectedJudge, index: number) => { return j.judge_name === name && j.judge_number === num; });
  }

  const toggleJudge = (name: string, num: string) => {
    let _judges:ISelectedJudge[] = JSON.parse(JSON.stringify(judges));

    let _hasJudge = hasJudge(name, num);

    if (_hasJudge.length > 0) {
      _judges.splice(_judges.findIndex((j: ISelectedJudge) => { return j.judge_number === num && j.judge_name === name; }), 1);
    } else {
      _judges.push({
        judge_name: name,
        judge_number: num
      });
    }

    setJudges(_judges);
  }

  return (
    <div className={styles.SelectJudges}>
      <div className={styles.title}>Select Judges</div>
      {props.show.rings?.map((r: IShowRing, index: number) => {
        if (ringHasJudges(r)) {
          return (
            <div className={styles.ringWrap} key={r.ring+index}>
              <div className={styles.ringName}>{r.ring}</div>
              <div className={styles.judges}>
                {r.running_order?.map((ro: IRunningOrder, roIndex: number) => {
                  return (
                    <div key={ro.judge_name+roIndex} className={styles.judge}>
                      <Checkbox id={ro.judge_name+ro.judge_number} onChange={(e) => { if (ro.judge_number) { toggleJudge(ro.judge_name, ro.judge_number); } }} value={ro.judge_number ? hasJudge(ro.judge_name, ro.judge_number).length > 0 : false} label={ro.judge_name + " (" + ro.judge_number + ")"} />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
      <div className={styles.actions}>
        <Button disabled={judges.length === 0} onClick={() => { props.onFinish(judges); }}>Confirm</Button>
      </div>
    </div>
  );
};

export default SelectJudges;