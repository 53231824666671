// ---------------
const isBrowser =
  typeof window !== "undefined" && typeof window.document !== "undefined";

// ---------------
export const Config = {
  temp: {
    incentive_number_of_show_types_prefix:
      "TEMP_INCENTIVE_NUMBER_OF_SHOW_TYPES_",

    incentive_number_of_entries_prefix: "TEMP_INCENTIVE_NUMBER_OF_ENTRIES_",
    incentive_number_of_entries_fee_percentage_prefix:
      "TEMP_INCENTIVE_NUMBER_OF_ENTRIES_FEE_PERCENTAGE_",

    incentive_number_of_entries_per_event_number_prefix:
      "TEMP_INCENTIVE_NUMBER_ENTRIES_PER_EVENT_NUMBER_",

    incentive_junior_account_prefix: "TEMP_INCENTIVE_JUNIOR_ACCOUNT_",

    incentive_number_of_shows_prefix: "TEMP_INCENTIVE_NUMBER_OF_SHOWS_",
    //
    incentive_event_handles: process.env.TEMP_INCENTIVE_EVENT_HANDLES || "",
    incentive_min_show_types: parseInt(
      process.env.TEMP_INCENTIVE_MIN_SHOW_TYPES || "3"
    ),
    incentive_fee: parseInt(process.env.TEMP_INCENTIVE_FEE || "25"),
    extra_form_event_handle: process.env.TEMP_EXTRA_FORM_EVENT_HANDLE || "",
    extra_form_file_name: process.env.TEMP_EXTRA_FORM_FILE_NAME || "",
  },

  partner: {
    club_ids: (process.env.EAGERDOG_PARTNER_CLUB_IDS || "").split(","),
  },

  auth: {
    // JWT siging options for access token
    access_token_options: {
      secret: process.env.EAGERDOG_ACCESS_TOKEN_SECRET,
      expiresIn: process.env.EAGERDOG_ACCESS_TOKEN_DURATION || "5m",
    },

    // JWT siging options for refresh token
    refresh_token_options: {
      secret: process.env.EAGERDOG_REFRESH_TOKEN_SECRET,
      expiresIn: process.env.EAGERDOG_REFRESH_TOKEN_DURATION || "60m",
    },

    password_reset_token_options: {
      secret: process.env.EAGERDOG_PASSWORD_RESET_TOKEN_SECRET,
    },
  },

  // -------------
  eagerdog_api: {
    db_url: process.env.EAGERDOG_API_DB_URL || "",
    port: parseInt(process.env.EAGERDOG_API_PORT || "3001"),

    payload_max_size: process.env.EAGERDOG_API_PAYLOAD_MAX_SIZE || "1mb",
  },

  // -------------
  eagerdog_webhooks_api: {
    port: parseInt(process.env.EAGERDOG_WEBHOOKS_API_PORT || "3001"),
  },

  // -------------
  eagerdog_cron_ms: {
    publish_events_cron:
      process.env.EAGERDOG_PUBLISH_EVENTS_CRON || "0 0-23/1 * * *", //every hour
  },

  knock: {
    api_key: process.env.EAGERDOG_KNOCK_API_KEY || "",
  },

  // ------------
  eagerdog_notifications_ms: {
    transport_options: {
      // TCP = 0,
      // REDIS = 1,
      // NATS = 2,
      // MQTT = 3,
      // GRPC = 4,
      // RMQ = 5,
      // KAFKA = 6
      //transport: 0, //TCP
      transport: 5,
      // options: {
      //   host: process.env.DATA_DICT_CACHE_API_URL,
      //   port: parseInt(process.env.DATA_DICT_CACHE_API_PORT || '3000')
      // }
      options: {
        urls: [process.env.EAGERDOG_API_RMQ_URL || "amqp://localhost:5672"],
        queue:
          process.env.EAGERDOG_NOTIFICATIONS_MS_QUEUE_NAME || "notifications",
        queueOptions: {
          durable: false,
        },
      },
    },
  },

  // ------------
  eagerdog_forms_ms: {
    transport_options: {
      // TCP = 0,
      // REDIS = 1,
      // NATS = 2,
      // MQTT = 3,
      // GRPC = 4,
      // RMQ = 5,
      // KAFKA = 6
      //transport: 0, //TCP
      transport: 5,
      // options: {
      //   host: process.env.DATA_DICT_CACHE_API_URL,
      //   port: parseInt(process.env.DATA_DICT_CACHE_API_PORT || '3000')
      // }
      options: {
        urls: [process.env.EAGERDOG_API_RMQ_URL || "amqp://localhost:5672"],
        queue: process.env.EAGERDOG_FORMS_MS_QUEUE_NAME || "forms",
        noAck: false,
        prefetchCount: 1, // maximum 1 forms processed at once
        queueOptions: {
          durable: false,
        },
      },
    },
  },

  aws_s3: {
    client_options: {
      accessKeyId: process.env.EAGERDOG_AWS_S3_ACCESS_KEY,
      secretAccessKey: process.env.EAGERDOG_AWS_S3_SECRET_KEY,
      region: process.env.EAGERDOG_AWS_S3_REGION,
    },
    bucket: process.env.EAGERDOG_AWS_S3_BUCKET,
    acl: process.env.EAGERDOG_AWS_S3_ACL,
    max_file_size_mb: parseInt(
      process.env.EAGERDOG_AWS_S3_MAX_FILE_SIZE_MB || "50"
    ),
  },

  // Temporary storage (forms)
  aws_s3_temp: {
    client_options: {
      accessKeyId: process.env.EAGERDOG_AWS_S3_TEMP_ACCESS_KEY,
      secretAccessKey: process.env.EAGERDOG_AWS_S3_TEMP_SECRET_KEY,
      region: process.env.EAGERDOG_AWS_S3_TEMP_REGION,
    },
    bucket: process.env.EAGERDOG_AWS_S3_TEMP_BUCKET,
    acl: process.env.EAGERDOG_AWS_S3_TEMP_ACL,
    max_file_size_mb: parseInt(
      process.env.EAGERDOG_AWS_S3_TEMP_MAX_FILE_SIZE_MB || "50"
    ),
  },

  stripe: {
    api_key: process.env.EAGERDOG_STRIPE_SECRET_KEY || "",
    connect_webhook_secret:
      process.env.EAGERDOG_STRIPE_CONNECT_WEBHOOK_SECRET || "",
    account_webhook_secret:
      process.env.EAGERDOG_STRIPE_ACCOUNT_WEBHOOK_SECRET || "",
  },

  pricing: {
    // <=0 to disable
    max_application_fee: parseInt(
      process.env.EAGERDOG_MAX_APPLICATION_FEE_CENTS || "2500"
    ),
  },

  //arm_number_config_prefix: "EAGERDOG_ARM_NUMBER", //EAGERDOG_ARM_NUMBER_AKC_Conformation=700

  puppeteer: {
    // needs to be adjusted for a docker image
    browser_options: {
      //headless: true,
      headless: "new",
      executablePath: process.env.EAGERDOG_PUPPETEER_BROWSER_PATH,
      args: ["--no-sandbox"],
    },
  },

  web: {
    HOST: "0.0.0.0",
    REACT_APP_API_URL: isBrowser
      ? eval("window._env_.REACT_APP_API_URL")
      : process.env.REACT_APP_API_URL,
    REACT_APP_WEB_URL: isBrowser
      ? eval("window._env_.REACT_APP_WEB_URL")
      : process.env.REACT_APP_WEB_URL,
    REACT_APP_STRIPE_PUBLIC_KEY: isBrowser
      ? eval("window._env_.REACT_APP_STRIPE_PUBLIC_KEY")
      : process.env.REACT_APP_STRIPE_PUBLIC_KEY,

    NODE_ENV: "development",
  },
};
