import React from 'react';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import './DatePick.scss';

interface IProps {
  onChange: any,
  value: any,
  label?: string,
  minDate?: Date,
  maxDate?: Date,
  disabled?: boolean,
  showTimeSelect?: boolean,
  className?: string,
  required?: boolean
}

const DatePick: React.FC<IProps> = ({
                                      onChange,
                                      value,
                                      label = "Date",
                                      minDate = undefined,
                                      maxDate = undefined,
                                      disabled = false,
                                      showTimeSelect = false,
                                      className = undefined,
                                      required = false,
                                    }) => {
  return (
    <div className={className ? "DatePick " + className : "DatePick"}>
      <div className="label"><span>{label} (MM/DD/YYYY)</span></div>
      <div className="dateInner">
        <DatePicker
          required={required}
          minDate={minDate ? new Date(minDate) : undefined}
          maxDate={maxDate ? new Date(maxDate) : undefined}
          dateFormat={showTimeSelect ? "MM/dd/yyyy h:mm a" : "MM/dd/yyyy"}
          showTimeSelect={showTimeSelect}
          timeIntervals={15}
          disabled={disabled}
          selected={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

export default DatePick;