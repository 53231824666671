import React, { useState } from "react";
import { Constants } from "@eagerdog/constants";
import {IShow } from "@eagerdog/interfaces";

import Dropdown, { IOption } from "src/components/Dropdown/Dropdown";
import Button from "src/components/Button/Button";

import styles from "./SelectRing.module.scss";

interface IProps {
  currentRing: string,
  show: IShow,
  onFinish(ring: string): void
}

const SelectRing: React.FC<IProps> = (props) => {
  const [ring, setRing] = useState<IOption>({ id: Constants.none, value: "Select a Ring" });

  return (
    <div className={styles.SelectRing}>
      <div className={styles.title}>Move Judge to Ring</div>
      <Dropdown value={ring} onChange={(e: any, value :IOption) => { setRing(value); }} label="New Ring" options={props.show.rings?.filter((ring: any) => ring.ring !== props.currentRing).map((ring: any) => { return { id: ring.ring, value: ring.ring }}) || []} placeholder="How many events do you run per year?" />
      <div className={styles.actions}>
        <Button disabled={ring.id === Constants.none} onClick={() => { props.onFinish(ring.id); }}>Confirm</Button>
      </div>
    </div>
  );
};

export default SelectRing;