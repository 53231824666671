import React, { useEffect, useState } from "react";
import { Constants } from "@eagerdog/constants";
import { PrecisionCoursingScoreDto } from "@eagerdog/interfaces";

import Dropdown, { IOption } from "src/components/Dropdown/Dropdown";
import StopWatch from "../StopWatch/StopWatch";

import styles from "./PrecisionCoursingResults.module.scss";

interface IProps {
  result: any,
  onChange(result: PrecisionCoursingScoreDto): void
}

const PrecisionCoursingResults: React.FC<IProps> = (props) => {
  const getOptionLabel = (id: string) => {
    switch (id) {
      case Constants.score_result_type.qualified:
        return "Qualified";
      case Constants.score_result_type.not_qualified:
        return "Not Qualified";
      case Constants.score_result_type.disqualified:
        return "Disqualified";
      case Constants.score_result_type.excused:
        return "Excused";
      case Constants.score_result_type.absent:
        return "Absent";
    }

    return "None";
  }

  const [result, setResult] = useState<IOption>({ id: props.result.precision_coursing?.result_type ? props.result.precision_coursing?.result_type : "None", value: props.result.precision_coursing?.result_type ? getOptionLabel(props.result.precision_coursing?.result_type) : "None" });
  const [time, setTime] = useState<string>(props.result.precision_coursing?.search_time ? props.result.precision_coursing?.search_time.toString() : "");
  const [hasChanged, setHasChanged] = useState<boolean>(false);

  const resultOptions:IOption[] = [
    { id: "None", value: "None" },
    { id: Constants.score_result_type.qualified, value: "Qualified" },
    { id: Constants.score_result_type.not_qualified, value: "Not Qualified" },
    { id: Constants.score_result_type.disqualified, value: "Disqualified" },
    { id: Constants.score_result_type.absent, value: "Absent" }
  ]

  let { onChange } = props;

  useEffect(() => {
    if (hasChanged) {
      let changedResult:any = {
        result_type: result.id
      };

      if (result.id === Constants.score_result_type.qualified) {
        changedResult.search_time = time;
      }

      onChange(changedResult);

      setHasChanged(false);
    }
  }, [hasChanged, onChange, result.id, time]);

  return (
    <div className={styles.PrecisionCoursingResults}>
      <div className={styles.row}><Dropdown value={result} onChange={(e: any, value: IOption) => { setResult(value); setHasChanged(true); }} label="Result" options={resultOptions} placeholder="" /></div>
      {result.id === Constants.score_result_type.qualified && <div className={styles.row}><StopWatch time={time} onChange={(value: string) => { setTime(value); setHasChanged(true); }} /></div>}
    </div>
  );
};

export default PrecisionCoursingResults;