import { IShowElementExercise } from "@eagerdog/interfaces";
import Checkbox from "src/components/Checkbox/Checkbox";

//import { apiService } from "src/services";

import styles from "./ExerciseItem.module.scss";

interface IProps {
  index: number,
  exercise: IShowElementExercise,
  onDisable(): void,
  allowDisable?: boolean
}

const ExerciseItem: React.FC<IProps> = (props) => {
  const isNotDisabled = () => {
    return (props.exercise.disabled === false) || false;
  }

  return (
    <div className={isNotDisabled() ? `${styles.ExerciseItem}` : `${styles.ExerciseItem} ${styles.disabled}`}>
      <div className={styles.handleWrap}>
        <div className={`${styles.handle} ${styles.icon}`}>
        </div>
      </div>
      <div className={styles.details}>
        <span className={styles.index}>{props.index + 1}. </span>
        {props.exercise.sign && <span className={styles.sign}>({props.exercise.sign})</span>}
        <span className={styles.name}>{props.exercise.exercise}</span>
      </div>
      {props.allowDisable !== false && <div className={styles.disabled}>
        <Checkbox onChange={(e) => { props.onDisable(); }} value={isNotDisabled()} id={props.exercise.exercise+"-disabled"} label="" />
      </div>}
    </div>
  );
};

export default ExerciseItem;