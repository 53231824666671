import React from 'react';
import { Constants } from "@eagerdog/constants";

import ContextMenu/*, { IContextMenuOption }*/ from "src/components/ContextMenu/ContextMenu";
import { toast } from "src/components/Toast/ToastManager";
import { Modal, useModal } from "src/components/Modal/Modal";
import Confirm from "src/components/Confirm/Confirm";

import { apiService } from "../../../services";
import type { CustomCellRendererProps } from 'ag-grid-react';

import styles from "./WaitlistTab.module.scss";

interface CustomUserWaitlistCellRendererProps extends CustomCellRendererProps {
  getWaitlists(): void
}

export const ActionsRenderer = (props: CustomUserWaitlistCellRendererProps) => {
  const { isShown, toggle } = useModal();

  return (<div className={styles.ActionRenderer}>
    {props.value.waitlist.status !== Constants.waitlist_status.registered && <ContextMenu
      options={[
        { 
          label: "Approve",
          onClick:() => {
            apiService.createRegistrationFromWaitlist(props.value.waitlist.event_id, props.value.waitlist._id).then((response) => {
              props.getWaitlists();

              toast.show({
                title: "Waitlist Approval",
                content: "You've successfully approved "+ props.value.waitlist.call_name +" (" + props.value.waitlist.registered_name + ") from the waitlist",
                duration: 10000,
                type: "success"
              });
            }).catch((e: any) => {
              toast.show({
                title: "Waitlist Approval",
                content: e.response?.data?.message ? e.response.data.message : "Something went wrong, please try again later",
                duration: 10000,
                errorDetails: e,
                type: "fail"
              });
            });
          }
        }, { 
          label: "Cancel",
          onClick:() => {
            toggle();
          }
        }
      ]}
    />}
    <Modal
      className="confirmModal"
      isShown={isShown}
      hide={toggle}
      modalContent={<Confirm
        message={<><p>Are you sure you want to cancel {props.value.waitlist.call_name} ({props.value.waitlist.registered_name})'s waitlist registration to {props.value.waitlist.show_type} {props.value.waitlist.show_name} ({props.value.waitlist.dog_class.show_element}, {(props.value.waitlist.dog_class.level || "No Level")})? All expenses will be refunded to the user from your stripe account.</p></>}
        onConfirm={() => {
          apiService.cancelEventWaitlistEntry(props.value.waitlist.event_id, props.value.waitlist._id).then((response) => {
            toast.show({
              title: "Cancel Waitlist Registration",
              content: "You've successfully canceled "+ props.value.waitlist.call_name +" (" + props.value.waitlist.registered_name +")'s waitlist registration",
              duration: 10000,
              type: "success"
            });

            props.getWaitlists();
            toggle();
          }).catch((e: any) => {
              toast.show({
                title: "Waitlist Withdraw",
                content: e.response.data.message ? e.response.data.message : "Something went wrong, please try again later",
                duration: 10000,
                type: "fail"
              });
          });

          toggle();
        }}
        onCancel={() => {
          toggle();
        }}
      />}
    />
  </div>);
};
