import React, { useEffect, useState } from "react";
import { Constants } from "@eagerdog/constants";
import { DockJumpingScoreDto } from "@eagerdog/interfaces";

import Input from "src/components/Input/Input";
import Dropdown, { IOption } from "src/components/Dropdown/Dropdown";

import "./DockJumpingResults.scss";

interface IProps {
  result: any,
  onChange(result: DockJumpingScoreDto): void
}

const DockJumpingResults: React.FC<IProps> = (props) => {
  const [hasChanged, setHasChanged] = useState<boolean>(false);

  const getOptionLabel = (id: string) => {
    switch (id) {
      case Constants.score_result_type.qualified:
        return "Qualified";
      case Constants.score_result_type.not_qualified:
        return "Not Qualified";
      case Constants.score_result_type.disqualified:
        return "Disqualified";
      case Constants.score_result_type.excused:
        return "Excused";
      case Constants.score_result_type.absent:
        return "Absent";
    }

    return "None";
  }

  const [result, setResult] = useState<IOption>({ id: props.result.dock_jumping?.result_type ? props.result.dock_jumping?.result_type : "None", value: props.result.dock_jumping?.result_type ? getOptionLabel(props.result.dock_jumping?.result_type) : "None" });
  const [feet, setFeet] = useState<string>(props.result.dock_jumping?.feet ? props.result.dock_jumping?.feet.toString() : "0");
  const [inches, setInches] = useState<string>(props.result.dock_jumping?.inches ? props.result.dock_jumping?.inches.toString() : "0");

  const resultOptions:IOption[] = [
    { id: "None", value: "None" },
    { id: Constants.score_result_type.qualified, value: "Qualified" },
    { id: Constants.score_result_type.not_qualified, value: "Not Qualified" },
    { id: Constants.score_result_type.disqualified, value: "Disqualified" },
    { id: Constants.score_result_type.excused, value: "Excused" },
    { id: Constants.score_result_type.absent, value: "Absent" }
  ]

  useEffect(() => {
    if (hasChanged) {
      props.onChange({
        result_type: result.id,
        feet: parseInt(feet),
        inches: parseInt(inches)
      });

      setHasChanged(false);
    }
  }, [hasChanged, props, feet, inches, result.id]);

  const getResultLevel = () => {
    return props.result.dog_class?.level;
  }

  const getDistanceInputs = (label?: string) => {
    return(
      <div className="distanceWrap">
        <Input type="text" required onChange={(e:any) => { setFeet(e.target.value); setHasChanged(true); }} label={label ? "Feet " + label : "Feet"} placeholder="0" defaultValue={feet} />
        <Input type="text" required onChange={(e:any) => { setInches(e.target.value); setHasChanged(true); }} label={label ? "Inches " + label : "Inches"} placeholder="0" defaultValue={inches} />
      </div>
    );
  }

  const getResultForm = (level: string) => {
    let form:any = <></>;

    switch(level) {
      case Constants.dog_class_element.dock_jumping_air:
      case Constants.dog_class_element_level.dock_jumping_splash_1:
      case Constants.dog_class_element_level.dock_jumping_splash_2:
      case Constants.dog_class_element_level.dock_jumping_splash_3:
      case Constants.dog_class_element_level.dock_jumping_splash_4:
      case Constants.dog_class_element_level.dock_jumping_splash_5:
      case Constants.dog_class_element_level.dock_jumping_splash_6:
        form = <>
          {getDistanceInputs("(Distance)")}
        </>;
      break;
      case Constants.dog_class_element.dock_jumping_vertical:
        form = <>
          {getDistanceInputs("(Height)")}
        </>;
      break;
      case Constants.dog_class_element.dock_jumping_fetch_it:
        form = <>
          {getDistanceInputs("(Height)")}
        </>;
      break;
    }
    return form;
  }

  return (
    <div className="DockJumpingResults">
      <div className="row"><Dropdown value={result} onChange={(e: any, value: IOption) => { setResult(value); setHasChanged(true); }} label="Result" options={resultOptions} placeholder="" /></div>
      {getResultForm(getResultLevel())}
    </div>
  );
};

export default DockJumpingResults;