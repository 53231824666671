import React, { useEffect, useState, useCallback } from "react";
import { AxiosError } from "axios";
import { IShowRegistrationSummary, IShow, IDogClass, IEvent, IBreed, IAddress, IDogCoownerInfo } from "@eagerdog/interfaces";
import { Constants } from "@eagerdog/constants";

import { apiService } from "src/services/api.service";
import { nonLicensed, dogObedienceHeights, dogRallyHeights, dogHeightTypes } from "src/services/helper.service";

import num from "src/assets/icons/num.svg";
import scale from "src/assets/icons/scale.svg";
import star from "src/assets/icons/star.svg";

import StepForm, { Step, useStepForm } from "src/components/StepForm/StepForm";
import FormBuilder from "src/components/FormBuilder/FormBuilder";
import Input from "src/components/Input/Input";
import Dropdown, { IOption } from "src/components/Dropdown/Dropdown";
import DatePick from "src/components/DatePick/DatePick";
import SearchDown from "src/components/SearchDown/SearchDown";
import OwnerList from "src/components/OwnerList/OwnerList";
import UserAddress, { isValidAddress, isValidPhone, IAddressError } from "src/routes/EventRegister/UserAddress/UserAddress";
import { toast } from "src/components/Toast/ToastManager";

import "./EditRegistration.scss";

interface IProps {
  event: IEvent | undefined,
  registration: IShowRegistrationSummary,
  onComplete(): void
}

const EditRegistration: React.FC<IProps> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const { switchToTab, activeTab } = useStepForm();

  const [show, setShow] = useState<IShow>();
  const [dogClasses, setDogClasses] = useState<any[]>([]);
  const [isUpdatingRegistration, setIsUpdatingRegistration] = useState<boolean>(false);

  const [callName, setCallName] = useState<string>(props.registration.dog.call_name);
  const [name, setName] = useState<string>(props.registration.dog.registered_name);

  const [sire, setSire] = useState<string>(props.registration.dog.sire || "");
  const [dam, setDam] = useState<string>(props.registration.dog.dam || "");
  const [finalResponse, setFinalResponse] = useState<string>(props.registration.dog.final_response || "");
  const [countryOfBirth, setCountryOfBirth] = useState<string>(props.registration.dog.country_of_birth || "");

  const [jumpHeightType, setJumpHeightType] = useState<IOption>({ id: props.registration.dog.jump_height_type || "None", value: props.registration.dog.jump_height_type || "None" });
  const [oJumpHeight, setOJumpHeight] = useState<IOption>({ id: props.registration.dog.obedience_jump_height?.toString() || "", value: props.registration.dog.obedience_jump_height?.toString() + '"' });
  const [rJumpHeight, setRJumpHeight] = useState<IOption>({ id: props.registration.dog.rally_jump_height?.toString() || "", value: props.registration.dog.rally_jump_height?.toString() + '"' });

  const [breeder, setBreeder] = useState<string>(props.registration.dog.breeder || "");

  const [regNum, setRegNum] = useState<string>(props.registration.dog.registration_number || "");
  const [regPrefix, setRegPrefix] = useState<IOption>({ id: props.registration.dog.registration_number_type || "None", value: props.registration.dog.registration_number_type || "None" });

  const [dogDOB, setDogDOB] = useState(new Date(props.registration.dog.dob) || new Date());
  const [gender, setGender] = useState<string>(props.registration.dog.sex || "");

  const [weight, setWeight] = useState<number | undefined>(props.registration.dog.weight || undefined);
  const [heightAtWithers, setHeightAtWithers] = useState<number | undefined>(props.registration.dog.height_at_withers || undefined);

  const [selectedBreed, setSelectedBreed] = useState<IBreed>();
  const [breedSearch, setBreedSearch] = useState<string>(props.registration.dog.breed || "");
  const [breedList, setBreedList] = useState<IBreed[]>([]);
  const [variety, setVariety] = useState<IOption>(props.registration.dog.variety ? { id: props.registration.dog.variety, value: props.registration.dog.variety } : { id: "None", value: "None" });

  const [displayName, setDisplayName] = useState<string>(props.registration.owner.display_name || "");
  const [email, setEmail] = useState<string>(props.registration.owner.email || "");

  const [address, setAddress] = useState<IAddress>(props.registration.owner.address || {});
  const [phone, setPhone] = useState<string>(props.registration.owner.phone || "");

  const [coOwners, setCoOwners] = useState<IDogCoownerInfo[]>([{
    coowner_type: "owner",
    display_name: "",
    address: {
      line1: "",
      line2: "",
      city: "",
      country: ""
    },
    email: "",
    phone: ""
  }]);

  const [steps, setSteps] = useState<string[]>(["Dog", "Registration", "Owner"]);

  const regPrefixOptions:IOption[] = [
    { id: Constants.registration_number_type.ukc_registered, value: Constants.registration_number_type.ukc_registered },
    { id: Constants.registration_number_type.ukc_temporary, value: Constants.registration_number_type.ukc_temporary },
    { id: Constants.registration_number_type.ukc_performance, value: Constants.registration_number_type.ukc_performance },
    { id: Constants.registration_number_type.akc_registered, value: Constants.registration_number_type.akc_registered },
    { id: Constants.registration_number_type.akc_pal, value: Constants.registration_number_type.akc_pal },
    { id: Constants.registration_number_type.akc_foreign_reg, value: Constants.registration_number_type.akc_foreign_reg }
  ];

  const [originalClasses, setOriginalClasses] = useState<any[]>();

  useEffect(() => {
    const createId = () => {
      return Math.random().toString(36).substring(2, 8);
    }

    const getDogClasses = () => {
      let dogClasses = JSON.parse(JSON.stringify(props.registration.dog_classes));
      let nonLicensedId:string = "";

      for (let d in dogClasses) {
        if (nonLicensed.includes(dogClasses[d].show_element)) {
          if (nonLicensedId.length > 0) {
            dogClasses[d].id = nonLicensedId;
          } else {
            let _id = createId();
            dogClasses[d].id = _id;
            nonLicensedId = _id;
          }
        } else {
          if (dogClasses[d].show_element === Constants.show_type.fast_cat) {
            dogClasses[d].id = Constants.show_type.fast_cat;
            dogClasses[d].time_slot = props.registration.time_slot;
          } else {
            dogClasses[d].id = createId();
          }
        }
      }

      return dogClasses;
    }

    const searchBreeds = () => {
      return new Promise<IBreed[]>((resolve) => {
        apiService.getBreeds().then((response) => {
          let breeds:IBreed[] = []; 

          response.forEach((b: IBreed) => {
            breeds.push(b);
          });

          resolve(breeds);
        }).catch((e: AxiosError) => {
          toast.show({
            title: "Dog Breeds",
            content: "Unable to get dog breeds",
            duration: 10000,
            errorDetails: e,
            type: "fail"
          });

          resolve([]);
        });
      });
    }

    if (!loaded) {
      if (props.event?.sanctioning_club === Constants.sanctioning_club.AKC) {
        setSteps(["Dog", "Co-Owners", "Registration", "Owner"]);
      }

      apiService.getClubEventShows(props.registration.club_id, props.registration.event_id, {
        query: {
          $and: [{ 
            attribute_name: "_id",
            attribute_value: {
               operator: "$eq",
               value: props.registration.show_id
            }
          }]
        }
      }).then((response) => {
        if (response.length > 0) {
          setShow(response[0]);
        }
      });

      const loadBreeds = async() => {
        let breeds:IBreed[] = await searchBreeds();
        setBreedList(breeds);

        if (props.registration.dog.breed) {
          setBreedSearch(props.registration.dog.breed);

          let hasBreed = breeds.filter((b: IBreed) => { return b.breed === props.registration.dog.breed });

          if (hasBreed.length > 0) {
            setSelectedBreed(hasBreed[0]);
          }
        }
      }

      loadBreeds();
      setCoOwners(props?.registration.coowners || []);
      setDogClasses(getDogClasses());

      setLoaded(true);
    }
  }, [loaded, props.registration, props.event?.sanctioning_club]);

  const isValid = () => {
    if (!hasBreed()) {
      toast.show({
        title: "Dog Breed",
        content: "Please select a dog breed from the dropdown menu",
        duration: 10000,
        type: "fail"
      });

      switchToTab(0);
      return false;
    }

    if (!hasVariety()) {
      toast.show({
        title: "Dog Variety",
        content: "Please select a dog variety from the dropdown menu",
        duration: 10000,
        type: "fail"
      });

      switchToTab(0);
      return false;
    }

    let validAddress:IAddressError = isValidAddress(address);
    let validPhone:IAddressError = isValidPhone(phone);

    if (!validPhone.valid) {
      toast.show({
        title: "Update Registration",
        content: validPhone.message || "",
        duration: 10000,
        type: "fail"
      });

      switchToTab(2);
      return false;
    }

    if (!validAddress.valid) {
      toast.show({
        title: "Update Registration",
        content: validAddress.message || "",
        duration: 10000,
        type: "fail"
      });

      switchToTab(2);
      return false;
    }

    if (!isValidClasses(dogClasses)) {
      switchToTab(1);
      return false;
    }

    return true;
  }

  const updateRegistration = () => {
    setIsUpdatingRegistration(true);

    let payload:any = {
      dog: {
        breed: breedSearch,
        sex: gender,
        dob: dogDOB,
        weight: weight,
        height_at_withers: heightAtWithers,
        registered_name: name,
        call_name: callName,
        registration_number_type: regPrefix.id,
        registration_number: regNum,
        sire: sire,
        dam: dam,
        final_response: finalResponse,
        country_of_birth: countryOfBirth,
        obedience_jump_height: parseFloat(oJumpHeight.id),
        rally_jump_height: parseFloat(rJumpHeight.id),
        breeder: breeder,
        variety: variety.id !== "None" ? variety.id : ""
      },
      owner: {
        display_name: displayName,
        email: email,
        phone: phone,
        address: address
      },
      coowners: coOwners.map((c: IDogCoownerInfo, index: number) => {
        c.display_name = c.first_name +" "+ c.last_name;
        return c;
      })
    };

    if (jumpHeightType.value !== "None") {
      payload.dog.jump_height_type = jumpHeightType.value;
    }

    if (originalClasses !== dogClasses) {
      payload.dog.dog_classes = dogClasses;
    }

    for (let d in dogClasses) {
      if (dogClasses[d].show_element === Constants.show_type.fast_cat && payload.dog) {
        payload.dog.time_slot = dogClasses[d].time_slot;
        break;
      }
    }

    if (props.event && isValid()) {
      apiService.updateShowRegistration(props.event._id, props.registration._id, payload).then((response) => {
        toast.show({
          title: "Update Registration",
          content: "Successfully updated the registration",
          duration: 10000,
          type: "success"
        });

        props.onComplete();
      }).catch((error) => {
        toast.show({
          title: "Update Registration",
          content: error.response?.data?.message ? error.response.data.message : "There was an error updating the registration, please try again",
          //content: "There was an error updating the registration, please try again",
          duration: 10000,
          type: "fail"
        });
      }).finally(() => {
        setIsUpdatingRegistration(false);
      })
    } else {
      setIsUpdatingRegistration(false);
    }
  }

  const isValidClasses = (dogClasses: IDogClass[]) => {
    if (props.registration.show_type !== Constants.show_type.nosework) {
      return true;
    }

    for (let dc in dogClasses) {
      if (dogClasses[dc].section !== undefined && dogClasses[dc].section === "None") {
        toast.show({
          title: "Registration Information",
          content: "Please select a section for " + dogClasses[dc].show_element,
          duration: 10000,
          type: "fail"
        });

        return false;
      }
    }

    return true;
  }

  const getBreed = useCallback((v: string, breeds?: IBreed[]) => {
    let hasBreed = breeds ? breeds.filter((b: IBreed) => { return b.breed.toLowerCase() === v.toLowerCase() }) : breedList.filter((b: IBreed) => { return b.breed.toLowerCase() === v.toLowerCase() });

    if (hasBreed.length > 0) {
      return hasBreed[0];
    }

    return undefined;
  }, [breedList]);

  const hasBreed = () => {
    let _hasBreed = breedList.filter((b: IBreed) => b.breed.toLowerCase() === breedSearch.toLowerCase());

    if (_hasBreed.length > 0) {
      return true;
    }

    toast.show({
      title: "Breed Selection",
      content: "Please select a dog breed from the dropdown list",
      duration: 10000,
      type: "fail"
    });

    return false;
  }

  const hasVariety = () => {
    if (selectedBreed !== undefined && selectedBreed.variety !== undefined && selectedBreed.variety.length > 0) {
      if (selectedBreed.variety.filter((v: string) => v === variety.id).length > 0) {
        return true;
      }
    } else if (selectedBreed !== undefined && (selectedBreed.variety === undefined || selectedBreed.variety.length === 0)) {
      return true;
    }

    return false;
  }

  const breedVarietyExists = () => {
    let hasVariety = breedList.filter((b: IBreed) => { return b.breed.toLowerCase() === breedSearch.toLowerCase() && b.variety !== undefined && b.variety.length > 0; });

    if (hasVariety.length > 0) {
      return true;
    }

    return false;
  }

  return (
    <div className="EditRegistration">
      <div className="title">
        <span>Edit Registration</span>
        <span>{props.registration.dog.call_name}</span>
      </div>
      <StepForm activeTab={activeTab} next={switchToTab} steps={steps} onSubmit={updateRegistration} isLoading={isUpdatingRegistration}>
        <Step>
          <div className="stepTitle">Dog Information</div>
          <p>Update the dogs information. This won't affect the users account and only applies to this individual registration.</p>
          <div className="row editDog">
            <Input required label="Call Name" id="callName" type="text" onChange={(e) => { e.stopPropagation(); setCallName(e.target.value); }} placeholder="What's your dogs call name?" defaultValue={callName} />
            <Input required label="Registered Name" id="registeredName" type="text" onChange={(e) => { e.stopPropagation(); setName(e.target.value); }} placeholder="What's your dogs registered name?" defaultValue={name} />
          </div>
          <SearchDown
            required
            label="Breed"
            icon={star}
            id="breed"
            type="text"
            onChange={(e) => {
              e.stopPropagation();
              setBreedSearch(e.target.value);
              setSelectedBreed(getBreed(e.target.value));
              setVariety({ id: "None", value: "None" });
            }}
            onSelect={(value: string) => {
              setBreedSearch(value);
              setSelectedBreed(getBreed(value));
              setVariety({ id: "None", value: "None" });
            }}
            placeholder="What's your dogs breed?"
            onSearch={() => {  }}
            searchResults={breedList.filter((b: IBreed) => b.breed.toLowerCase() === breedSearch.toLowerCase()).length === 1 ? [] : breedList.filter((b: IBreed) => {
              if (b.breed.toLowerCase().includes(breedSearch.toLowerCase()) && b.breed.toLowerCase() !== breedSearch.toLowerCase()) {
                return b.breed;
              } else {
                return false;
              }}).map((b: IBreed) => b.breed)
            }
            defaultValue={breedSearch}
          />
          <>{breedVarietyExists() && <div className="varietyWrap">
            <Dropdown
              value={variety}
              label={"Variety"}
              onChange={(e: any, value: IOption) => { setVariety(value); }}
              options={selectedBreed?.variety?.map((v: string) => { return { id: v, value: v }}) || []}
              placeholder=""
            />
          </div>}</>
          <div className="breedError">
            <div className="breedErrorInner">
              <div className="breedErrorIconWrap">
                <div className="breedErrorIcon"></div>
              </div>
              <div className="breedErrorContent">
                <p>Can't find the breed you're looking for? Please <a href={"mailto:info@eagerdog.com?subject=Missing Dog Breed: " + breedSearch}>contact us</a> and let us know.</p>
              </div>
            </div>
          </div>
          <div className="row editDog">
            <Dropdown className="prefix" value={regPrefix} label={"Prefix"} onChange={(e: any, value: IOption) => { setRegPrefix(value); }} options={regPrefixOptions} placeholder="" />
            <Input required icon={num} label="Registration Number" id="regNum" type="text" onChange={(e) => { e.stopPropagation(); setRegNum(e.target.value); }} placeholder="What's the dogs Registration number?" defaultValue={regNum} />
          </div>
          <DatePick label="Date of Birth" maxDate={new Date()} onChange={setDogDOB} value={dogDOB} />
          <div className="gender">
            <div className="checkboxWrap">
              <label htmlFor="male">
                <div className="checkmarkWrap">
                  <input type="checkbox" id={"male"} name={Constants.dog_sex.male} checked={gender === Constants.dog_sex.male ?  true : false} onChange={() => { setGender(Constants.dog_sex.male); }} />
                  <span className="checkmark"></span>
                </div>
                <span className="name">Male</span>
              </label>
            </div>
            <div className="checkboxWrap">
              <label htmlFor="female">
                <div className="checkmarkWrap">
                  <input type="checkbox" id={"female"} name={Constants.dog_sex.female} checked={gender === Constants.dog_sex.female ?  true : false} onChange={() => { setGender(Constants.dog_sex.female); }} />
                  <span className="checkmark"></span>
                </div>
                <span className="name">Female</span>
              </label>
            </div>
          </div>
          <div className="row editDog">
            <Input label="Weight (lbs)" icon={scale} id="weight" type="number" step="0.01" min="1" onChange={(e) => { e.stopPropagation(); setWeight(Number(e.target.value)); }} placeholder="What's your dogs weight? (optional)" defaultValue={weight as any} />
            <Input label="Height at Withers (inches)" icon={num} id="heightAtWithers" type="number" step="0.01" min="1" onChange={(e) => { e.stopPropagation(); setHeightAtWithers(Number(e.target.value)); }} placeholder="What's your dogs height at withers? (optional)" defaultValue={heightAtWithers as any} />
          </div>
          <div className="row editDog">
            <Input label="Sire" id="sire" type="text" onChange={(e) => { e.stopPropagation(); setSire(e.target.value); }} placeholder="Who's your dogs Sire?" defaultValue={sire} />
            <Input label="Dam" id="dam" type="text" onChange={(e) => { e.stopPropagation(); setDam(e.target.value); }} placeholder="Who's your dogs Dam?" defaultValue={dam} />
          </div>
          <div className="row editDog">
            <Input label="Final Response" id="finalResponse" type="text" onChange={(e) => { e.stopPropagation(); setFinalResponse(e.target.value); }} placeholder="What's your dogs final response?" defaultValue={finalResponse} />
            <Input label="Country of Birth" id="countryOfBirth" type="text" onChange={(e) => { e.stopPropagation(); setCountryOfBirth(e.target.value); }} placeholder="Who's your dogs country of birth?" defaultValue={countryOfBirth} />
          </div>
          <div className="row editDog">
            <Input label="Breeder" id="breeder" type="text" onChange={(e) => { e.stopPropagation(); setBreeder(e.target.value); }} placeholder="Who's your dogs breeder?" defaultValue={breeder} />
            <Dropdown value={jumpHeightType} label={"UKC Jump Height Type"} onChange={(e: any, value: IOption) => { setJumpHeightType(value); }} options={dogHeightTypes} placeholder="" />
          </div>
          <div className="row editDog">
            <Dropdown value={oJumpHeight} label={"AKC Obedience Jump Height"} onChange={(e: any, value: IOption) => { setOJumpHeight({ id: e.target.value, value: e.target.value + '"' }); }} options={dogObedienceHeights} placeholder="" />
            <Dropdown value={rJumpHeight} label={"AKC Rally Jump Height"} onChange={(e: any, value: IOption) => { setRJumpHeight({ id: e.target.value, value: e.target.value + '"' }); }} options={dogRallyHeights} placeholder="" />
          </div>
        </Step>
        <>{(props.event?.sanctioning_club === Constants.sanctioning_club.AKC) && <Step>
          <React.Fragment>
            <div className="stepTitle">Co-Owner Information</div>
            <p>Update the dogs co-owner information. This won't affect the users account and only applies to this individual registration.</p>
            {loaded && <OwnerList owners={coOwners} onChange={(newOwners: IDogCoownerInfo[]) => { setCoOwners(newOwners); }} />}
          </React.Fragment>
        </Step>}</>
        <Step onSubmit={() => { return isValidClasses(dogClasses); }}>
          <div className="stepTitle">Registration Information</div>
          <p>Update the dogs registration information. You'll only be able to modify elements and levels that the dog owner has already selected and paid for.</p>
          <>{show !== undefined && 
            <FormBuilder 
              show={show}
              event={props.event}
              dog={{...props.registration.dog, ...{ dob: dogDOB }}}
              classes={dogClasses}
              isJunior={props.registration.owner.is_junior}
              edit={true}
              onChange={
                (newClasses: IDogClass[]) => {
                  if (newClasses.length > 0 && originalClasses === undefined) {
                    setOriginalClasses(newClasses);
                  }

                  setDogClasses(newClasses); 
                }
              }
            />
          }</>
        </Step>
        <Step>
          <div className="stepTitle">Owner Information</div>
          <p>Update the owner information. This won't affect the users account and only applies to this individual registration.</p>
          <div className="row editDog">
            <Input required label="Display Name" id="displayName" type="text" onChange={(e) => { e.stopPropagation(); setDisplayName(e.target.value); }} placeholder="What's the owners name?" defaultValue={displayName} />
            <Input required label="Email" id="email" type="email" onChange={(e) => { e.stopPropagation(); setEmail(e.target.value); }} placeholder="What's the owners email?" defaultValue={email} />
          </div>
          <UserAddress user={props.registration.owner} onChange={(newAddress: IAddress, newPhone: string) => { setAddress(newAddress); setPhone(newPhone); }}/>
        </Step>
      </StepForm>
    </div>
  );
};

export default EditRegistration;