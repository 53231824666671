import React, { useEffect, useState } from "react";
import { Constants } from "@eagerdog/constants";
import { ShedDogScoreDto } from "@eagerdog/interfaces";

import Input from "src/components/Input/Input";
import Dropdown, { IOption } from "src/components/Dropdown/Dropdown";
import StopWatch, { convertTimeToMinutes, convertTimeToSeconds } from "../StopWatch/StopWatch";

import styles from "./ShedDogResults.module.scss";

interface IProps {
  result: any,
  onChange(result: ShedDogScoreDto): void
}

const ShedDogResults: React.FC<IProps> = (props) => {
  const getOptionLabel = (id: string) => {
    switch (id) {
      case Constants.score_result_type.qualified:
        return "Qualified";
      case Constants.score_result_type.not_qualified:
        return "Not Qualified";
      case Constants.score_result_type.disqualified:
        return "Disqualified";
      case Constants.score_result_type.excused:
        return "Excused";
      case Constants.score_result_type.absent:
        return "Absent";
    }

    return "None";
  }

  const getResultReasonLabel = () => {
    let label:string = "";

    switch (result.id) {
      case Constants.score_result_type.disqualified:
        label = "Disqualified For";
        break;
      case Constants.score_result_type.excused:
        label = "Excused For";
        break;
    }

    return label;
  }

  const [result, setResult] = useState<IOption>({ id: props.result.shed_dog?.result_type ? props.result.shed_dog?.result_type : "None", value: props.result.shed_dog?.result_type ? getOptionLabel(props.result.shed_dog?.result_type) : "None" });
  const [resultReason, setResultReason] = useState<string>(props.result.shed_dog?.result_reason ? props.result.shed_dog?.result_reason : "");
  const [time, setTime] = useState<string>(props.result.shed_dog?.search_time ? convertTimeToMinutes(props.result.shed_dog?.search_time.toString()) : "");
  const [hasChanged, setHasChanged] = useState<boolean>(false);

  const [finds, setFinds] = useState<IOption[]>(props.result.shed_dog?.finds ? props.result.shed_dog.finds.map((f: boolean, index: number) => {
    return f === true ? { id: "Yes", value: "Yes" } : { id: "No", value: "No" }
  }) : [
    { id: "Yes", value: "Yes" }, 
    { id: "Yes", value: "Yes" }, 
    { id: "Yes", value: "Yes" }, 
    { id: "Yes", value: "Yes" }, 
    { id: "Yes", value: "Yes" }
  ]);

  const resultOptions:IOption[] = [
    { id: "None", value: "None" },
    { id: Constants.score_result_type.qualified, value: "Qualified" },
    { id: Constants.score_result_type.not_qualified, value: "Not Qualified" },
    { id: Constants.score_result_type.disqualified, value: "Disqualified" },
    { id: Constants.score_result_type.absent, value: "Absent" }
  ]

  const findOptions:IOption[] = [
    { id: "Yes", value: "Yes" },
    { id: "No", value: "No" }
  ];

  const setFind = (find: IOption, index: number) => {
    let _finds = JSON.parse(JSON.stringify(finds));

    _finds[index] = find;

    setFinds(_finds);
  }

  let { onChange } = props;

  useEffect(() => {
    if (hasChanged) {
      let changedResult:any = {
        result_type: result.id,
        search_time: Math.round(convertTimeToSeconds(time)),
        finds: finds.map((f: IOption, index: number) => {
          return f.id === "Yes" ? true : false
        })
      };

      if (result.id === Constants.score_result_type.disqualified || result.id === Constants.score_result_type.excused) {
        changedResult.result_reason = resultReason;
      }

      onChange(changedResult);

      setHasChanged(false);
    }
  }, [hasChanged, onChange, finds, result.id, resultReason, time]);

  return (
    <div className={styles.ShedDogResults}>
      <div className={styles.row}><Dropdown value={result} onChange={(e: any, value: IOption) => { setResult(value); setHasChanged(true); }} label="Result" options={resultOptions} placeholder="" /></div>
      {(result.id === Constants.score_result_type.disqualified || result.id === Constants.score_result_type.excused) && props.result.sanctioning_club === Constants.sanctioning_club.UKC && <div className={styles.resultReason}>
        <div className={styles.row}>
          <Input type="text" onChange={(e: any) => { setResultReason(e.target.value); setHasChanged(true); }} label={getResultReasonLabel()} placeholder="" defaultValue={resultReason} />
        </div>
      </div>}
      <div className={styles.row}><StopWatch time={time} onChange={(value: string) => { setTime(value); setHasChanged(true); }} /></div>
      {finds.map((f: IOption, index:  number) => {
        return <div key={f.id+index} className={styles.creditedRetrieve}>
          <Dropdown value={f} onChange={(e: any, value :IOption) => { setFind(value, index); setHasChanged(true); }} label={(index + 1) + ". Credited Retrieve"} options={findOptions} placeholder="" />
        </div>;
      })}
    </div>
  );
};

export default ShedDogResults;