import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import { AxiosError } from "axios";

import { DogDto, IBreed, IDog, IDogCoownerInfo, IUser } from "@eagerdog/interfaces";
import { Constants } from "@eagerdog/constants"

import { apiService } from "../../../services";
import { helperService, dogHeightTypes, dogRallyHeights, dogObedienceHeights} from "../../../services/helper.service";

import StepForm, { Step, useStepForm } from "../StepForm";
import Input from "../../Input/Input";
import DatePick from "../../DatePick/DatePick";
import SearchDown from "../../SearchDown/SearchDown";
import Dropdown, { IOption } from "../../Dropdown/Dropdown";
import OwnerList from "../../OwnerList/OwnerList";

import scale from "../../../assets/icons/scale.svg";
import num from "../../../assets/icons/num.svg";
import star from "../../../assets/icons/star.svg";

import { toast } from "../../../components/Toast/ToastManager";

import './DogForm.scss';
import 'react-image-crop/dist/ReactCrop.css';
import moment from 'moment';

interface IProps {
  onFinish(dog?: IDog): void,
  dog?: IDog,
  onBehalf?: string,
  onBehalfEmail?: string,
  clubId?: string
}

const DogForm: React.FC<IProps> = (props) => {
  const { switchToTab, activeTab } = useStepForm();
  const [isCreatingDog, setIsCreatingDog] = useState<boolean>(false);
  const [isSelectingImage, setIsSelectingImage] = useState<boolean>(false);
  const user: IUser = helperService.getUser();

  const [name, setName] = useState<string>("");
  const [callName, setCallName] = useState<string>("");

  const [imgSrc, setImgSrc] = useState<string>();
  const [imgFile, setImgFile] = useState<File>();

  const imgRef = useRef<HTMLImageElement>(null);

  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  const [crop, setCrop] = useState<Crop>({
    unit: 'px',
    x: 25,
    y: 25,
    width: 140,
    height: 140
  });

  const [userDogs, setUserDogs] = useState<any>([]);

  const [dogDOB, setDogDOB] = useState<Date>();
  const [gender, setGender] = useState<string>(Constants.dog_sex.male);
  const [weight, setWeight] = useState<number>();
  const [heightAtWithers, setHeightAtWithers] = useState<number | null>(null);
  const [finalResponse, setFinalResponse] = useState<string>("");
  const [breedSearch, setBreedSearch] = useState<string>("");
  const [selectedBreed, setSelectedBreed] = useState<IBreed>();
  const [breedList, setBreedList] = useState<IBreed[]>([]);
  const [variety, setVariety] = useState<IOption>({ id: "None", value: "None" });

  const [UKCNum, setUKCNum] = useState<string>("");
  const [UKCPrefix, setUKCPrefix] = useState<IOption>({ id: Constants.registration_number_type.ukc_registered, value: "Permanent Reg." });

  const [AKCNum, setAKCNum] = useState<string>("");
  const [AKCPrefix, setAKCPrefix] = useState<IOption>({ id: Constants.registration_number_type.akc_registered, value: "AKC" });

  const [ABINum, setABINum] = useState<string>("");
  const [ABIPrefix, setABIPrefix] = useState<IOption>({ id: Constants.sanctioning_club.UKC, value: "UKC" });

  const [heightType, setHeightType] = useState<IOption>({ id: "None", value: "None" });

  const registrationNumTypeUKC:IOption[] = useMemo(() => { return [
    { id: Constants.registration_number_type.ukc_registered, value: "Permanent Reg." },
    { id: Constants.registration_number_type.ukc_temporary, value: "TL" },
    { id: Constants.registration_number_type.ukc_performance, value: "PL/LP" }
  ]}, []);

  const registrationNumTypeAKC:IOption[] = useMemo(() => { return [
    { id: Constants.registration_number_type.akc_registered, value: "AKC" },
    { id: Constants.registration_number_type.akc_pal, value: "PAL/ILP" },
    { id: Constants.registration_number_type.akc_foreign_reg, value: "F. REG" }
  ]}, []);

  const registrationNumTypeABI:IOption[] = useMemo(() => { return [
    { id: Constants.sanctioning_club.UKC, value: "ABI" }
  ]}, []);

  const initialOwner: IDogCoownerInfo = {
    coowner_type: "owner",
    coowner_number: "",
    email: "",
    first_name: "",
    last_name: "",
    display_name: "",
    phone: "",
    address: {
      line1: "",
      line2: "",
      city: "",
      state: "",
      country: "",
      postal_code: ""
    }
  };

  const [obedienceHeight, setObedienceHeight] = useState<IOption>({ id: "None", value: "None" });
  const [rallyHeight, setRallyHeight] = useState<IOption>({ id: "None", value: "None" });

  const [coOwners, setCoOwners] = useState<IDogCoownerInfo[]>([{ ...initialOwner }]);

  const [countryOfBirth, setCountryOfBirth] = useState<string>("");
  const [obedienceJumpHeight, setObedienceJumpHeight] = useState<string>("");
  const [rallyJumpHeight, setRallyJumpHeight] = useState<string>("");
  const [breeder, setBreeder] = useState<string>("");
  const [sire, setSire] = useState<string>("");
  const [dam, setDam] = useState<string>("");

  const validateDog = () => {
    let passed:string = "";

    if (dogDOB === undefined) {
      passed = "Please enter your dog's date of birth";
      switchToTab(1);
    }

    if (!hasBreed()) {
      passed = "Please select a dog breed from the dropdown list";
      switchToTab(2);
    }

    if (!hasVariety()) {
      passed = "Please select a dog variety from the dropdown list";
      switchToTab(2);
    }

    if (name === "") {
      passed = "Please set a registered name";
      switchToTab(0);
    }

    if (callName === "") {
      passed = "Please set a call name";
      switchToTab(0);
    }

    /*
    if (UKCNum.length === 0 && AKCNum.length === 0 && ABINum.length === 0) {
      passed = "Please enter atleast one registration number";
      switchToTab(3);
    }
    */

    /*if (ownerName.length > 0) {
      if (coowner.address?.country !== "" && coowner.address?.state === "") {
        passed = "Please select an owner state/province";
        switchToTab(4);
      }
    }*/

    return passed;
  }

  const isEditingDog = () => {
    return props.dog && props.dog._id;
  };
  
  const getDogId = () => {
    return isEditingDog() ? (props?.dog?._id ?? '') : '';
  };
  
  const showToast = (title: string, content: string, type: string, errorDetails?: any) => {
    toast.show({
      title: title,
      content: content,
      duration: 10000,
      type: type,
      errorDetails: errorDetails,
    });
  };
  
  const handleSuccessResponse = (response: any) => {
    if (response._id) {
      const message = `You've successfully ${isEditingDog() ? 'updated' : 'added'} ${callName}.`;
      showToast(isEditingDog() ? "Dog Updated" : "Dog Added", message, "success");
      props.onFinish(response);
    }
  };
  
  const editDog = (dogId: string, payload: DogDto) => {
    apiService.updateDog(dogId, payload).then(handleSuccessResponse).catch((e: AxiosError) => {
      showToast("Edit Dog", "Unable to update dog to account", "fail", e);
    }).finally(() => {
      setIsCreatingDog(false);
    });
  };
  
  const createDog = (payload: DogDto) => {
    if (props.onBehalf && props.onBehalfEmail && props.clubId) {
      let _payload:any = {
        ...payload, 
        ...{ owner_id: props.onBehalf }
      };

      apiService.createDogByClubManager(props.clubId, _payload).then(handleSuccessResponse).catch((e: AxiosError) => {
        showToast("Add Dog", "Unable to add dog to account", "fail", e);
      }).finally(() => {
        setIsCreatingDog(false);
      });
    } else {
      apiService.createDog(payload).then(handleSuccessResponse).catch((e: AxiosError) => {
        showToast("Add Dog", "Unable to add dog to account", "fail", e);
      }).finally(() => {
        setIsCreatingDog(false);
      });
    }
  };

  const isDuplicateDog = (newDog: DogDto) => {

    let dogs = userDogs.filter((d: IDog) => d._id !== props.dog?._id);
    
    let hasDuplicateCallName = dogs.filter((d: IDog) => {
      return d.call_name === newDog.call_name;
    });

    if (hasDuplicateCallName.length > 0) {
      return true;
    }

    let hasDuplicateRegName = dogs.filter((d: IDog) => {
      return d.registered_name === newDog.registered_name;
    });

    if (hasDuplicateRegName.length > 0) {
      return true;
    }

    let hasAKCNum = newDog.registration_numbers.filter((n: any) => { return n.name === "AKC" });

    if (hasAKCNum.length > 0) {
      for (let i in dogs) {
        let hasDuplicateAKCNum = dogs[i].registration_numbers.filter((d: any) => {
          return d.name === "AKC" && d.value === hasAKCNum[0].value;
        });

        if (hasDuplicateAKCNum.length > 0) {
          return true;
        }
      }
    }

    let hasUKCNum = newDog.registration_numbers.filter((n: any) => { return n.name === "UKC" });

    if (hasUKCNum.length > 0) {
      for (let i in dogs) {
        let hasDuplicateUKCNum = dogs[i].registration_numbers.filter((d: any) => {
          return d.name === "UKC" && d.value === hasUKCNum[0].value;
        });

        if (hasDuplicateUKCNum.length > 0) {
          return true;
        }
      }
    }

    return false;
  }

  const getTrueBreedName = () => {
    let _hasBreed = breedList.filter((b: IBreed) => b.breed.toLowerCase() === breedSearch.toLowerCase());

    if (_hasBreed.length > 0) {
      return _hasBreed[0].breed;
    }

    return breedSearch;
  }
  
  const handleDog = () => {
    let _error: string = validateDog();
    const actionTitle = isEditingDog() ? "Edit Dog" : "Add Dog";
  
    if (_error.length > 0) {
      showToast(actionTitle, _error, "fail");
      return false;
    }

    setIsCreatingDog(true);

    let payload: DogDto = {
      breed: getTrueBreedName(),
      variety: variety.id !== "None" ? variety.id : "",
      sex: gender,
      dob: dogDOB !== undefined ? new Date(dogDOB.getFullYear(), dogDOB.getMonth(), dogDOB.getDate(), 0, 0, 0) : new Date(),
      registered_name: name,
      call_name: callName,
      registration_numbers: [],
      image_url: props.dog?.image_url || "",  // default to existing image for edit,
      final_response: finalResponse,
      coowners: coOwners.filter((c: IDogCoownerInfo, index: number) => { return c.first_name !== undefined && c.last_name !== undefined && c.first_name !== "" && c.last_name !== ""; }).map((c: IDogCoownerInfo, index: number) => {
        c.display_name = c.first_name +" "+ c.last_name;
        return c;
      }),
      country_of_birth: countryOfBirth,
      obedience_jump_height: parseFloat(obedienceJumpHeight) || 0,
      rally_jump_height: parseFloat(rallyJumpHeight) || 0,
      breeder: breeder,
      sire: sire,
      dam: dam,
    };

    if (heightType.id !== "None") {
      payload.jump_height_type = heightType.id;
    }

    if (UKCNum.length > 0) {
      payload.registration_numbers.push({ type: UKCPrefix.id, name: "UKC", value: UKCNum });
    }

    if (AKCNum.length > 0) {
      payload.registration_numbers.push({ type: AKCPrefix.id, name: "AKC", value: AKCNum });
    }

    if (ABINum.length > 0) {
      payload.registration_numbers.push({type: ABIPrefix.id, name: "ABI", value: ABINum });
    }

    if (rallyHeight.id !== "None") {
      payload.rally_jump_height = parseInt(rallyHeight.id);
    }

    if (obedienceHeight.id !== "None") {
      payload.obedience_jump_height = parseInt(obedienceHeight.id);
    }

    if (weight && weight > 0) {
      payload.weight = weight;
    }

    if (heightAtWithers && heightAtWithers > 0) {
      payload.height_at_withers = heightAtWithers;
    }

    if (!isEditingDog() && isDuplicateDog(payload)) {
      showToast("Create Dog", "You already have a dog with the same call name, registered name, and/or registration (UKC/AKC) number on this account.", "fail");
      setIsCreatingDog(false);
    } else {
      if (isEditingDog()) {
        // if image is changed, upload it first
        if (imgFile) {
          apiService.uploadFile(imgFile).then((response: any) => {
            if (response.value) {
              payload.image_url = response.value;
            }
            editDog(getDogId(), payload);
          });
        } else {
          editDog(getDogId(), payload);
        }
      } else {
        if (imgFile) {
          apiService.uploadFile(imgFile).then((response: any) => {
            if (response.value) {
              payload.image_url = response.value;
              createDog(payload);
            } else {
              showToast("Image Upload", "There's an issue uploading your dog's profile image, please try again later", "fail");
            }
          });
        } else {
          createDog(payload);
        }
      }
    }
  };

  const hasBreed = () => {
    let _hasBreed = breedList.filter((b: IBreed) => b.breed.toLowerCase() === breedSearch.toLowerCase());

    if (_hasBreed.length > 0) {
      return true;
    }

    toast.show({
      title: "Breed Selection",
      content: "Please select a dog breed from the dropdown list",
      duration: 10000,
      type: "fail"
    });

    return false;
  }

  const hasVariety = () => {
    if (selectedBreed !== undefined && selectedBreed.variety !== undefined && selectedBreed.variety.length > 0) {
      if (selectedBreed.variety.filter((v: string) => v === variety.id).length > 0) {
        return true;
      }
    } else if (selectedBreed !== undefined && (selectedBreed.variety === undefined || selectedBreed.variety.length === 0)) {
      return true;
    }

    return false;
  }

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    let size:number = e.target.files && e.target.files[0]?.size ? e.target.files[0].size : 0;
    let maxSize:number = 1048576 * 8; // 8MB

    if (size > 0) {
      setIsSelectingImage(true);
    }

    if (size > maxSize) {
      toast.show({
        title: "Image Upload",
        content: "Image is too big, please select a smaller image",
        duration: 10000,
        type: "fail"
      });
    } else if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        setImgSrc(reader.result?.toString() || '');    
      });

      reader.readAsDataURL(e.target.files[0]);
    }
  }

  useEffect(() => {
    if (imgRef.current) {
      const canvas = document.createElement("canvas");
      const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
      const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
      canvas.width = crop.width;
      canvas.height = crop.height;

      const ctx = canvas.getContext("2d");

      if (ctx) {
        ctx.drawImage(
            imgRef.current,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        const base64Image = canvas.toDataURL("image/jpeg", 1);
        let file = dataURLtoFile(base64Image, "image.png");
        setImgFile(file);
      }
    }
  }, [completedCrop, imgRef, crop]);

  // Hacky fix, imgRef.current doesn't update until setCrop is sent, setCrop isn't set until you move the cropper.
  // Without this, if you upload an image but don't move the cropper, it won't upload the image.
  // IMAGE HACK START
  const pImgSrc = helperService.usePrevious(imgSrc);

  useEffect(() => {
    if ((pImgSrc !== imgSrc) && imgSrc !== undefined) {
      setCrop({x: 0, y: 0, width: 140, height: 140, unit: 'px'});
    }
  }, [imgSrc, pImgSrc, setCompletedCrop]);
  // IMAGE HACK END

  const dataURLtoFile = (dataurl: any, filename: string) => {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type:mime});
  }

  const getDogs = useCallback(() => {
    apiService.getUserDogs(user._id).then((response) => {
      setUserDogs(response);
    });
  }, [user._id]);

  const getBreed = useCallback((v: string, breeds?: IBreed[]) => {
    let hasBreed = breeds ? breeds.filter((b: IBreed) => { return b.breed.toLowerCase() === v.toLowerCase() }) : breedList.filter((b: IBreed) => { return b.breed.toLowerCase() === v.toLowerCase() });

    if (hasBreed.length > 0) {
      return hasBreed[0];
    }

    return undefined;
  }, [breedList]);

  const breedVarietyExists = () => {
    let hasVariety = breedList.filter((b: IBreed) => { return b.breed.toLowerCase() === breedSearch.toLowerCase() && b.variety !== undefined && b.variety.length > 0; });

    if (hasVariety.length > 0) {
      return true;
    }

    return false;
  }

  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    const searchBreeds = () => {
      return new Promise<IBreed[]>((resolve) => {
        apiService.getBreeds().then((response) => {
          let breeds:IBreed[] = []; 

          response.forEach((b: IBreed) => {
            breeds.push(b);
          });

          resolve(breeds);
        }).catch((e: AxiosError) => {
          toast.show({
            title: "Dog Breeds",
            content: "Unable to get dog breeds",
            duration: 10000,
            errorDetails: e,
            type: "fail"
          });

          resolve([]);
        });
      });
    }

    if (!loaded && props.dog) {
      getDogs();

      const hydrateDogFromProps = async() => {
        const dob = moment(props?.dog?.dob).toDate();
        let breeds:IBreed[] = await searchBreeds();
        setBreedList(breeds);

        if (props.dog) {
          setCallName(props?.dog?.call_name);
          setName(props?.dog?.registered_name);
          setGender(props?.dog?.sex);
          setDogDOB(dob);
          setVariety(props?.dog?.variety ? { id: props?.dog?.variety, value: props?.dog?.variety } : { id: "None", value: "None" });

          setCountryOfBirth(props.dog?.country_of_birth || "");
          setObedienceJumpHeight(props.dog?.obedience_jump_height?.toString() || "");
          setRallyJumpHeight(props.dog?.rally_jump_height?.toString() || "");
          setBreeder(props.dog?.breeder || "");
          setSire(props.dog?.sire || "");
          setDam(props.dog?.dam || "");
          setHeightType({ id: props.dog?.jump_height_type || "None", value: props.dog?.jump_height_type || "None" });

          if (props?.dog?.obedience_jump_height) {
            setObedienceHeight({ id: props?.dog?.obedience_jump_height.toString(), value: props?.dog?.obedience_jump_height + '"' });
          }

          if (props?.dog?.rally_jump_height) {
            setRallyHeight({ id: props?.dog?.rally_jump_height.toString(), value: props?.dog?.rally_jump_height + '"' });
          }

          if (props?.dog?.weight) {
            setWeight(props?.dog?.weight);
          }

          if (props?.dog?.height_at_withers) {
            setHeightAtWithers(props?.dog?.height_at_withers);
          }

          if (props?.dog?.final_response) {
            setFinalResponse(props?.dog?.final_response);
          }

          if (props?.dog?.coowners) {
            setCoOwners(props.dog.coowners);
          }

          setUKCNum(props?.dog?.registration_numbers?.find((x) => x.name === "UKC")?.value ?? '');
          setUKCPrefix(registrationNumTypeUKC.find((y) => props?.dog?.registration_numbers?.find((x) => x.name === "UKC" && x.type === y.id)) || { id: Constants.registration_number_type.ukc_registered, value: "Permanent Reg." });
          
          setAKCNum(props?.dog?.registration_numbers?.find((x) => x.name === "AKC")?.value ?? '');
          setAKCPrefix(registrationNumTypeAKC.find((y) => props?.dog?.registration_numbers?.find((x) => x.name === "AKC" && x.type === y.id)) || { id: Constants.registration_number_type.akc_registered, value: "AKC" });

          setABINum(props?.dog?.registration_numbers?.find((x) => x.name === "ABI")?.value ?? '');
          setABIPrefix(registrationNumTypeABI.find((y) => props?.dog?.registration_numbers?.find((x) => x.name === "ABI" && x.type === y.id)) || { id: Constants.sanctioning_club.UKC, value: "UKC" });

          if (props?.dog?.breed) {
            setBreedSearch(props?.dog?.breed);

            let hasBreed = breeds.filter((b: IBreed) => { return b.breed === props?.dog?.breed });

            if (hasBreed.length > 0) {
              setSelectedBreed(hasBreed[0]);
            }
          }
        }
      }

      hydrateDogFromProps();
      setLoaded(true);
    } else {
      const loadBreeds = async() => {
        let breeds:IBreed[] = await searchBreeds();
        setBreedList(breeds);
      }

      loadBreeds();
      getDogs();
      setLoaded(true);
    }
  }, [loaded, props.dog, registrationNumTypeUKC, registrationNumTypeAKC, registrationNumTypeABI, getDogs]);

  return(
    <div className="DogForm">
      <div className="title">
        <span>Add a Dog</span>
        {props.onBehalfEmail && <span className="subTitle">On behalf of {props.onBehalfEmail}</span>}
      </div>
      <div className="formWrap">
        <StepForm activeTab={activeTab} next={switchToTab} onSubmit={handleDog} steps={["Name", "Basic Information", "Breed", "Registration Numbers", "Co-Owner Information"]} isLoading={isCreatingDog}>
          <Step>
            <div className="stepTitle">About your dog</div>
            <p>What's your dogs name?</p>
            <Input required label="Call Name" id="callName" type="text" onChange={(e) => { e.stopPropagation(); setCallName(e.target.value); }} placeholder="What's the dogs call name?" defaultValue={callName} />
            <Input required label="Registered Name" id="registeredName" type="text" onChange={(e) => { e.stopPropagation(); setName(e.target.value); }} placeholder="What's the dogs registered name?" defaultValue={name} />
            <div className="dogImage">
              <p>Dog Profile Picture (optional)</p>
              <label className="uploadDog">
                <input type="file" accept="image/*" onChange={onSelectFile} />
                <span>{(imgSrc || getDogId()) ? 'Change Image' : 'Upload Image'}</span>
              </label>
              {props?.dog && props?.dog?.image_url && !isSelectingImage && <div className="dog-img-wrap">
                <img src={props?.dog?.image_url} alt="Dog Portrait" />
              </div>}
              {!!imgSrc && <div className="cropWrap">
                <ReactCrop
                  crop={crop}
                  onChange={c => setCrop(c)}
                  aspect={1}
                  minHeight={140}
                  minWidth={140}
                  maxWidth={140}
                  maxHeight={140}
                  locked={true}
                  circularCrop={true}
                  onComplete={(c) => setCompletedCrop(c)}
                >
                  <img ref={imgRef} src={imgSrc} alt="Dog Portait Crop" />
                </ReactCrop>
              </div>}
            </div>
          </Step>
          <Step>
            <div className="stepTitle">Basic Information</div>
            <p>Enter your dogs DOB, gender, and weight</p>
            <DatePick required={true} label="Date of Birth" maxDate={new Date()} onChange={setDogDOB} value={dogDOB} className="hideDate" />
            <div className="gender">
              <div className="checkboxWrap">
                <label htmlFor="male">
                  <div className="checkmarkWrap">
                    <input type="checkbox" id={"male"} name={Constants.dog_sex.male} checked={gender === Constants.dog_sex.male ?  true : false} onChange={() => { setGender(Constants.dog_sex.male); }} />
                    <span className="checkmark"></span>
                  </div>
                  <span className="name">Male</span>
                </label>
              </div>
              <div className="checkboxWrap">
                <label htmlFor="female">
                  <div className="checkmarkWrap">
                    <input type="checkbox" id={"female"} name={Constants.dog_sex.female} checked={gender === Constants.dog_sex.female ?  true : false} onChange={() => { setGender(Constants.dog_sex.female); }} />
                    <span className="checkmark"></span>
                  </div>
                  <span className="name">Female</span>
                </label>
              </div>
            </div>
            <Input label="Weight (lbs)" icon={scale} id="weight" type="number" step="0.01" min="1" onChange={(e) => { e.stopPropagation(); setWeight(Number(e.target.value)); }} placeholder="What's your dogs weight? (optional)" defaultValue={weight as any} />
            <Input label="Height at Withers (inches, optional)" icon={num} id="heightAtWithers" type="number" step="0.01" min="1" onChange={(e) => { e.stopPropagation(); setHeightAtWithers(Number(e.target.value)); }} placeholder="What's your dogs height at withers? (optional)" defaultValue={heightAtWithers as any} />
            <Input label="Final Response" id="finalResponse" type="string" onChange={(e) => { e.stopPropagation(); setFinalResponse(e.target.value); }} placeholder="What's your dogs final response? (optional)" defaultValue={finalResponse} />
            <Input label="Country Of Birth (optional)" type="string" placeholder="What's your dogs country of birth? (optional)" onChange={(e) => { e.stopPropagation(); setCountryOfBirth(e.target.value); }} defaultValue={countryOfBirth} />
            <Dropdown value={heightType} label={"UKC Jump Height Type"} onChange={(e: any, value: IOption) => { setHeightType(value); }} options={dogHeightTypes} placeholder="" />
            <Dropdown value={obedienceHeight} label={"AKC Obedience Jump Height"} onChange={(e: any, value: IOption) => { setObedienceHeight(value); }} options={dogObedienceHeights} placeholder="" />
            <Dropdown value={rallyHeight} label={"AKC Rally Jump Height"} onChange={(e: any, value: IOption) => { setRallyHeight(value); }} options={dogRallyHeights} placeholder="" />
            <Input label="Breeder (optional)" type="string" placeholder="Who's your dogs breeder? (optional)" onChange={(e) => { e.stopPropagation(); setBreeder(e.target.value); }} defaultValue={breeder} />
            <Input label="Sire (optional)" type="string" placeholder="Who's your dogs Sire? (optional)" onChange={(e) => { e.stopPropagation(); setSire(e.target.value); }} defaultValue={sire} />
            <Input label="Dam (optional)" type="string" placeholder="Who's your dogs Dam? (optional)" onChange={(e) => { e.stopPropagation(); setDam(e.target.value); }} defaultValue={dam} />
          </Step>
          <Step onSubmit={hasBreed}>
            <div className="stepTitle">Dog Breed</div>
            <p>What's your dogs breed?</p>
            <SearchDown
              required
              label="Breed"
              icon={star}
              id="breed"
              type="text"
              onChange={(e) => {
                e.stopPropagation();
                setBreedSearch(e.target.value);
                setSelectedBreed(getBreed(e.target.value));
                setVariety({ id: "None", value: "None" });
              }}
              onSelect={(value: string) => {
                setBreedSearch(value);
                setSelectedBreed(getBreed(value));
                setVariety({ id: "None", value: "None" });
              }}
              placeholder="What's your dogs breed?"
              onSearch={() => {  }}
              searchResults={breedList.filter((b: IBreed) => b.breed.toLowerCase() === breedSearch.toLowerCase()).length === 1 ? [] : breedList.filter((b: IBreed) => {
                if (b.breed.toLowerCase().includes(breedSearch.toLowerCase()) && b.breed.toLowerCase() !== breedSearch.toLowerCase()) {
                  return b.breed;
                } else {
                  return false;
                }}).map((b: IBreed) => b.breed)
              }
              defaultValue={breedSearch}
            />
            <>{breedVarietyExists() && <div className="varietyWrap">
              <Dropdown
                value={variety}
                label={"Variety"}
                onChange={(e: any, value: IOption) => { setVariety(value); }}
                options={selectedBreed?.variety?.map((v: string) => { return { id: v, value: v }}) || []}
                placeholder=""
              />
            </div>}</>
            <div className="breedError">
              <div className="breedErrorInner">
                <div className="breedErrorIconWrap">
                  <div className="breedErrorIcon"></div>
                </div>
                <div className="breedErrorContent">
                  <p>Can't find the breed you're looking for? Please <a href={"mailto:info@eagerdog.com?subject=Missing Dog Breed: " + breedSearch}>contact us</a> and let us know.</p>
                </div>
              </div>
            </div>
          </Step>
          <Step>
            <div className="stepTitle">Registration Number</div>
            <p>Enter your dogs UKC and/or AKC registration numbers</p>
            <div className="regNumWrap">
              <Dropdown value={UKCPrefix} label={"Prefix"} onChange={(e: any, value: IOption) => { setUKCPrefix(value); }} options={registrationNumTypeUKC} placeholder="" />
              <Input icon={num} label="UKC Registration" id="ukcReg" type="text" onChange={(e) => { e.stopPropagation(); setUKCNum(e.target.value); }} placeholder="What's your dogs UKC Registration number?" defaultValue={props?.dog?.registration_numbers?.find((x) => x.name === "UKC")?.value} />
            </div>
            <div className="regNumWrap">
              <Dropdown value={AKCPrefix} label={"Prefix"} onChange={(e: any, value: IOption) => { setAKCPrefix(value); }} options={registrationNumTypeAKC} placeholder="" />
              <Input icon={num} label="AKC Registration" id="akcReg" type="text" onChange={(e) => { e.stopPropagation(); setAKCNum(e.target.value); }} placeholder="What's your dogs AKC Registration number?" defaultValue={props?.dog?.registration_numbers?.find((x) => x.name === "AKC")?.value} />
            </div>
            <p>For ABI events, select a type of registration number, followed by your dogs registration number.</p>
            <div className="regNumWrap">
              <Dropdown value={ABIPrefix} label={"Type"} onChange={(e: any, value: IOption) => { setABIPrefix(value); }} options={registrationNumTypeABI} placeholder="" />
              <Input icon={num} label="ABI Registration" id="abiReg" type="text" onChange={(e) => { e.stopPropagation(); setABINum(e.target.value); }} placeholder="What's your dogs ABI Registration number?" defaultValue={props?.dog?.registration_numbers?.find((x) => x.name === "ABI")?.value} />
            </div>
            <div className="breedError">
              <div className="breedErrorInner">
                <div className="breedErrorContent noIcon">
                  <p>It is your responsibility to ensure your registration numbers are correct and up to date.  Entering an event with the wrong registration number can cause your results to not be counted correctly by the sanctioning club.</p>
                </div>
              </div>
            </div>
          </Step>
          {<Step>
            <React.Fragment>
              <div className="stepTitle">Co-Owner Information</div>
              <p>Enter each dog co-owners Country, State/Province, and Phone Number. This information is optional, but required for AKC shows.</p>
              {loaded && <OwnerList owners={coOwners} onChange={(newOwners: IDogCoownerInfo[]) => { setCoOwners(newOwners); }} />}
            </React.Fragment>
          </Step>}
        </StepForm>
      </div>
    </div>
  );
}

export default DogForm;