import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { Constants } from "@eagerdog/constants";
import { IEventSummary, IShow, IShowElement } from "@eagerdog/interfaces";

//import { helperService } from "src/services/helper.service";
//import { apiService } from "src/services";

import TabContent, { ITab, useTabContent } from "src/components/TabContent/TabContent";

import NotificationsTab from "./NotificationsTab/NotificationsTab";
import JudgeSchedulesTab from "./JudgeSchedulesTab/JudgeSchedulesTab";
import ExerciseTab from "./ExerciseTab/ExerciseTab";

import styles from "./ManageShow.module.scss";

interface IProps {
  event: IEventSummary,
  show: IShow,
  onUpdate(): void,
  level?: string
}


const ManageShow: React.FC<IProps> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  const { activeTab, setTab } = useTabContent();

  const supportsJudgeSchedules = useCallback(() => {
    return (props.event.sanctioning_club === Constants.sanctioning_club.AKC && (props.show.show_type === Constants.show_type.obedience || props.show.show_type === Constants.show_type.rally_obedience));
  }, [props.event.sanctioning_club, props.show.show_type]);

  const showHasExercises = useCallback(() => {
    for (let se in props.show.show_elements) {
      let showElement:IShowElement = props.show.show_elements[se];

      if (showElement.exercises !== undefined && showElement.exercises.length > 0) {
        return true;
      }
    }

    return false;
  }, [props.show.show_elements]);

  const [tabs, setTabs]= useState<ITab[]>();

  useEffect(() => {
    if (!loaded) {
      let _tabs:ITab[] = [
        { name: "Notifications", actions: [] }
      ];

      if (supportsJudgeSchedules()) {
        _tabs.push({ name: "Judge Schedules", actions: [] });
      }

      if (showHasExercises()) {
        _tabs.push({ name: "Exercises", actions: [] });
      }

      setTabs(_tabs);

      if (props.level) {
        setTab(_tabs.findIndex((t: ITab) => t.name === "Exercises"));
      }

      setLoaded(true);
    }
  }, [loaded, showHasExercises, supportsJudgeSchedules, props.level, setTab]);

  return (
    <div className={styles.ManageShow}>
      <div className={styles.title}>
        <span className={styles.ModalTitle}>Manage Show</span>
        <span className={styles.ShowName}>{props.show.show_type} {props.show.show_name} ({moment(props.show.show_date).format("MMM Do")})</span>
      </div>
      {loaded && tabs !== undefined && <div className={styles.manageShowContent}>
        <TabContent className={"ManageShowTabs"} setTab={setTab} activeTab={activeTab} tabs={tabs}>
          <NotificationsTab event={props.event} show={props.show} onUpdate={props.onUpdate} />
          {supportsJudgeSchedules() && <JudgeSchedulesTab event={props.event} show={props.show} onUpdate={props.onUpdate} />}
          {showHasExercises() && <ExerciseTab startAt={props.level ? props.level : undefined} event={props.event} show={props.show} onUpdate={props.onUpdate} />}
        </TabContent>
      </div>}
    </div>
  );
};

export default ManageShow;