import React, { useState } from 'react';
import { Constants } from "@eagerdog/constants";
import { apiService } from "src/services";

import { toast } from "src/components/Toast/ToastManager";
import { Modal, useModal } from "src/components/Modal/Modal";
import Confirm from "src/components/Confirm/Confirm";
import ContextMenu, { IContextMenuOption } from "src/components/ContextMenu/ContextMenu";
import MoveUp from "src/components/MoveUp/MoveUp";
import EnterResults from "src/components/EnterResults/EnterResults";

import type { CustomCellRendererProps } from 'ag-grid-react';

import styles from "./ResultsTab.module.scss";

interface CustomResultsCellRendererProps extends CustomCellRendererProps {
  getResults(): void
}

export const ActionsRenderer = (props: CustomResultsCellRendererProps) => {
  const [modalContent, setModalContent] = useState<"move" | "enter" | undefined>();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const { isShown, toggle } = useModal();

  let noMoveUp:string[] = [
    Constants.show_type.fast_cat
  ];

  let menuOptions:IContextMenuOption[] = [];
  if (!noMoveUp.includes(props.data.element)) {
    menuOptions.push({
      label: "Move Up",
      onClick:() => {
        setModalContent("move");
        toggle();
      }
    });
  }

  if (props.value.canEnterResults) {
    menuOptions.push({
      label: "Enter Results",
      onClick:() => {
        setModalContent("enter");
        toggle();
      }
    });

    if (props.data.result !== "unknown") {
      menuOptions.push({
        label: "Clear Results",
        onClick: () => setShowConfirm(true)
      });
    }

    menuOptions.push({
      label: "Email Results",
      onClick:() => {
        apiService.requestResultsEmailForShowRegistration(props.value.event._id, props.value.result._id).then((response) => {
          toast.show({
            title: "Email Result",
            content: "You've successfully emailed the individual results",
            duration: 10000,
            type: "success"
          });
        }).catch((e) => {
          toast.show({
            title: "Reset Password",
            content: e.response.data.message ? "There is no account with that email" : "Something went wrong, please try again later",
            duration: 10000,
            type: "fail"
          });
        });
      }
    });
  }

  const getModalClassName = () => {
    switch(modalContent) {
      case "move":
        return "moveUpModal";
      case "enter":
        return "enterResultsModal";
    }

    return "";
  }

  const getModalContent = () => {
    switch(modalContent) {
      case "move":
        return <MoveUp
            event={props.value.event}
            registration={props.value.result}
            onComplete={() => {
                toggle();
                setModalContent(undefined);
                props.getResults();
              }
            }
            onClose={() => {
                toggle();
                setModalContent(undefined);
                props.getResults();
              }
            }
          />;
      case "enter":
        return <EnterResults
          event={props.value.event}
          eventId={props.value.event._id}
          showType={props.value.result.show_type}
          result={props.value.result}
          onSave={() => {
            toggle();
            setModalContent(undefined);
            props.getResults();
          }}
          onClose={() => {
            toggle();
            setModalContent(undefined);
            props.getResults();
          }}
        />;
    }

    return <></>;
  }

  const handleClearResult = () => {
    apiService.deleteScoreResult(props.value.event._id, props.value.result._id)
      .then(() => {
        props.getResults();
        toast.show({
          title: "Clear Result",
          content: "You've successfully cleared the individual results",
          duration: 10000,
          type: "success"
        });
      })
      .catch((error) => {
        toast.show({
          title: "Clear Result",
          content: error.response.data.message ??  "Something went wrong, please try again later",
          duration: 10000,
          type: "fail"
        });
      })
      .finally(() => setShowConfirm(false));
  }

  return (<div className={styles.ActionRenderer}>
    <ContextMenu
      options={menuOptions}
    />
    <Modal
      className={getModalClassName()}
      isShown={isShown}
      hide={toggle}
      modalContent={getModalContent()}
    />
    <Modal className="Confirmation" isShown={showConfirm} hide={() => { setShowConfirm(false); }}
      modalContent={
        <Confirm
          message={<><p>Are you sure you want to clear this result?</p></>}
          onConfirm={handleClearResult}
          onCancel={() => { setShowConfirm(false); }}
        />
      }
    />
  </div>);
};