import React, { useState } from 'react';
import { AxiosError } from "axios";

import { Modal, useModal } from "src/components/Modal/Modal";
import ContextMenu/*, { IContextMenuOption }*/ from "src/components/ContextMenu/ContextMenu";
import { download } from "src/components/Download/DownloadManager";
import { toast } from "src/components/Toast/ToastManager";

import EditRegistration from "src/components/EditRegistration/EditRegistration";
import ChangeElements from "src/components/ChangeElements/ChangeElements";
import Confirm from "src/components/Confirm/Confirm";

import { apiService } from "../../../services";
import type { CustomCellRendererProps } from 'ag-grid-react';

import styles from "./RegistrantsTab.module.scss";

interface CustomRegistrantCellRendererProps extends CustomCellRendererProps {
  getRegistrants(): void
}

export const ActionsRenderer = (props: CustomRegistrantCellRendererProps) => {
  const { isShown, toggle } = useModal();

  const [modalContent, setModalContent] = useState<"edit" | "remove" | "change" | undefined>();
  const [removingDog, setRemovingDog] = useState<boolean>(false);

  const removeDog = () => {
    setRemovingDog(true);

    apiService.removeDogFromShow(props.value.event._id, props.value.registration.show_id, props.value.registration.dog.dog_id).then((response) => {
      toast.show({
        title: "Remove Dog",
        content: "Successfully removed " + props.value.registration.dog.call_name + " from " + props.value.registration.show_type + " " + props.value.registration.show_name,
        duration: 10000,
        type: "success"
      });

      toggle();
      setModalContent(undefined);
      props.getRegistrants();
    }).catch((e: AxiosError) => {
      toast.show({
        title: "Remove Dog",
        content: "Unable to remove dog",
        duration: 10000,
        errorDetails: e,
        type: "fail"
      });
    }).finally(() => {
      setRemovingDog(false);
    });
  }

  const getModalContent = () => {
    switch(modalContent) {
      case "edit":
        return(<EditRegistration
          event={props.value.event}
          registration={props.value.registration}
          onComplete={() => {
            toggle();
            setModalContent(undefined);
            props.getRegistrants(); }}
            />
          );
      case "change":
        return (<ChangeElements
          event={props.value.event}
          registration={props.value.registration}
          onComplete={() => {
            toggle();
            setModalContent(undefined);
            props.getRegistrants();
          }}
          onClose={() => {
            toggle();
            setModalContent(undefined);
          }}
        />);
      case "remove":
        return(<Confirm
          message={<><p>Are you sure you want to remove {props.value.registration.dog.call_name} from {props.value.registration.show_type} {props.value.registration.show_name}? This won't refund the transaction, you can refund on the Transactions tab.</p></>}
          buttonsDisabled={removingDog}
          onConfirm={() => {
            removeDog();
          }}
          onCancel={() => {
            toggle();
            setModalContent(undefined);
          }}
        />);
    }

    return <></>;
  }

  const getModalClassName = () => {
    switch (modalContent) {
      case "edit":
        return "editRegistrationModal";
      case "change":
        return "changeElementsModal";
      case "remove":
        return "removeDogModal";
    }

    return "";
  }

  return (<div className={styles.ActionRenderer}>
    <ContextMenu
      options={[
        { 
          label: "Download Registration Form",
          onClick:() => {
            download.pushDownload({
              type: "registration",
              registrantId: props.value.registration.event_id,
              dogId: props.value.registration.dog.dog_id
            });
          }
        },
        {
          label: 'Re-send Registration Form',
          onClick: () => {
            apiService.requestEntryForms(
              props.value.registration.event_id, props.value.registration.dog.dog_id, true
            )
              .then(() => {
                toast.show({
                  title: "Registration Form",
                  content: `Successfully sent email to ${props.value.registration.owner.email}`,
                  duration: 10000,
                  type: "success"
                });
              })
              .catch((error) => {
                toast.show({
                  title: "Registration Form",
                  content: `Unable to send email to ${props.value.registration.owner.email}`,
                  duration: 10000,
                  errorDetails: error,
                  type: "fail"
                });
              });
          }
        },
        {
          label: "Edit Registration",
          onClick:() => {
            toggle();
            setModalContent("edit");
          }
        }, {
          label: "Change Elements",
          onClick: () => {
            toggle();
            setModalContent("change");
          }
        }, {
          label: "Remove Dog",
          onClick: () => {
            toggle();
            setModalContent("remove");
          }
        }
      ]}
    />
    <Modal
      className={getModalClassName()}
      isShown={isShown}
      hide={() => { toggle(); setModalContent(undefined); }}
      modalContent={getModalContent()}
    />
  </div>);
};
