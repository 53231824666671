import React, { useEffect, useState, useMemo, type ChangeEvent } from "react";
import { Constants } from "@eagerdog/constants";
import { IScore, IFastcatScore } from "@eagerdog/interfaces";

import Dropdown, { IOption } from "src/components/Dropdown/Dropdown";
import Input from "src/components/Input/Input";

import "./FastCatResults.scss";

interface IProps {
  result: IScore,
  onChange(result: IFastcatScore): void
}

const FastCatResults: React.FC<IProps> = (props) => {
  const [time, setTime] = useState<string>("");
  const [result, setResult] = useState<IOption>({ value: "None", id: "None" });
  const [loaded, setLoaded] = useState<boolean>(false);

  const resultOptions:IOption[] = useMemo(() => [
    { id: Constants.score_result_type.qualified, value: "Qualified" },
    { id: Constants.score_result_type.not_qualified, value: "NQ"},
    { id: Constants.score_result_type.absent, value: "Absent" }
  ], []);

  const [hasChanged, setHasChanged] = useState<boolean>(false);

  useEffect(() => {
    if (!loaded) {
      if (props.result && props.result.fastcat) {
        const { result_type, time } = props.result.fastcat
        for (let ro in resultOptions) {
          if (resultOptions[ro].id === result_type) {
            setResult(resultOptions[ro]);
            break;
          }
        }

        // NOTE: setup time initial value as per input pattern
        setTime(time?.toString()?.replace(/^(\d)\./, '0$1.') ?? "");
      }
      setLoaded(true);
    }
  }, [loaded, props.result, resultOptions]);

  useEffect(() => {
    if (hasChanged) {
      props.onChange({
        time: Number(time),
        result_type: result.id
      });

      setHasChanged(false);
    }
  }, [hasChanged, time, props, result.id]);

  const handleTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTime(event.target.value);
    setHasChanged(true);
  }

  return (
    <div className="FastCatResults">
      <Dropdown value={result} label={"Result"} onChange={(e: any, value: IOption) => { setResult(value); setHasChanged(true); }} options={resultOptions} placeholder="" />
      {result.id === Constants.score_result_type.qualified && <Input type="text" pattern="[0-9]{2}.[0-9]{2}" maxLength={5} onChange={handleTimeChange} label="Time (SS.MM)" placeholder="Enter the result time (e.g., 07.56)" defaultValue={time} />}
    </div>
  );
};

export default FastCatResults;