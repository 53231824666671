import React, { useState, useEffect } from "react";
import { IEvent, IShow } from "@eagerdog/interfaces";

import Input from "src/components/Input/Input";
import Checkbox, { ICheck } from "src/components/Checkbox/Checkbox";

import styles from "./WaitlistTab.module.scss";

interface IProps {
  event: IEvent,
  show?: IShow,
  showType: string,
  showElements: ICheck[],
  onChange(waitlists: IWaitlistItem[]): void
}

interface IPropsWaitlistElement {
  waitlist: IWaitlistItem,
  index: number,
  onChange(wl: IWaitlistItem, index: number): void
}

export interface IWaitlistItem {
  show_element: string,
  max_entries: string,
  waitlist_size: string,
  waitlist_enabled: boolean
}

const WaitlistElement: React.FC<IPropsWaitlistElement> = (props) => {
  const [waitlist, setWaitlist] = useState<IWaitlistItem>(props.waitlist);

  const handleWaitlistToggle = (e: any) => {
    let _waitlist:IWaitlistItem = { ...waitlist, waitlist_enabled: !waitlist.waitlist_enabled };
    setWaitlist(_waitlist);
    props.onChange(_waitlist, props.index);
  }

  const handleMaxEntriesChange = (e: any) => {
    let _waitlist:IWaitlistItem = { ...waitlist, max_entries: e.target.value };
    setWaitlist(_waitlist);
    props.onChange(_waitlist, props.index);
  }

  const handleWaitlistSizeChange = (e: any) => {
    let _waitlist:IWaitlistItem = { ...waitlist, waitlist_size: e.target.value };
    setWaitlist(_waitlist);
    props.onChange(_waitlist, props.index);
  }

  useEffect(() => {
    setWaitlist(props.waitlist);
  }, [props.waitlist])

  return(
    <div className={waitlist.waitlist_enabled ? styles.WaitlistElement : `${styles.WaitlistElement} ${styles.disabled}`}>
      <div className={styles.title}>
        <div className={styles.titleInner}>{waitlist.show_element}</div>
        <div className={styles.toggle}>
          <Checkbox id={waitlist.show_element + "check"} onChange={handleWaitlistToggle} value={waitlist.waitlist_enabled} label={"Enable Waitlist"} />
        </div>
      </div>
      <div className={styles.data}>
        <div className={styles.inputWrap}>
          <Input className={styles.input} required={true} onChange={handleMaxEntriesChange} type="text" label="Entry Limit" placeholder="What's the maximum entries?" defaultValue={waitlist.max_entries} />
        </div>
        <div className={styles.inputWrap}>
          <Input className={styles.input} required={waitlist.waitlist_enabled ? true : false} disabled={waitlist.waitlist_enabled ? false : true} onChange={handleWaitlistSizeChange} type="text" label="Waitlist Size" placeholder="Whats the waitlist size?" defaultValue={waitlist.waitlist_size} />
        </div>
      </div>
    </div>
  );
}

const WaitlistTab: React.FC<IProps> = (props) => {
  const [waitlists, setWaitlists] = useState<IWaitlistItem[]>([]);

  useEffect(() => {
    let _wls:IWaitlistItem[] = [];
    let _showElements:ICheck[] = props.showElements.filter((_se: any) => _se.checked);

    if (props.show) {
      if (waitlists.length === 0) {
        for (let s in props.show?.show_elements) {
          let _se:any = props.show?.show_elements[s];

          _wls.push({
            show_element: _se.level ? _se.level : _se.show_element,
            max_entries: _se.max_entries?.toString() || "500",
            waitlist_size: _se.waitlist_size || "25",
            waitlist_enabled: _se.waitlist_enabled ? _se.waitlist_enabled : false
          });
        }
      } else {
        if (props.showType === props.show.show_type) {
          for (let s in _showElements) {
            let _se:any = _showElements[s];
            let hasValue = props.show?.show_elements.filter((se: any) => { return se.show_element === _se.label || se.level === _se.label; });

            _wls.push({
              show_element: hasValue.length > 0 ? hasValue[0].level ? hasValue[0].level : hasValue[0].show_element : _se.label,
              max_entries: hasValue.length > 0 ? hasValue[0].max_entries?.toString() || "500" : "500",
              waitlist_size: hasValue.length > 0 ? hasValue[0].waitlist_size?.toString() || "25" : "25",
              waitlist_enabled: hasValue.length > 0 ? hasValue[0].waitlist_enabled || false : false
            });
          }
        } else {
          for (let s in _showElements) {
            let _se:any = _showElements[s];
            _wls.push({
              show_element:  _se.label,
              max_entries: "500",
              waitlist_size: "25",
              waitlist_enabled: false
            });
          }
        }
      }
    } else {
      for (let s in _showElements) {
        let _se:any = _showElements[s];

        _wls.push({
          show_element: _se.label,
          max_entries: "500",
          waitlist_size: "25",
          waitlist_enabled: false
        });
      }
    }

    if (_wls.length > 0) {
      setWaitlists(_wls);
    }
  }, [props.showElements, props.show, waitlists.length, props.showType]);

  let { onChange } = props;

  useEffect(() => {
    onChange(waitlists);
  }, [waitlists, onChange]);

  const updateWaitlist = (_wl: IWaitlistItem, _index: number) => {
    let _waitlists:IWaitlistItem[] = [...waitlists];
    _waitlists[_index] = _wl;

    setWaitlists(_waitlists);
  }

  return (
    <div className={styles.WaitlistTab}>
      <div className="stepTitle">Waitlist</div>
      <p>Would you like to use waitlists on your show elements? Waitlists are disabled by default, but you can select which show elements you'd like to use a waitlist for.</p>
      {waitlists.map((wl: IWaitlistItem, index: number) => {
        return(
          <WaitlistElement
            key={index}
            waitlist={wl}
            index={index}
            onChange={(_wl: IWaitlistItem, _index: number) => {
              updateWaitlist(_wl, _index);
            }}
          />);
      })}
    </div>
  );
};

export default WaitlistTab;