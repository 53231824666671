import React, { useState, useCallback } from "react";
import { IShow, IEventSummary } from "@eagerdog/interfaces";
//import { Constants } from "@eagerdog/constants";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

import { Modal, useModal } from "src/components/Modal/Modal";
import Input from "src/components/Input/Input";
import Checkbox from "src/components/Checkbox/Checkbox";
import Button from "src/components/Button/Button";
import ManageShow from "src/routes/Event/ManageShow/ManageShow";

import styles from "./DeductionForm.module.scss";

interface IProps {
  deductions: IDeduction[],
  result: any,
  onChange(deductions: IDeduction[], reorder?: boolean): void,
  show: IShow,
  event?: IEventSummary,
  onExerciseChange?(): void
}

export interface IDeduction {
  label: string,
  id: string,
  amount: string,
  sign?: string,
  disabled: boolean
}

const DeductionForm: React.FC<IProps> = (props) => {
  const [deductions, setDeductions] = useState<IDeduction[]>(Array.from(props.deductions));
  const { isShown, toggle } = useModal();

  const isDragDisabled = () => {
    /*let draggableLevels:string[] = [
      Constants.dog_class_element_level.open_b,
      Constants.dog_class_element_level.preferred_open,
      Constants.dog_class_element_level.preferred_utility,
      Constants.dog_class_element_level.ro1_a,
      Constants.dog_class_element_level.ro1_b,
      Constants.dog_class_element_level.ro1_c,
      Constants.dog_class_element_level.ro2_a,
      Constants.dog_class_element_level.ro2_b,
      Constants.dog_class_element_level.ro3_a,
      Constants.dog_class_element_level.ro3_b,
      Constants.dog_class_element_level.rom_a,
      Constants.dog_class_element_level.rom_b,
      Constants.dog_class_element_level.rae,
      Constants.dog_class_element_level.raem,
      Constants.dog_class_element_level.pre_novice,
      Constants.dog_class_element_level.beginner_novice,
      Constants.dog_class_element_level.beginner_novice_a,
      Constants.dog_class_element_level.beginner_novice_b,
      Constants.dog_class_element_level.preferred_novice,
      Constants.dog_class_element_level.novice_a,
      Constants.dog_class_element_level.novice_b,
      Constants.dog_class_element_level.novice_c,
      Constants.dog_class_element_level.advance_novice,
      Constants.dog_class_element_level.graduate_novice,
      Constants.dog_class_element_level.open_a,
      Constants.dog_class_element_level.open_b,
      Constants.dog_class_element_level.advance_open,
      Constants.dog_class_element_level.preferred_open,
      Constants.dog_class_element_level.graduate_open,
      Constants.dog_class_element_level.utility_a,
      Constants.dog_class_element_level.utility_b,
      Constants.dog_class_element_level.preferred_utility,
      Constants.dog_class_element_level.wc_novice,
      Constants.dog_class_element_level.wc_open,
      Constants.dog_class_element_level.wc_utility,
      Constants.dog_class_element_level.udx
    ];

    if (draggableLevels.includes(props.result?.dog_class?.level)) {
      return false;
    }*/

    return true;
  }

  const isDisabled = (d: IDeduction) => {
    if (d.disabled !== undefined && d.disabled === true) {
      return true;
    }

    return false;
  }

  const getDraggableClassName = (d: IDeduction) => {
    let className:any = `${styles.DeductionDraggable}`;

    if (isDragDisabled()) {
      className += ` ${styles.DragDisabled}`;
    }

    if (isDisabled(d)) {
      className += ` ${styles.disabled}`;
    }

    return className;
  }

  const toggleDisableDeduction = (index: number) => {
    let _deductions = Array.from(deductions);

    _deductions[index].disabled = !_deductions[index].disabled;
    props.onChange(_deductions, true);
    setDeductions(_deductions);
  }

  const updateDeduction = (index: number, value: string) => {
    let _deductions = Array.from(deductions);

    _deductions[index].amount = value;
    props.onChange(_deductions);
    setDeductions(_deductions);
  }

  const reorder = useCallback((_oldDeductions: IDeduction[], startIndex: number, endIndex: number) => {
    let _deductions = Array.from(_oldDeductions);

    let [removed] = _deductions.splice(startIndex, 1);
    _deductions.splice(endIndex, 0, removed);

    return _deductions;
  }, []);

  let { onChange } = props;

  const onDragEnd = useCallback((result: any) => {
    const { source, destination } = result;

    const sInd = source.droppableId;
    const dInd = destination.droppableId;

    if (!destination) {
      return;
    }

    let _deductions = Array.from(deductions);

    if (sInd === dInd) {
      let newDeductions = reorder(_deductions, source.index, destination.index);
      onChange(newDeductions, true);
      setDeductions(newDeductions);
    }
  }, [deductions, reorder, onChange]);

  return (
    <div className={styles.DeductionForm}>
      <DragDropContext
       onDragEnd={onDragEnd}
      >
        <Droppable key="0" droppableId="0">
        {(provided, snapshot) => (
          <div ref={provided.innerRef} className={styles.DeductionDroppable} {...provided.droppableProps}>
          {deductions.filter((d: IDeduction) => !d.disabled).length === 0 && <>
            <div className={styles.fixExercise}>
              <p>You haven't selected your exercises for this show level.</p>
              <Button type="button" onClick={(e) => { toggle(); }}>Select Exercises</Button>
            </div>
          </>}
          {deductions.filter((d: IDeduction) => !d.disabled).map((d: IDeduction, index: number) => {
            return <Draggable isDragDisabled={isDragDisabled()} key={index} draggableId={index.toString()} index={index}>
              {(provided, snapshot) => (  
                <div
                  className={getDraggableClassName(d)}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                >
                  <div className={styles.Handle} {...provided.dragHandleProps}>
                    <div className={`${styles.icon} ${styles.handle}`}>
                    </div>
                  </div>
                  <Input disabled={d.disabled} className={styles.DeductionInput} type="text" onChange={(e:any) => { updateDeduction(index, e.target.value); }} label={(index + 1) +". "+d.label+ " (Point Deduction)"} placeholder={"Total " + d.label + " Deductions"} defaultValue={d.amount} />
                  {false && d.disabled !== undefined && <div className={styles.disableToggle}>
                    <Checkbox id={d.id + "-disabled"} onChange={(e) => { toggleDisableDeduction(index); }} value={!d.disabled} label={""} />
                  </div>}
                </div>
              )}
            </Draggable>
          })}
          {provided.placeholder}
          </div>
        )}
        </Droppable>
      </DragDropContext>
      <Modal
        className={"manageShowModal"}
        isShown={isShown}
        hide={toggle}
        modalContent={props.event ? <ManageShow event={props.event} level={props.result?.dog_class?.level || undefined} show={props.show} onUpdate={() => { if (props.onExerciseChange) { props.onExerciseChange(); } }} /> : <></>}
      />
    </div>
  );
};

export default DeductionForm;