import React, { useEffect, useState } from "react";
import { Constants, Deductions } from "@eagerdog/constants";
import { apiService } from "src/services";
import { IShow, IShowElement, IShowElementExercise, IEventSummary } from "@eagerdog/interfaces";

import { helperService } from "src/services/helper.service";

import Dropdown, { IOption } from "src/components/Dropdown/Dropdown";
import StopWatch, { convertTimeToSeconds, convertTimeToMinutes } from "../StopWatch/StopWatch";
import DeductionForm, { IDeduction } from "../DeductionForm/DeductionForm";

import "./RallyObedienceResults.scss";

interface IProps {
  result: any,
  onChange(result: any, show?: IShow): void,
  event: IEventSummary
  onExerciseChange(): void
}

const RallyObedienceResults: React.FC<IProps> = (props) => {
  const [currentShow, setCurrentShow] = useState<IShow>();

  const getOptionLabel = (id: string) => {
    switch (id) {
      case Constants.score_result_type.qualified:
        return "Qualified";
      case Constants.score_result_type.not_qualified:
        return "Not Qualified";
      case Constants.score_result_type.disqualified:
        return "Disqualified";
      case Constants.score_result_type.excused:
        return "Excused";
      case Constants.score_result_type.absent:
        return "Absent";
    }

    return "None";
  }

  const [deductions, setDeductions] = useState<IDeduction[]>([]);
  const [result, setResult] = useState<IOption>({ id: props.result.rally_obedience?.result_type ? props.result.rally_obedience?.result_type : "None", value: props.result.rally_obedience?.result_type ? getOptionLabel(props.result.rally_obedience?.result_type) : "None" });
  const [time, setTime] = useState<string>(props.result.rally_obedience?.time ? convertTimeToMinutes(props.result.rally_obedience?.time.toString()) : "");

  const resultOptions:IOption[] = [
    { id: "None", value: "None" },
    { id: Constants.score_result_type.qualified, value: "Qualified" },
    { id: Constants.score_result_type.not_qualified, value: "Not Qualified" },
    { id: Constants.score_result_type.disqualified, value: "Disqualified" },
    { id: Constants.score_result_type.excused, value: "Excused" },
    { id: Constants.score_result_type.absent, value: "Absent" }
  ]

  const pResult = helperService.usePrevious(result);
  const pDeductions = helperService.usePrevious(deductions);
  const pTime = helperService.usePrevious(time);

  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!loaded) {
      const getExerciseOrder = (showElement:IShowElement | string) => {
        return new Promise((resolve) => {
          let hasExerciseOrder:string[] = [
            Constants.show_type.obedience,
            Constants.show_type.rally_obedience
          ];

          if (typeof showElement === "string") {
            resolve("I");
          } else if (hasExerciseOrder.includes(props.result.show_type)) {
            if (showElement.order_of_exercises !== undefined) {
              resolve(showElement.order_of_exercises);
            } else {
              resolve("I");
            }
          } else {
            resolve("I");
          }
        });
      }

      const getShow = () => {
        return new Promise<IShow|string>((resolve) => {
          let _query:any = {
            query: {
              $and: [
                {
                  attribute_name: "_id",
                  attribute_value: {
                    operator: "$eq",
                    value: props.result.show_id,
                  },
                }
              ]
            },
          };

          apiService.getClubEventShows(props.result.club_id, props.result.event_id, _query).then((response) => {
            if (response.length > 0) {
              resolve(response[0]);
            } else {
              resolve("NO_SHOW");
            }
          }).catch(() => {
            resolve("NO_SHOW");
          });
        });
      }

      const getShowElement = (show: IShow) => {
        return new Promise<IShowElement | string>((resolve) => {
            if (show.show_elements) {
              for (let se in show.show_elements) {
                if (show.show_elements[se].level === props.result.dog_class.level) {
                  resolve(show.show_elements[se]);
                }
              }
            }

            resolve("NO_SHOW_ELEMENT");
        });
      }

      const getDeductionAmount = (currentDeduction: string) => {
        let hasDeduction = props.result.rally_obedience?.element_deductions?.filter((d: any) => { return d.element_name === currentDeduction; });

        if (hasDeduction?.length > 0) {
          return hasDeduction[0].element_deductions.toString();
        }

        return "0";
      }

      const loadDetailedDeductions = (exerciseOrder: string) => {
        let _deductions:IDeduction[] = [];

        if (exerciseOrder !== undefined) {
          let _deductionDef:any[] = Deductions[props.result.sanctioning_club][props.result.show_type][props.result.dog_class.level][exerciseOrder];

          if (_deductionDef?.length > 0) {
            for (let d in _deductionDef) {
              _deductions.push({
                label: _deductionDef[d].exercise ? _deductionDef[d].exercise : _deductionDef[d],
                id: _deductionDef[d].exercise ? _deductionDef[d].exercise.toLowerCase().replaceAll(" ", "_") : _deductionDef[d].toLowerCase().replaceAll(" ", "_"),
                amount: props.result.rally_obedience !== undefined ? getDeductionAmount(_deductionDef[d].exercise ? _deductionDef[d].exercise : _deductionDef[d]) : "0",
                disabled: false,
                sign: _deductionDef[d].sign
              });
            }
          }
        }

        return _deductions;
      }

      const updateShowExercises = (deductions: IDeduction[], show: IShow, showElement: IShowElement | string) => {
        let _show:any = JSON.parse(JSON.stringify(show));

        if (typeof showElement !== "string") {
          let deductionPayload:any = deductions.map((d: IDeduction, index: number) => {
            return {
              sign: d.sign,
              exercise: d.label,
              disabled: d.disabled
            };
          });

          for (let se in _show.show_elements) {
            if (_show.show_elements[se].level === props.result.dog_class.level) {
              _show.show_elements[se].exercises = deductionPayload;
              break;
            }
          }

          setCurrentShow(show);
        }
      }

      const loadExercises = async() => {
        const show:IShow | string = await getShow();

        if (typeof show !== "string") {
          setCurrentShow(show);
          const showElement:IShowElement | string = await getShowElement(show);
          const exerciseOrder:any = await getExerciseOrder(showElement);

          if (typeof showElement !== "string" && showElement.exercises !== undefined && showElement.exercises.length > 0) {
            let _deductions:IDeduction[] = showElement.exercises.map((exercise: IShowElementExercise, index: number) => {
              return {
                label: exercise.exercise,
                id: exercise.exercise.toLowerCase().replaceAll(" ", "_"),
                amount: props.result.rally_obedience !== undefined ? getDeductionAmount(exercise.exercise) : "0",
                disabled: exercise.disabled || false,
                sign: exercise.sign
              };
            });

            setDeductions(_deductions);
          } else {
            if (exerciseOrder !== undefined) {
              let _deductions:IDeduction[] = loadDetailedDeductions(exerciseOrder);
              setDeductions(_deductions);
              updateShowExercises(_deductions, show, showElement);
            }
          }

          setLoaded(true);
        }
      }

      loadExercises();
    }
  }, [loaded, props.result.club_id, props.result.dog_class_level, props.result.event_id, props.result.show_id, props.result.dog_class.level, props.result.show_type, props.result.rally_obedience, props.result.sanctioning_club]);

  let { onChange } = props;

  useEffect(() => {
    let _pResult:any = pResult as any;

    if ((_pResult !== undefined && _pResult.id !== result.id) || (deductions !== pDeductions) || (time !== pTime)) {
      onChange({
        result_type: result.id,
        element_deductions: deductions.filter((d: IDeduction) => !d.disabled).map((d: IDeduction) => {
          return {
            element_name: d.label,
            element_deductions: Number(d.amount)
          }
        }),
        time: convertTimeToSeconds(time)
      }, currentShow);
    }
  }, [deductions, result, pResult, pDeductions, time, pTime, onChange, currentShow]);

  const reorderExerciseList = (deductions: IDeduction[]) => {
    if (currentShow) {
      let _show:IShow = JSON.parse(JSON.stringify(currentShow));

      let deductionPayload:any = deductions.map((d: IDeduction, index: number) => {
        return {
          sign: d.sign,
          exercise: d.label,
          disabled: d.disabled
        };
      });

      for (let se in _show.show_elements) {
        if (_show.show_elements[se].level === props.result.dog_class.level) {
          _show.show_elements[se].exercises = deductionPayload;
          break;
        }
      }

      setCurrentShow(_show);
    }
  }

  return (
    <div className="RallyObedienceResults">
      <div className="row"><Dropdown value={result} onChange={(e: any, value: IOption) => { setResult(value); }} label="Result" options={resultOptions} placeholder="" /></div>
      <div className="row"><StopWatch time={time} onChange={(value: string) => { setTime(value); }} /></div>
      {loaded && currentShow && <DeductionForm
        result={props.result}
        deductions={deductions}
        show={currentShow}
        event={props.event}
        onExerciseChange={() => { setLoaded(false); }}
        onChange={(newDeductions: IDeduction[], reorder?: boolean) => {
          if (reorder === true) {
            reorderExerciseList(newDeductions);
          }

          setDeductions(newDeductions);
        }}
      />}
    </div>
  );
};

export default RallyObedienceResults;