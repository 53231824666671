import React, { useState, useEffect, useCallback } from "react";
import { Constants } from "@eagerdog/constants";
import { IEvent, IShow, IShowElement, IShowElementExercise } from "@eagerdog/interfaces";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

import { apiService } from "src/services";

import { toast } from "src/components/Toast/ToastManager";
import Dropdown, { IOption } from "src/components/Dropdown/Dropdown";
import Button from "src/components/Button/Button";
import ExerciseItem from "./ExerciseItem/ExerciseItem";

//import { apiService } from "src/services";

import styles from "./ExerciseTab.module.scss";

interface IProps {
  event: IEvent,
  show: IShow,
  onUpdate(): void,
  startAt?: string
}

const ExerciseTab: React.FC<IProps> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [updatedShow, setUpdatedShow] = useState<IShow>(JSON.parse(JSON.stringify(props.show)));
  const [showElements, setShowElements] = useState<IOption[]>([{ value: "Select a Level", id: "Select a Level" }]);
  const [selectedElement, setSelectedElement] = useState<IOption>({ value: "Select a Level", id: "Select a Level" });

  const reorder = useCallback((startIndex: number, endIndex: number, currentElement: string) => {
    let newShow:IShow = JSON.parse(JSON.stringify(updatedShow));

    let hasElement:IShowElement[] = newShow.show_elements.filter((se: IShowElement) => se.level === currentElement);

    if (hasElement.length > 0) {
      let updatedShow:IShowElement = hasElement[0];

      if (updatedShow.exercises) {
        const [removed] = updatedShow.exercises.splice(startIndex, 1);
        updatedShow.exercises.splice(endIndex, 0, removed);
      }

      newShow.show_elements[newShow.show_elements.findIndex((se: IShowElement) => se.level === currentElement)] = updatedShow;
    }

    return newShow;

  }, [updatedShow]);

  const onDragEnd = useCallback((result: any) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    let newShow:IShow = reorder(source.index, destination.index, selectedElement.value);
    setUpdatedShow(newShow);

    let hasElement:IShowElement[] = newShow.show_elements.filter((se: IShowElement) => se.level === selectedElement.value);

    if (hasElement.length > 0) {
      let updatedShow:IShowElement = hasElement[0];

      let newOption:IOption = {
        ...selectedElement,
        ...{ data: updatedShow }
      };

      setSelectedElement(newOption);
    }

  }, [selectedElement, reorder]);

  useEffect(() => {
    if (!loaded) {
      let elementOptions:IOption[] = updatedShow.show_elements.filter((se: IShowElement) => se.exercises && se.exercises.length > 0).map((se: IShowElement, index: number) => {
        return({
          value: se.level || se.show_element,
          id: se.level?.toLowerCase().replaceAll(" ", "_") || se.show_element.toLowerCase().replaceAll(" ", "_"),
          disabled: false,
          data: se
        });
      });

      if (props.startAt) {
        if (elementOptions.findIndex((i: IOption) => i.value === props.startAt) >= 0) {
          setSelectedElement(elementOptions[elementOptions.findIndex((i: IOption) => i.value === props.startAt)]);
        }
      }

      setShowElements(elementOptions);
      setLoaded(true);
    }
  }, [loaded, updatedShow, props.startAt]);

  const toggleDisable = (currentElement: IOption, index: number) => {
    let newElement = JSON.parse(JSON.stringify(currentElement));

    if (newElement.data.exercises) {
      newElement.data.exercises[index].disabled = !newElement.data.exercises[index].disabled;
    }

    let newShow:IShow = JSON.parse(JSON.stringify(updatedShow));
    let hasElement:IShowElement[] = newShow.show_elements.filter((se: IShowElement) => se.level === newElement.value);

    if (hasElement.length > 0) {
      newShow.show_elements[newShow.show_elements.findIndex((se: IShowElement) => se.level === newElement.value)] = newElement.data;
      setUpdatedShow(newShow);
    }

    setSelectedElement(newElement);
  }

  const saveShow = () => {
    let newShow:any = JSON.parse(JSON.stringify(updatedShow));
    delete newShow.__v;

    apiService.updateShow(props.event._id, props.show._id, newShow).then((response) => {
      toast.show({
        title: "Show Exercises",
        content: "You've successfully updated your show exercises for " + selectedElement.value,
        duration: 10000,
        type: "success"
      });

      props.onUpdate();
    }).catch((error: any) => {
      toast.show({
        title: "Show Exercises",
        content: error.response.data.message ? error.response.data.message : "Something went wrong, please try again later",
        duration: 10000,
        type: "fail"
      });
    });
  }

  return (
    <div className={styles.ExerciseTab}>
      <div className={styles.tip}>
        Select a level to order and disable exercises for that order. To disable an exercise that's not running at your show, uncheck the checkbox. When you're finished, 'Save' the list at the bottom of the modal.
      </div>
      <Dropdown className={styles.elementSelect} value={selectedElement} onChange={(e: any, value :IOption) => { setSelectedElement(value); }} label="Level" options={showElements} placeholder="" />
      {selectedElement.id !== "Select a Level" && <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={"exercises"}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} className={styles.JudgeDroppable} {...provided.droppableProps}>
            {selectedElement.data !== undefined && <>
              {selectedElement.data.exercises && selectedElement.data.exercises.map((exercise: IShowElementExercise, index: number) => {
                return(
                  <Draggable isDragDisabled={props.show.show_type === Constants.show_type.obedience} key={exercise.exercise+"-"+index} draggableId={exercise.exercise+"-"+index} index={index}>
                    {(provided, snapshot) => (
                      <div
                        className={styles.exerciseDragabble}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <ExerciseItem
                          index={index}
                          exercise={exercise}
                          onDisable={() => { toggleDisable(selectedElement, index) }}
                          allowDisable={props.show.show_type !== Constants.show_type.obedience}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
            </>}
          </div>
        )}
        </Droppable>
      </DragDropContext>}
      {selectedElement.data !== undefined && <div className={styles.actions}>
        <Button onClick={() => { saveShow(); }}>Save Exercises</Button>
      </div>}
    </div>
  );
};

export default ExerciseTab;