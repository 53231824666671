import React, { useEffect, useState } from "react";
import { Constants } from "@eagerdog/constants";
import { IShow, IShowElement, IShowElementExercise } from "@eagerdog/interfaces";

import { helperService } from "src/services/helper.service";
import { apiService } from "src/services";

import Dropdown, { IOption } from "src/components/Dropdown/Dropdown";
import Checkbox from "src/components/Checkbox/Checkbox";
import Input from "src/components/Input/Input";

import DeductionForm, { IDeduction } from "../DeductionForm/DeductionForm";
import { Deductions } from "@eagerdog/constants";

import "./ObedienceResults.scss";

interface IProps {
  result: any,
  onChange(result: any, show?: IShow): void
}

interface IDeductionReason {
  id: string,
  label: string,
  checked: boolean
}

const ObedienceResults: React.FC<IProps> = (props) => {
  const [currentShow, setCurrentShow] = useState<IShow>();

  const getOptionLabel = (id: string) => {
    switch (id) {
      case Constants.score_result_type.qualified:
        return "Qualified";
      case Constants.score_result_type.not_qualified:
        return "Not Qualified";
      case Constants.score_result_type.disqualified:
        return "Disqualified";
      case Constants.score_result_type.excused:
        return "Excused";
      case Constants.score_result_type.absent:
        return "Absent";
    }

    return "None";
  }

  const [deductionReasons, setDeductionReasons] = useState<IDeductionReason[]>([
    { id: "handler_error", label: Constants.ukc_deduction_reasons.handler_error, checked: props.result.obedience?.extra_deduction_reasons?.includes("handler_error") ? true : false },
    { id: "disciplining_dog", label: Constants.ukc_deduction_reasons.disciplining_dog, checked: props.result.obedience?.extra_deduction_reasons?.includes("disciplining_dog") ? true : false },
    { id: "unsportsmanlike_conduct", label: Constants.ukc_deduction_reasons.unsportsmanlike_conduct, checked: props.result.obedience?.extra_deduction_reasons?.includes("unsportsmanlike_conduct") ? true : false },
    { id: "dog_misbehaviour", label: Constants.ukc_deduction_reasons.dog_misbehaviour, checked: props.result.obedience?.extra_deduction_reasons?.includes("dog_misbehaviour") ? true : false },
    { id: "other", label: "Other", checked: props.result.obedience?.extra_deduction_reasons?.includes("other") ? true : false }
  ]);

  const [nqReasons, setNqReasons] = useState<IDeductionReason[]>([
    { id: "dog_shows_fear", label: Constants.ukc_non_qualifying_reasons.dog_shows_fear, checked: props.result.obedience?.non_qualifying_reasons?.includes("dog_shows_fear") ? true : false },
    { id: "fouling_ring", label: Constants.ukc_non_qualifying_reasons.fouling_ring, checked: props.result.obedience?.non_qualifying_reasons?.includes("fouling_ring") ? true : false }
  ]);

  const [other, setOther] = useState<string>(props.result.obedience?.other_error ? props.result.obedience.other_error : "");
  const [extraPoints, setExtraPoints] = useState<string>(props.result.obedience?.extra_deductions ? props.result.obedience?.extra_deductions.toString() : "0");

  const [resultReason, setResultReason] = useState<string>(props.result.obedience?.result_reason ? props.result.obedience?.result_reason : "");

  const [deductions, setDeductions] = useState<IDeduction[]>([]);
  const [result, setResult] = useState<IOption>({ id: props.result.obedience?.result_type ? props.result.obedience?.result_type : "None", value: props.result.obedience?.result_type ? getOptionLabel(props.result.obedience?.result_type) : "None" });

  const resultOptions:IOption[] = [
    { id: "None", value: "None" },
    { id: Constants.score_result_type.qualified, value: "Qualified" },
    { id: Constants.score_result_type.not_qualified, value: "Not Qualified" },
    { id: Constants.score_result_type.disqualified, value: "Disqualified" },
    { id: Constants.score_result_type.excused, value: "Excused" },
    { id: Constants.score_result_type.absent, value: "Absent" }
  ];

  const pResult = helperService.usePrevious(result);
  const pDeductions = helperService.usePrevious(deductions);
  const pDeductionReasons = helperService.usePrevious(deductionReasons);
  const pNqReasons = helperService.usePrevious(nqReasons);
  const pOther = helperService.usePrevious(other);
  const pExtraPoints = helperService.usePrevious(extraPoints);
  const pResultReason = helperService.usePrevious(resultReason);

  let { onChange } = props;

  useEffect(() => {
    let _pResult:any = pResult as any;

    if ((_pResult !== undefined && _pResult.id !== result.id) || (deductions !== pDeductions) || (deductionReasons !== pDeductionReasons) || (nqReasons !== pNqReasons) || (other !== pOther) || (extraPoints !== pExtraPoints) || (resultReason !== pResultReason)) {
      let changePayload:any = {
        result_type: result.id,
        element_deductions: deductions.filter((d: IDeduction) => !d.disabled).map((d: IDeduction) => {
          return {
            element_name: d.label,
            element_deductions: d.amount
          }
        })
      };

      if (props.result.sanctioning_club === Constants.sanctioning_club.UKC) {
        changePayload.extra_deductions = parseInt(extraPoints);
        changePayload.extra_deduction_reasons = deductionReasons.filter((r: IDeductionReason, index: number) => { if (r.checked) { return r.label; } return false; }).map((r: IDeductionReason, index: number) => { return r.label; });
        changePayload.non_qualifying_reasons = nqReasons.filter((r: IDeductionReason, index: number) => { if (r.checked) { return r.label; } return false; }).map((r: IDeductionReason, index: number) => { return r.label; });
        changePayload.other_error = other;

        if ((result.id === Constants.score_result_type.disqualified || result.id === Constants.score_result_type.excused)) {
          changePayload.result_reason = resultReason;
        } else {
          changePayload.result_reason = undefined;
        }
      }

      onChange(changePayload, currentShow);
    }
  }, [deductions, result, pResult, pDeductions, deductionReasons, pDeductionReasons, nqReasons, pNqReasons, other, pOther, extraPoints, pExtraPoints, resultReason, pResultReason, props.result.sanctioning_club, onChange, currentShow]);

  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!loaded) {
      const getExerciseOrder = (showElement:IShowElement | string) => {
        return new Promise((resolve) => {
          let hasExerciseOrder:string[] = [
            Constants.show_type.obedience,
            Constants.show_type.rally_obedience
          ];

          if (typeof showElement === "string") {
            resolve("I");
          } else if (hasExerciseOrder.includes(props.result.show_type)) {
            if (showElement.order_of_exercises !== undefined) {
              resolve(showElement.order_of_exercises);
            } else {
              resolve("I");
            }
          } else {
            resolve("I");
          }
        });
      }

      const getShow = () => {
        return new Promise<IShow|string>((resolve) => {
          let _query:any = {
            query: {
              $and: [
                {
                  attribute_name: "_id",
                  attribute_value: {
                    operator: "$eq",
                    value: props.result.show_id,
                  },
                }
              ]
            },
          };

          apiService.getClubEventShows(props.result.club_id, props.result.event_id, _query).then((response) => {
            if (response.length > 0) {
              resolve(response[0]);
            } else {
              resolve("NO_SHOW");
            }
          }).catch(() => {
            resolve("NO_SHOW");
          });
        });
      }

      const getShowElement = (show: IShow) => {
        return new Promise<IShowElement | string>((resolve) => {
            if (show.show_elements) {
              for (let se in show.show_elements) {
                if (show.show_elements[se].level === props.result.dog_class.level) {
                  resolve(show.show_elements[se]);
                }
              }
            }

            resolve("NO_SHOW_ELEMENT");
        });
      }

      const getDeductionAmount = (currentDeduction: string) => {
        let hasDeduction = props.result.obedience?.element_deductions?.filter((d: any) => { return d.element_name === currentDeduction; });

        if (hasDeduction?.length > 0) {
          return hasDeduction[0].element_deductions.toString();
        }

        return "0";
      }

      const loadDetailedDeductions = (exerciseOrder: string) => {
        let _deductions:IDeduction[] = [];

        if (exerciseOrder !== undefined) {
          let _deductionDef:any[] = Deductions[props.result.sanctioning_club][props.result.show_type][props.result.dog_class.level][exerciseOrder];

          if (_deductionDef?.length > 0) {
            for (let d in _deductionDef) {
              _deductions.push({
                label: _deductionDef[d].exercise ? _deductionDef[d].exercise : _deductionDef[d],
                id: _deductionDef[d].exercise ? _deductionDef[d].exercise.toLowerCase().replaceAll(" ", "_") : _deductionDef[d].toLowerCase().replaceAll(" ", "_"),
                amount: props.result.obedience !== undefined ? getDeductionAmount(_deductionDef[d].exercise ? _deductionDef[d].exercise : _deductionDef[d]) : "0",
                disabled: false
              });
            }
          }
        }

        return _deductions;
      }

      const updateShowExercises = (deductions: IDeduction[], show: IShow, showElement: IShowElement | string) => {
        let _show:any = JSON.parse(JSON.stringify(show));

        if (typeof showElement !== "string") {
          let deductionPayload:any = deductions.map((d: IDeduction, index: number) => {
            return {
              exercise: d.label,
              disabled: d.disabled
            };
          });

          for (let se in _show.show_elements) {
            if (_show.show_elements[se].level === props.result.dog_class.level) {
              _show.show_elements[se].exercises = deductionPayload;
              break;
            }
          }

          setCurrentShow(show);
        } else {

        }
      }

      const loadExercises = async() => {
        const show:IShow | string = await getShow();

        if (typeof show !== "string") {
          setCurrentShow(show);
          const showElement:IShowElement | string = await getShowElement(show);
          const exerciseOrder:any = await getExerciseOrder(showElement);

          if (typeof showElement !== "string" && showElement.exercises !== undefined && showElement.exercises.length > 0) {
            let _deductions:IDeduction[] = showElement.exercises.map((exercise: IShowElementExercise, index: number) => {
              return {
                label: exercise.exercise,
                id: exercise.exercise.toLowerCase().replaceAll(" ", "_"),
                amount: props.result.obedience !== undefined ? getDeductionAmount(exercise.exercise) : "0",
                disabled: exercise.disabled || false
              };
            });

            setDeductions(_deductions);
          } else {
            if (exerciseOrder !== undefined) {
              let _deductions:IDeduction[] = loadDetailedDeductions(exerciseOrder);
              setDeductions(_deductions);
              updateShowExercises(_deductions, show, showElement);
            }
          }

          setLoaded(true);
        }
      }

      loadExercises();
    }
  }, [loaded, props.result.club_id, props.result.dog_class_level, props.result.event_id, props.result.show_id, props.result.dog_class.level, props.result.show_type, props.result.obedience, props.result.sanctioning_club]);

  const handleReasonCheck = (e: any, index: number) => {
    let _deductionReasons:IDeductionReason[] = Array.from(deductionReasons);

    for (let r in _deductionReasons) {
      if (parseInt(r) === index) {
        _deductionReasons[r] = { ..._deductionReasons[r], checked: !_deductionReasons[r].checked };
      }
    }

    setDeductionReasons(_deductionReasons);
  }

  const handleNqCheck = (e: any, index: number) => {
    let _nqReasons:IDeductionReason[] = Array.from(nqReasons);

    for (let r in _nqReasons) {
      if (parseInt(r) === index) {
        _nqReasons[r] = { ..._nqReasons[r], checked: !_nqReasons[r].checked };
      }
    }

    setNqReasons(_nqReasons);
  }

  const isOtherChecked = () => {
    let isOtherChecked = deductionReasons.filter((r: IDeductionReason) => { return r.id === "other" && r.checked === true; })

    return isOtherChecked.length > 0;
  }

  const getResultReasonLabel = () => {
    let label:string = "";

    switch (result.id) {
      case Constants.score_result_type.disqualified:
        label = "Disqualified For";
        break;
      case Constants.score_result_type.excused:
        label = "Excused For";
        break;
    }

    return label;
  }

  const reorderExerciseList = (deductions: IDeduction[]) => {
    if (currentShow) {
      let _show:IShow = JSON.parse(JSON.stringify(currentShow));

      let deductionPayload:any = deductions.map((d: IDeduction, index: number) => {
        return {
          sign: d.sign,
          exercise: d.label,
          disabled: d.disabled
        };
      });

      for (let se in _show.show_elements) {
        if (_show.show_elements[se].level === props.result.dog_class.level) {
          _show.show_elements[se].exercises = deductionPayload;
          break;
        }
      }

      setCurrentShow(_show);
    }
  }

  return (
    <div className="ObedienceResults">
      <div className="row"><Dropdown value={result} onChange={(e: any, value: IOption) => { setResult(value); }} label="Result" options={resultOptions} placeholder="" /></div>
      {(result.id === Constants.score_result_type.disqualified || result.id === Constants.score_result_type.excused) && props.result.sanctioning_club === Constants.sanctioning_club.UKC && <div className="resultReason">
        <div className="inputWrap">
          <Input type="text" onChange={(e: any) => { setResultReason(e.target.value); }} label={getResultReasonLabel()} placeholder="" defaultValue={resultReason} />
        </div>
      </div>}
      {loaded && currentShow && <DeductionForm
        result={props.result}
        deductions={deductions}
        show={currentShow}
        onChange={(newDeductions: IDeduction[], reorder?: boolean) => {
          if (reorder === true) {
            reorderExerciseList(newDeductions);
          }

          setDeductions(newDeductions);
        }}
      />}
      {loaded && props.result.sanctioning_club === Constants.sanctioning_club.UKC && <>
        <div className="subheader">
          Points Deducted For
        </div>
        <div className="deductionReasonWrap">
          {deductionReasons.map((reason:IDeductionReason, index: number) => {
            return(
              <div key={index} className="reason">
                <Checkbox id={reason.id} onChange={(e) => { handleReasonCheck(e, index); }} value={reason.checked} label={reason.label} />
              </div>
            );
          })}
        </div>
        {isOtherChecked() && <div className="inputWrap">
          <Input type="text" required={true} onChange={(e: any) => { setOther(e.target.value); }} label="Other Deduction Reason" placeholder="Other Deduction Reason" defaultValue={other} />
        </div>}
        <div className="inputWrap">
          <Input type="number" required={true} onChange={(e: any) => { setExtraPoints(e.target.value); }} label="Less Points Deducted" placeholder="Less Points Deducted" defaultValue={extraPoints} />
        </div>
        <div className="subheader">
          Non-Qualifying Score For
        </div>
        <div className="deductionReasonWrap">
          {nqReasons.map((reason:IDeductionReason, index: number) => {
            return(
              <div key={index} className="reason">
                <Checkbox id={reason.id} onChange={(e) => { handleNqCheck(e, index); }} value={reason.checked} label={reason.label} />
              </div>
            );
          })}
        </div>
      </>}
    </div>
  );
};

export default ObedienceResults;