import React, { useState, useEffect, useCallback } from 'react';
import moment from "moment";
import { Constants } from "@eagerdog/constants";
import { helperService, IEventRegisterInput, IEventRegisterRow, nonLicensed } from "../../services/helper.service";
import { apiService } from "../../services";

import { IDogClass, IShow, IShowElement, IDog, IEvent, IDogRegistrationInfo, IShowRunTimeSlot } from "@eagerdog/interfaces";

import { Nosework } from "./forms/Nosework";
import { Conformation } from "./forms/Conformation";
import { ConformationJunior } from "./forms/ConformationJunior";
import { Obedience } from "./forms/Obedience";
import { RallyObedience } from "./forms/RallyObedience";
import { Agility } from "./forms/Agility";
import { WeightPull } from "./forms/WeightPull";
import { UKCPrecisionCoursing } from "./forms/UKCPrecisionCoursing";
import { UKCShedDog } from "./forms/UKCShedDog";
import { DockJumping } from "./forms/DockJumping";

import { ABIConformation } from "./forms/ABIConformation";
import { ABIUrbanRatRace } from "./forms/ABIUrbanRatRace";
import { ABICountryRatRace } from "./forms/ABICountryRatRace";

import { AKCConformation } from "./forms/AKCConformation";
import { AKCObedience } from "./forms/AKCObedience";
import { AKCRallyObedience  } from "./forms/AKCRallyObedience";
import { AKCScentwork } from "./forms/AKCScentwork";
import { AKCLureCoursing } from "./forms/AKCLureCoursing";

import { AKCFastCat } from "./forms/AKCFastCat";
import { AKCFetch } from "./forms/AKCFetch";

import Dropdown, { IOption } from "../../components/Dropdown/Dropdown";
import Checkbox from "../../components/Checkbox/Checkbox";
import Input from "../../components/Input/Input";
import { toast } from "../../components/Toast/ToastManager";

import TimeSlot from "./TimeSlot/TimeSlot";

import './FormBuilder.scss';

interface IProps {
  classes: IDogClass[],
  dog: IDog | IDogRegistrationInfo,
  onChange(result: any): void,
  show: IShow,
  event?: IEvent,
  edit?: boolean,
  moveUp?: boolean,
  overrideDisable?: boolean,
  isJunior?: boolean
}

export interface IDogClassElement extends IDogClass {
  id: string,
  start_minutes?: number,
  time_slot?: string,
  score_id?: string
}

interface IEventRegisterInputElement extends IEventRegisterInput {
  id: string
}

interface IEventRegisterRowElement {
  allowDuplicates?: boolean,
  duplicate?: boolean,
  inputs: IEventRegisterInputElement[]
}

const FormBuilder: React.FC<IProps> = (props) => {
  const [classes, setClasses] = useState<IDogClassElement[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [form, setForm] = useState<IEventRegisterRowElement[]>();

  const [timeslots, setTimeslots] = useState<IShowRunTimeSlot[]>([]);
  const [selectedTimeslot, setSelectedTimeslot] = useState<number>(-1);

  const [elementCount, setElementCount] = useState<number>();

  const prevClasses = helperService.usePrevious(classes);

  const createId = () => {
    return Math.random().toString(36).substring(2, 8);
  }

  const yearList:any[] = [
    { level: Constants.dog_class_element_level.novice_puppy, min: 0, max: 6 },
    { level: Constants.dog_class_element_level.puppy, min: 6, max: 12 },
    { level: Constants.dog_class_element_level.junior, min: 12, max: 24 },
    { level: Constants.dog_class_element_level.adult, min: 24, max: -1 },
    { level: Constants.dog_class_element_level.open, min: 6, max: -1 },
    { level: Constants.dog_class_element_level.breader_handler, min: 6, max: -1 },
    { level: Constants.dog_class_element_level.champion, min: 6, max: -1 },
    { level: Constants.dog_class_element_level.grand_champion, min: 6, max: -1 },
    { level: Constants.dog_class_element_level.akc_4_6_month_puppy, min: 4, max: 6 },
    { level: Constants.dog_class_element_level.akc_6_9_month_puppy, min: 6, max: 9 },
    { level: Constants.dog_class_element_level.akc_9_12_month_puppy, min: 9, max: 12 },
    { level: Constants.dog_class_element_level.akc_12_18_month_puppy, min: 12, max: 18 },
    { level: Constants.dog_class_element_level.akc_under_12_month_bred_by_exhibitor, min: 0, max: 12 },
    { level: Constants.dog_class_element_level.akc_adult_bred_by_exhibitor, min: 12, max: -1 },
    { level: Constants.dog_class_element_level.akc_veteran_8_10_years, min: 96, max: 120 },
    { level: Constants.dog_class_element_level.akc_veteran_10_12_years, min: 120, max: 156 },
    { level: Constants.dog_class_element_level.akc_veteran_13_plus_years, min: 156, max: -1 },
    { level: Constants.dog_class_element_level.akc_veteran_8_plus_years, min: 96, max: -1 },
    { level: Constants.dog_class_element_level.akc_veteran_7_plus_years, min: 84, max: -1 },
    { level: Constants.dog_class_element_level.akc_veteran_6_8_years, min: 72, max: 96 }
  ];

  const createForm = useCallback((_classes: IDogClassElement[]) => {
    let template:IEventRegisterRow[] = Nosework.form;

    if (props.event) {
      if (props.event.sanctioning_club === Constants.sanctioning_club.UKC) {
        switch (props.show.show_type) {
          case Constants.dog_class_element.conformation:
            if (props.isJunior) {
              template = ConformationJunior.form;
            } else {
              template = Conformation.form;
            }
          break;
          case Constants.dog_class_element.performance_obedience:
            template = Obedience.form;
          break;
          case Constants.dog_class_element.performance_rally_obedience:
            template = RallyObedience.form;
          break;
          case Constants.dog_class_element.performance_agility:
            template = Agility.form;
          break;
          case Constants.dog_class_element.performance_weight_pull:
            template = WeightPull.form;
          break;
          case Constants.dog_class_element.precision_coursing:
            template = UKCPrecisionCoursing.form;
          break;
          case Constants.show_type.shed_dog:
            template = UKCShedDog.form;
          break;
          case Constants.show_type.dock_jumping:
            template = DockJumping.form;
          break;
          default:
            template = Nosework.form;
          break;
        }
      } else if (props.event.sanctioning_club === Constants.sanctioning_club.ABI) {
        switch (props.show.show_type) {
          case Constants.dog_class_element.conformation:
            template = ABIConformation.form;
          break;
          case Constants.dog_class_element.performance_urban_rat_race:
            template = ABIUrbanRatRace.form;
          break;
          case Constants.dog_class_element.performance_country_rat_race:
            template = ABICountryRatRace.form;
          break;
          default:
            template = ABIConformation.form;
          break;
        }
      } else if (props.event.sanctioning_club === Constants.sanctioning_club.AKC) {
        switch (props.show.show_type) {
          case Constants.show_type.lure_coursing:
            template = AKCLureCoursing.form;
          break;
          case Constants.dog_class_element.fast_cat:
          case Constants.dog_class_element.fetch:

            if (props.show.show_type === Constants.dog_class_element.fast_cat) {
              template = AKCFastCat.form;
            } else if (props.show.show_type === Constants.dog_class_element.fetch) {
              template = AKCFetch.form;
            }

            if (props.event._id && props.show._id) {
              apiService.getShowRunTimeSlots(props.event._id, props.show._id).then((response: IShowRunTimeSlot[]) => {
                setTimeslots(response);

                if (helperService.timeSlotShows.includes(props.show.show_type) && _classes.length > 0) {
                  let _time_slot = _classes[0].time_slot;
                  let _timeslotIndex = response.findIndex((t) => t.time_slot === _time_slot);
                  setSelectedTimeslot(_timeslotIndex);
                }
              });
            }

          break;
          case Constants.dog_class_element.conformation:
            template = AKCConformation.form;
          break;
          case Constants.show_type.obedience:
            template = AKCObedience.form;
          break;
          case Constants.show_type.rally_obedience:
            template = AKCRallyObedience.form;
          break;
          case Constants.show_type.fetch:
            template = AKCFetch.form;
          break;
          case Constants.show_type.scentwork:
            template = AKCScentwork.form;
          break;
        }
      }
    }

    let _form:IEventRegisterRowElement[] = [];
    let duplicateOffset:number = 0;

    for (let row in template) {
      _form.push({ allowDuplicates: template[row].allowDuplicates, inputs: [] });

      for (let input in template[row].inputs) {
        let id:string = createId();

        let _class = _classes.filter((c) => {
          if (template[row].inputs[input].label === Constants.dog_class_element.conformation_non_licensed && nonLicensed.includes(c.level)) {
            return true;
          } else {
            return c.show_element === template[row].inputs[input].label;
          }
        });

        if (_class[0]) {
          id = _class[0].id;
        }

        let _input:IEventRegisterInputElement = {...template[row].inputs[input], id: template[row].inputs[input].label === "Section" ? _form[parseInt(row) + duplicateOffset].inputs[(parseInt(input) - 1)].id : id};
        _form[parseInt(row) + duplicateOffset].inputs.push(_input);
      }

      if (_form[parseInt(row) + duplicateOffset].allowDuplicates) {
        let hasDuplicates = _classes.filter((c) => c.show_element === template[row].inputs[0].label);

        for (let d = 0; d < hasDuplicates.length - 1; d++) {
          let _duplicateRow = JSON.parse(JSON.stringify(_form[parseInt(row) + duplicateOffset]));
          _duplicateRow.allowDuplicates = false;
          _duplicateRow.duplicate = true;

          for (let i in _duplicateRow.inputs) {
            _duplicateRow.inputs[i].id = hasDuplicates[d + 1].id;
          }

          _form.push(_duplicateRow);
          duplicateOffset++;
        }
      }
    }

    setForm(_form);
  }, [props.show.show_type, props.event, props.show._id, props.isJunior]);

  useEffect(() => {
    if (!loaded) {
      let _classes:any = JSON.parse(JSON.stringify(props.classes));

      if (props.edit) {
        setElementCount(_classes.length);
      }

      createForm(_classes);
      setClasses(_classes);

      setLoaded(true);
    }
  }, [loaded, createForm, props.classes, props.edit]);

  useEffect(() => {
    if (JSON.stringify(prevClasses) !== JSON.stringify(classes)) {
      let _classes = JSON.parse(JSON.stringify(classes));

      props.onChange(_classes);
    }
  }, [classes, props, prevClasses]);

  const updateClasses = (input: IEventRegisterInputElement, value: IOption, extra?: string, extra_arr?: string) => {
    let _classes = JSON.parse(JSON.stringify(classes));
    let _class = _classes.filter((c: IDogClassElement) => { return (input.label !== "Section" && c.show_element === input.label && c.id === input.id) || (c.id === input.id); })[0];

    if (extra !== undefined) {
      _class = _classes.filter((c: IDogClassElement) => { return c.id === input.id && (c.level === Constants.dog_class_element_level.brace || c.level === Constants.dog_class_element_level.shed_dog_youth || c.level === Constants.dog_class_element_level.pairs); })[0];
    } else if (extra_arr !== undefined) {
      _class = _classes.filter((c: IDogClassElement) => { return c.id === input.id && c.level === Constants.dog_class_element_level.team })[0];
    }

    switch (input.type) {
      case "checkbox":
        _classes = updateCheckboxClass(_classes, _class, input, value, extra ? extra : undefined);
      break;
      case "dropdown":
        _classes = updateDropdownClass(_classes, _class, input, value);
      break;
      case "timeslot":
        _classes = updateTimeslotClass(_classes, _class, input, value);
      break;
    }

    setClasses(_classes);
  }

  const updateTimeslotClass = (oldClasses: IDogClassElement[], hasClass: IDogClassElement, input: IEventRegisterInputElement, value: IOption, extra?: string) => {
    let _classes:IDogClassElement[] = oldClasses;

    if (input.id === Constants.show_type.fast_cat) {
      let _newClass:IDogClassElement = {
        id: input.id,
        show_element: input.label,
        level: input.label,
        time_slot: value.value
      };

      if (hasClass) {
        hasClass.time_slot = value.value;
        hasClass.level = input.label;
      } else {
        _classes.push(_newClass);
      }
    } else {
      if (oldClasses.length > 0) {
        oldClasses[0].time_slot = value.value;
      }
    }

    return _classes;
  }

  const updateCheckboxClass = (oldClasses: IDogClassElement[], hasClass: IDogClassElement, input: IEventRegisterInputElement, value: IOption, extra?: string) => {
    let _classes:IDogClassElement[] = oldClasses;

    let _newClass:IDogClassElement = {
      id: input.id,
      show_element: input.label,
      level: value.value,
      section: 'A'
    };

    let obedienceJumpHeightLevels:string[] = [
      Constants.dog_class_element_level.graduate_novice,
      Constants.dog_class_element_level.open_a,
      Constants.dog_class_element_level.open_b,
      Constants.dog_class_element_level.preferred_open,
      Constants.dog_class_element_level.graduate_open,
      Constants.dog_class_element_level.utility_a,
      Constants.dog_class_element_level.utility_b,
      Constants.dog_class_element_level.preferred_utility
    ];

    let rallyJumpHeightLevels:string[] = [
      Constants.dog_class_element_level.advanced_a,
      Constants.dog_class_element_level.advanced_b,
      Constants.dog_class_element_level.excellent_a,
      Constants.dog_class_element_level.excellent_b,
      Constants.dog_class_element_level.master
    ];

    let UKCRallyJumpHeightTypes:string[] = [
      Constants.dog_class_element_level.ro3_a,
      Constants.dog_class_element_level.ro3_b,
      Constants.dog_class_element_level.rom_a,
      Constants.dog_class_element_level.rom_b
    ];
    
    if (props.event && props.event.sanctioning_club === Constants.sanctioning_club.AKC) {
      if (props.show.show_type === Constants.show_type.obedience) {
        if (obedienceJumpHeightLevels.includes(value.value) && (props.dog.obedience_jump_height === undefined || props.dog.obedience_jump_height <= 0)) {
          throwConditionError(props.dog.call_name+ " is missing an obedience jump height. You can add an obedience jump height by editing your dogs information in your account settings.");
          return oldClasses;
        }
      } else if (props.show.show_type === Constants.show_type.rally_obedience) {
        if (rallyJumpHeightLevels.includes(value.value) && (props.dog.rally_jump_height === undefined || props.dog.rally_jump_height <= 0)) {
          throwConditionError(props.dog.call_name+ " is missing a rally obedience jump height. You can add a rally obedience jump height by editing your dogs information in your account settings.");
          return oldClasses;
        }
      }
    }

    if (props.event && props.event.sanctioning_club === Constants.sanctioning_club.UKC) {
      if (props.show.show_type === Constants.show_type.rally_obedience) {
        if (UKCRallyJumpHeightTypes.includes(value.value) && !props.dog.jump_height_type) {
          throwConditionError(props.dog.call_name+ " is missing a rally obedience jump height type. You can add a rally obedience jump height type by editing your dogs information in your account settings.");
          return oldClasses;
        }
      }
    }

    if (props.event?.sanctioning_club === Constants.sanctioning_club.ABI) {
      if (props.show.show_type === Constants.show_type.conformation) {
        if (value.id === Constants.dog_class_element_level.abi_brace || value.id === Constants.dog_class_element_level.abi_baby_puppy) {
          let hasAltered = oldClasses.filter((d: IDogClassElement) => { return d.show_element === Constants.dog_class_element.conformation_altered && d.level === Constants.dog_class_element_level.abi_altered; });

          if (hasAltered.length > 0) {
            throwConditionError("Unable to register for " + value.id + " and " + Constants.dog_class_element.conformation_altered + " in the same registration");
            return oldClasses;
          }
        }
      }
    }

    if (hasClass) {
      // if (hasClass.level === Constants.dog_class_element_level.dock_jumping_air && value.id === "multiplier") {
      //   hasClass.multiplier = Number(value.value);
      // } else
      if ((hasClass.level === Constants.dog_class_element_level.brace || hasClass.level === Constants.dog_class_element_level.shed_dog_youth || hasClass.level === Constants.dog_class_element_level.pairs) && value.id === "extra") {
        hasClass.extra = value.value;
      } else if (hasClass.level === Constants.dog_class_element_level.team && value.id.includes("extra_arr")) {
        let _index = value.id.split('_');

        if (hasClass.extra_arr === undefined) {
          hasClass.extra_arr = ["", "", ""];
        }

        if (hasClass.extra_arr) {
          hasClass.extra_arr[parseInt(_index[2])] = value.value;
        }
      } else if (input.multiSelect) {
        let _hasMultiSelectClass = _classes.filter((c: IDogClassElement) => c.show_element === input.label && c.level === value.id);

        if (_hasMultiSelectClass.length > 0) {
          _classes.splice(_classes.indexOf(_hasMultiSelectClass[0]), 1);
        } else {
          _classes.push(_newClass);
        }
      } else if (!input.multiSelect) {
        if (props.edit) {
          if (_classes.indexOf(hasClass) !== -1) {
            _classes.splice(_classes.indexOf(hasClass), 1);
          } else {
            _classes.push(_newClass);
          }
        } else {
          _classes.splice(_classes.indexOf(hasClass), 1);
          _classes.push(_newClass);
        }

      }
    } else {
      _classes.push(_newClass);
    }

    if (props.show.show_type === Constants.show_type.scentwork) {
      _classes = checkScentworkConditions(input.label, value.value, _classes);
    }

    switch (input.label) {
      case Constants.dog_class_element.performance_rally_obedience:
        _classes = checkRallyConditions(value.value, _classes);
      break;
      case Constants.dog_class_element.performance_obedience:
        _classes = checkObedienceConditions(value.value, _classes);
      break;
      case Constants.dog_class_element.conformation_non_licensed:
        _classes = checkConformationConditions(value.value, _classes);
      break;
    }

    return _classes;
  }

  const updateDropdownClass = (oldClasses: IDogClassElement[], hasClass: IDogClassElement, input: IEventRegisterInputElement, value: IOption) => {
    let _classes:IDogClassElement[] = oldClasses;

    if (props.event?.sanctioning_club === Constants.sanctioning_club.ABI) {
      if (props.show.show_type === Constants.show_type.conformation) {
        if (value.id === Constants.dog_class_element_level.abi_altered) {
          let hasBraceOrBabyPuppy = oldClasses.filter((d: IDogClassElement) => { return d.show_element === Constants.dog_class_element.conformation_non_licensed && (d.level === Constants.dog_class_element_level.abi_baby_puppy || d.level === Constants.dog_class_element_level.abi_brace); });

          if (hasBraceOrBabyPuppy.length > 0) {
            throwConditionError("Unable to register for " + value.id + " and " + Constants.dog_class_element.conformation_altered + " in the same registration, "+ hasBraceOrBabyPuppy[0].level + " has been removed");
            _classes.splice(_classes.indexOf(hasBraceOrBabyPuppy[0]), 1);
          }
        }
      }
    }

    let _newClass:IDogClassElement = {
      id: input.id,
      show_element: input.label,
      level: value.value,
      section: 'None'
    };

    let noseworkLabels:string[] = [
      Constants.dog_class_element.nosework_container,
      Constants.dog_class_element.nosework_exterior,
      Constants.dog_class_element.nosework_interior,
      Constants.dog_class_element.nosework_vehicle,
      Constants.dog_class_element.nosework_handler_descrimination
    ];

    let conformationLabels:string[] = [
      Constants.dog_class_element.conformation,
      Constants.dog_class_element.conformation_altered
    ]

    if (hasClass) {
      if (value.value === "None" && input.label !== "Section") {
        _classes.splice(_classes.indexOf(hasClass), 1);
      } else {
        let _class = _classes.filter((c: IDogClassElement) => c.id === input.id)[0];

        if (input.label !== "Section") {
          _class.level = value.value;
        } else {
          _class.section = value.value;
        }

        if (conformationLabels.includes(input.label)) {
          _classes = checkConformationConditions(input.label, _classes);
        }

        if (noseworkLabels.includes(input.label)) {
          _classes = checkNoseworkConditions(input.label, _classes);
        }
      }
    } else {
      if (input.label !== "Section" && value.value !== "None") {
        if (conformationLabels.includes(input.label)) {
          _classes = checkConformationConditions(input.label, _classes);
        }

        if (noseworkLabels.includes(input.label)) {
          _classes = checkNoseworkConditions(input.label, _classes);
        }

        _classes.push(_newClass);
      }
    }

    return _classes;
  }

  const checkScentworkConditions = (label: string, value:string, _classes: IDogClassElement[]) => {
    switch(value) {
      //Novice
      case Constants.dog_class_element_level.novice_a:
        let hasNoviceB = _classes.filter((c: IDogClassElement) => label === c.show_element && c.level === Constants.dog_class_element_level.novice_b);

        if (hasNoviceB.length > 0) {
          _classes.splice(_classes.indexOf(hasNoviceB[0]), 1);
          throwConditionError("You can't register for "+ Constants.dog_class_element_level.novice_a +" and "+ Constants.dog_class_element_level.novice_b +". "+ Constants.dog_class_element_level.novice_b +" option has been reset.");
        }
      break;
      case Constants.dog_class_element_level.novice_b:
        let hasNoviceA = _classes.filter((c: IDogClassElement) => label === c.show_element && c.level === Constants.dog_class_element_level.novice_a);

        if (hasNoviceA.length > 0) {
          _classes.splice(_classes.indexOf(hasNoviceA[0]), 1);
          throwConditionError("You can't register for "+ Constants.dog_class_element_level.novice_b +" and "+ Constants.dog_class_element_level.novice_a +". "+ Constants.dog_class_element_level.novice_a +" option has been reset.");
        }
      break;
    }

    let hasMoreThanTwo = _classes.filter((c: IDogClassElement) => label === c.show_element);

    if (hasMoreThanTwo.length > 2) {
      throwConditionError("You can only enter two levels per Scentwork element. " + hasMoreThanTwo[0].level + " has been removed for "+ hasMoreThanTwo[0].show_element +".");
      _classes.splice(_classes.indexOf(hasMoreThanTwo[0]), 1);
      
    }

    return _classes;
  }

  const checkNoseworkConditions = (label: string, _classes: IDogClassElement[]) => {
    let levels:string[] = [
      Constants.dog_class_element_level.novice,
      Constants.dog_class_element_level.advanced,
      Constants.dog_class_element_level.superior,
      Constants.dog_class_element_level.master,
      Constants.dog_class_element_level.elite
    ];

    let highest:IDogClassElement|undefined = undefined;

    let classElements:IDogClassElement[] = _classes.filter((c) => c.show_element === label);

    for (let c in _classes) {
      if (_classes[c].show_element === label) {
        if (highest === undefined || levels.indexOf(_classes[c].level) > levels.indexOf(highest.level)) {
          highest = _classes[c];
        }
      }
    }

    for (let c in _classes) {
      if (_classes[c].show_element === label) {
        if (_classes[c] !== highest) {
          _classes[c].section = Constants.dog_class_section.nosework_b;
        } else {
          if (classElements.length > 1) {
            _classes[c].section = Constants.dog_class_section.nosework_a;
          }
        }
      }
    }

    return _classes;
  }

  const checkConformationConditions = (label: string, _classes: IDogClassElement[]) => {
    switch (label) {
      case Constants.dog_class_element.conformation: {
        let hasAlteredConf = _classes.filter((c: IDogClassElement) => c.show_element === Constants.dog_class_element.conformation_altered);
        let hasNovicePuppy = _classes.filter((c: IDogClassElement) => c.level === Constants.dog_class_element.novice_puppy);

        if (hasAlteredConf.length > 0) {
          _classes.splice(_classes.indexOf(hasAlteredConf[0]), 1);
          throwConditionError("You can't register for "+ Constants.dog_class_element.conformation +" and "+ Constants.dog_class_element.conformation_altered +". "+ Constants.dog_class_element.conformation_altered +" option has been reset.");
        } else if (hasNovicePuppy.length > 0) {
          _classes.splice(_classes.indexOf(hasNovicePuppy[0]), 1);
          throwConditionError("You can't register for "+ Constants.dog_class_element.conformation +" and "+ Constants.dog_class_element.novice_puppy +". "+ Constants.dog_class_element.novice_puppy +" option has been reset.");
        }

        break;
      }
      case Constants.dog_class_element.conformation_altered: {
        let hasConf =  _classes.filter((c: IDogClassElement) => c.show_element === Constants.dog_class_element.conformation);
        let hasNovicePuppy = _classes.filter((c: IDogClassElement) => c.level === Constants.dog_class_element.novice_puppy);

        if (hasConf.length > 0) {
          _classes.splice(_classes.indexOf(hasConf[0]), 1);
          throwConditionError("You can't register for "+ Constants.dog_class_element.conformation_altered +" and "+ Constants.dog_class_element.conformation +". "+ Constants.dog_class_element.conformation +" option has been reset.");
        } else if (hasNovicePuppy.length > 0) {
          _classes.splice(_classes.indexOf(hasNovicePuppy[0]), 1);
          throwConditionError("You can't register for "+ Constants.dog_class_element.conformation_altered +" and "+ Constants.dog_class_element.novice_puppy +". "+ Constants.dog_class_element.novice_puppy +" option has been reset.");
        }

        break;
      }
      case Constants.dog_class_element.novice_puppy: {
        let hasVeteran = _classes.filter((c: IDogClassElement) => c.level === Constants.dog_class_element.veteran);

        if (hasVeteran.length > 0) {
          _classes.splice(_classes.indexOf(hasVeteran[0]), 1);
          throwConditionError("You can't register for "+ Constants.dog_class_element.novice_puppy +" and "+ Constants.dog_class_element.veteran +". "+ Constants.dog_class_element.veteran +" option has been reset.");            
        }

        let hasConf =  _classes.filter((c: IDogClassElement) => c.show_element === "Conformation");

        if (hasConf.length > 0) {
          _classes.splice(_classes.indexOf(hasConf[0]), 1);
          throwConditionError("You can't register for "+ Constants.dog_class_element.novice_puppy +" and "+ Constants.dog_class_element.conformation +". "+ Constants.dog_class_element.conformation +" option has been reset."); 
        }

        let hasAlteredConf = _classes.filter((c: IDogClassElement) => c.show_element === "Altered Conformation");

        if (hasAlteredConf.length > 0) {
          _classes.splice(_classes.indexOf(hasAlteredConf[0]), 1);
          throwConditionError("You can't register for "+ Constants.dog_class_element.novice_puppy +" and "+ Constants.dog_class_element.conformation_altered +". "+ Constants.dog_class_element.conformation_altered +" option has been reset."); 
        }

        break;
      }
      case Constants.dog_class_element.veteran: {
        let hasNovicePuppy = _classes.filter((c: IDogClassElement) => c.level === Constants.dog_class_element.novice_puppy);

        if (hasNovicePuppy.length > 0) {
          _classes.splice(_classes.indexOf(hasNovicePuppy[0]), 1);
          throwConditionError("You can't register for "+ Constants.dog_class_element.veteran +" and "+ Constants.dog_class_element.novice_puppy +". "+ Constants.dog_class_element.novice_puppy +" option has been reset.");            
        }

        break;
      }
    }

    return _classes;
  }

  const checkRallyConditions = (value: string, _classes: IDogClassElement[]) => {
    switch (value) {
      //RO1
      case Constants.dog_class_element_level.ro1_a:
        let hasRO1B = _classes.filter((c: IDogClassElement) => c.level === Constants.dog_class_element_level.ro1_b);

        if (hasRO1B.length > 0) {
          _classes.splice(_classes.indexOf(hasRO1B[0]), 1);
          throwConditionError("You can't register for "+ Constants.dog_class_element_level.ro1_a +" and "+ Constants.dog_class_element_level.ro1_b +". "+ Constants.dog_class_element_level.ro1_b +" option has been reset.");
        }
      break;
      case Constants.dog_class_element_level.ro1_b:
        let hasRO1A = _classes.filter((c: IDogClassElement) => c.level === Constants.dog_class_element_level.ro1_a);

        if (hasRO1A.length > 0) {
          _classes.splice(_classes.indexOf(hasRO1A[0]), 1);
          throwConditionError("You can't register for "+ Constants.dog_class_element_level.ro1_b +" and "+ Constants.dog_class_element_level.ro1_a +". "+ Constants.dog_class_element_level.ro1_a +" option has been reset.");
        }
      break;

      //RO2
      case Constants.dog_class_element_level.ro2_a:
        let hasRO2B = _classes.filter((c: IDogClassElement) => c.level === Constants.dog_class_element_level.ro2_b);

        if (hasRO2B.length > 0) {
          _classes.splice(_classes.indexOf(hasRO2B[0]), 1);
          throwConditionError("You can't register for "+ Constants.dog_class_element_level.ro2_a +" and "+ Constants.dog_class_element_level.ro2_b +". "+ Constants.dog_class_element_level.ro2_b +" option has been reset.");
        }
      break;
      case Constants.dog_class_element_level.ro2_b:
        let hasRO2A = _classes.filter((c: IDogClassElement) => c.level === Constants.dog_class_element_level.ro2_a);

        if (hasRO2A.length > 0) {
          _classes.splice(_classes.indexOf(hasRO2A[0]), 1);
          throwConditionError("You can't register for "+ Constants.dog_class_element_level.ro2_b +" and "+ Constants.dog_class_element_level.ro2_a +". "+ Constants.dog_class_element_level.ro2_a +" option has been reset.");
        }
      break;

      //RO3
      case Constants.dog_class_element_level.ro3_a:
        let hasRO3B = _classes.filter((c: IDogClassElement) => c.level === Constants.dog_class_element_level.ro3_b);

        if (hasRO3B.length > 0) {
          _classes.splice(_classes.indexOf(hasRO3B[0]), 1);
          throwConditionError("You can't register for "+ Constants.dog_class_element_level.ro3_a +" and "+ Constants.dog_class_element_level.ro3_b +". "+ Constants.dog_class_element_level.ro3_b +" option has been reset.");
        }
      break;
      case Constants.dog_class_element_level.ro3_b:
        let hasRO3A = _classes.filter((c: IDogClassElement) => c.level === Constants.dog_class_element_level.ro3_a);

        if (hasRO3A.length > 0) {
          _classes.splice(_classes.indexOf(hasRO3A[0]), 1);
          throwConditionError("You can't register for "+ Constants.dog_class_element_level.ro3_b +" and "+ Constants.dog_class_element_level.ro3_a +". "+ Constants.dog_class_element_level.ro3_a +" option has been reset.");
        }
      break;

      //ROM
      case Constants.dog_class_element_level.rom_a:
        let hasROMB = _classes.filter((c: IDogClassElement) => c.level === Constants.dog_class_element_level.rom_b);

        if (hasROMB.length > 0) {
          _classes.splice(_classes.indexOf(hasROMB[0]), 1);
          throwConditionError("You can't register for "+ Constants.dog_class_element_level.rom_a +" and "+ Constants.dog_class_element_level.rom_b +". "+ Constants.dog_class_element_level.rom_b +" option has been reset.");
        }
      break;
      case Constants.dog_class_element_level.rom_b:
        let hasROMA = _classes.filter((c: IDogClassElement) => c.level === Constants.dog_class_element_level.rom_a);

        if (hasROMA.length > 0) {
          _classes.splice(_classes.indexOf(hasROMA[0]), 1);
          throwConditionError("You can't register for "+ Constants.dog_class_element_level.rom_b +" and "+ Constants.dog_class_element_level.rom_a +". "+ Constants.dog_class_element_level.rom_a +" option has been reset.");
        }
      break;
    }

    return _classes;
  }

  const checkObedienceConditions = (value: string, _classes: IDogClassElement[]) => {
    switch(value) {
      //Novice
      case Constants.dog_class_element_level.novice_a:
        let hasNoviceB = _classes.filter((c: IDogClassElement) => c.level === Constants.dog_class_element_level.novice_b);

        if (hasNoviceB.length > 0) {
          _classes.splice(_classes.indexOf(hasNoviceB[0]), 1);
          throwConditionError("You can't register for "+ Constants.dog_class_element_level.novice_a +" and "+ Constants.dog_class_element_level.novice_b +". "+ Constants.dog_class_element_level.novice_b +" option has been reset.");
        }
      break;
      case Constants.dog_class_element_level.novice_b:
        let hasNoviceA = _classes.filter((c: IDogClassElement) => c.level === Constants.dog_class_element_level.novice_a);

        if (hasNoviceA.length > 0) {
          _classes.splice(_classes.indexOf(hasNoviceA[0]), 1);
          throwConditionError("You can't register for "+ Constants.dog_class_element_level.novice_b +" and "+ Constants.dog_class_element_level.novice_a +". "+ Constants.dog_class_element_level.novice_a +" option has been reset.");
        }
      break;

      //Open
      case Constants.dog_class_element_level.open_a:
        let hasOpenB = _classes.filter((c: IDogClassElement) => c.level === Constants.dog_class_element_level.open_b);

        if (hasOpenB.length > 0) {
          _classes.splice(_classes.indexOf(hasOpenB[0]), 1);
          throwConditionError("You can't register for "+ Constants.dog_class_element_level.open_a +" and "+ Constants.dog_class_element_level.open_b +". "+ Constants.dog_class_element_level.open_b +" option has been reset.");
        }
      break;
      case Constants.dog_class_element_level.open_b:
        let hasOpenA = _classes.filter((c: IDogClassElement) => c.level === Constants.dog_class_element_level.open_a);

        if (hasOpenA.length > 0) {
          _classes.splice(_classes.indexOf(hasOpenA[0]), 1);
          throwConditionError("You can't register for "+ Constants.dog_class_element_level.open_b +" and "+ Constants.dog_class_element_level.open_a +". "+ Constants.dog_class_element_level.open_a +" option has been reset.");
        }
      break;

      //Utility
      case Constants.dog_class_element_level.utility_a:
        let hasUtilityB = _classes.filter((c: IDogClassElement) => c.level === Constants.dog_class_element_level.utility_b);

        if (hasUtilityB.length > 0) {
          _classes.splice(_classes.indexOf(hasUtilityB[0]), 1);
          throwConditionError("You can't register for "+ Constants.dog_class_element_level.utility_a +" and "+ Constants.dog_class_element_level.utility_b +". "+ Constants.dog_class_element_level.utility_b +" option has been reset.");
        }
      break;
      case Constants.dog_class_element_level.utility_b:
        let hasUtilityA = _classes.filter((c: IDogClassElement) => c.level === Constants.dog_class_element_level.utility_a);

        if (hasUtilityA.length > 0) {
          _classes.splice(_classes.indexOf(hasUtilityA[0]), 1);
          throwConditionError("You can't register for "+ Constants.dog_class_element_level.utility_b +" and "+ Constants.dog_class_element_level.utility_a +". "+ Constants.dog_class_element_level.utility_a +" option has been reset.");
        }
      break;
    }

    return _classes;
  }

  const throwConditionError = (msg: string) => {
    toast.show({
      title: "Show Registration",
      content: msg,
      duration: 10000,
      type: "fail"
    });
  }

  const getValue = (input: IEventRegisterInputElement) => {
    let _class = classes.filter((c) => c.id === input.id)[0];
    let value:IOption = { value: "None", id: "None" };

    if (_class) {
      if (input.label === "Section" && _class.section) {
        value = { value: _class.section, id: _class.section };
      } else {
        value = { value: _class.level, id: _class.level };
      }
    } else {
      if (input.defaultValue) {
        value = { value: input.defaultValue, id: input.defaultValue };
      }
    }

    return value;
  }

  const duplicateRow = (rIndex: number) => {
    if (form) {
      let _form:IEventRegisterRowElement[] = JSON.parse(JSON.stringify(form));

      let _row:IEventRegisterRowElement = JSON.parse(JSON.stringify(form[rIndex]));
      _row.allowDuplicates = false;
      _row.duplicate = true;

      let id:string = createId();

      for (let i in _row.inputs) {
        _row.inputs[i].id = id;
      }

      _form.splice(rIndex + 1, 0, JSON.parse(JSON.stringify(_row)));

      setForm(_form);
    }
  }

  const removeRow = (rIndex: number) => {
    if (form) {
      let _form:IEventRegisterRowElement[] = JSON.parse(JSON.stringify(form));
      let _classes = JSON.parse(JSON.stringify(classes));

      for (let i in _form[rIndex].inputs) {
        let _class = _classes.filter((c: IDogClassElement) => c.id === _form[rIndex].inputs[i].id)[0];

        if (_class) {
          _classes.splice(_classes.indexOf(_class), 1);
        }
      }

      setClasses(_classes);

      _form.splice(rIndex, 1);
      setForm(_form);
    }
  }

  const getJudge = (showElement: string) => {
    return props.show.show_elements.filter((e:IShowElement) => e.show_element === showElement)[0]?.judge_name || undefined;
  }

  const getLabel = (input: IEventRegisterInputElement) => {
    let label = getJudge(input.label) !== undefined && input.label !== "Section" ? input.label + ", judged by " + getJudge(input.label) : input.label;

    if (props.show.show_elements.filter((e: IShowElement) => { return e.show_element === input.label; }).length === 0 && input.label !== "Section") {
      label = input.label + " (Unavailable)";
    }

    return label;
  }

  const hasExtraElements = () => {
    let total:number = 0;

    props.classes.forEach(c => {
      if (c.show_element !== "None") {
        total++;

      }
    });

    if (elementCount && total < elementCount) {
      return true;
    }

    return false;
  }

  const isDropdownDisabled = (input: IEventRegisterInputElement) => {
    if (props.overrideDisable) {
      return false;
    }

    let hasClass = classes.filter((c => c.show_element === input.label)).length > 0;

    if ((props.edit || props.moveUp) && !hasClass && input.label === "Section") {
      let hasId = classes.filter((c => c.id === input.id)).length > 0;

      if (hasId) {
        return false;
      } else {
        if (props.edit && hasExtraElements() && props.show.show_elements.filter((e: IShowElement) => { return e.show_element === input.label; }).length > 0) {
          return false;
        } else {
          return true;
        }
        
      }
    } else if ((props.edit || props.moveUp) && !hasClass) {
      if (props.edit && hasExtraElements() && props.show.show_elements.filter((e: IShowElement) => { return e.show_element === input.label; }).length > 0) {
        return false;
      } else {
        let hasConfOrAlt = classes.filter((c => c.show_element === Constants.dog_class_element.conformation || c.show_element === Constants.dog_class_element.conformation_altered)).length > 0;

        if (hasConfOrAlt && (input.label === Constants.dog_class_element.conformation || input.label === Constants.dog_class_element.conformation_altered)) {
          return false;
        }

        return true;
      }
    } else if (input.label === "Section" && classes.filter((c: IDogClassElement) => c.id === input.id).length > 0) {
      return false;
    } else if (props.show.show_elements.filter((e: IShowElement) => { return e.show_element === input.label; }).length === 0) {
      return true;
    }

    return false;
  }

  const isCheckboxDisabled = (option: string) => {
    if (props.overrideDisable) {
      return false;
    }

    if (props.show.show_type === Constants.show_type.agility || 
      props.show.show_type === Constants.show_type.weight_pull || 
      props.show.show_type === Constants.show_type.fetch || 
      props.show.show_type === Constants.show_type.scentwork ||
      props.show.show_type === Constants.show_type.dock_jumping) {
      return false;
    }

    let checkboxAgeRestriction = yearList.filter((item: any) => { return item.level === option; });

    if (checkboxAgeRestriction.length > 0) {
      let dobInMonths = moment().diff(props.dog.dob, 'months');

      if (props.event) {
        dobInMonths = moment(props.event.start_date).diff(props.dog.dob, 'months');
      }

      if ((checkboxAgeRestriction[0].min !== -1 && dobInMonths < checkboxAgeRestriction[0].min) ||
          (checkboxAgeRestriction[0].max !== -1 && dobInMonths > checkboxAgeRestriction[0].max)) {
        return true;
      }
    }

    // If formbuilder is in edit mode, and the level is free, don't disable it
    if (props.edit && props.show.show_elements.filter((e:IShowElement) => (option === e.level && e.fee === 0)).length > 0) {
      return false;
    // If formbuilder is in edit mode, and the option isn't already selected, disable it
    } else if (props.edit && props.classes.filter((c) => { return option === c.level; }).length === 0) {
      // Unless they've unselected an already selected option (they have "element credit")
      if (props.edit && elementCount && props.classes.length < elementCount) {
        // Then check if the level is available at the show, if not, disable it
        if (props.show.show_elements.filter((e:IShowElement) => (e.show_element === option || e.level === option)).length === 0) {
          return true;
        } else {
          // If the element is available at the show, then at this point, don't disable it
          return false;
        }
      } else {
        return true;
      }
    // If the formbuilder is NOT in edit mode and the show element is available at the show
    } else if (props.show.show_elements.filter((e:IShowElement) => (e.show_element === option || e.level === option)).length === 0) {
      return true;
    }

    return false;
  }

  const ageRestrictedElements:string[] = [
    Constants.dog_class_element.conformation,
    Constants.dog_class_element.conformation_altered
  ];

  const ageRestrictOptions = (options: IOption[]) => {
    let dobInMonths = moment().diff(props.dog.dob, 'months');

    if (props.event) {
      dobInMonths = moment(props.show.show_date).diff(props.dog.dob, 'months');
    }

    let filteredOptions:IOption[] = JSON.parse(JSON.stringify(options));

    for (let i in filteredOptions) {
      for (let y in yearList) {
        if (filteredOptions[i].value === yearList[y].level) {
          filteredOptions[i].disabled = true;

          if (
            (dobInMonths >= yearList[y].min || yearList[y].min === -1) && 
            (dobInMonths < yearList[y].max || yearList[y].max === -1)
          ) {
            filteredOptions[i].disabled = false;
          }

          break;
        }
      }
    }

    return filteredOptions;
  }

  const dupCheckOptions = (input: any) => {
     let filteredOptions:IOption[] = JSON.parse(JSON.stringify(input.options));

     for (let i in filteredOptions) {
       for (let c in props.classes) {
         if (props.classes[c].show_element === input.label && props.classes[c].level === filteredOptions[i].value && filteredOptions[i].value !== getValue(input).value) {
           filteredOptions[i].disabled = true;
           break;
         } else {
           filteredOptions[i].disabled = false;
         }
       }
     }

     // Oxford Start
     if ((props.event && (props.event.handle === "u35ag9" || props.event.handle === "k0nya5")) && input.type === "dropdown" && input.label === Constants.dog_class_element.nosework_handler_descrimination) {
      for (let i in filteredOptions) {
        if (filteredOptions[i].id === Constants.dog_class_element_level.master) {
          filteredOptions[i].disabled = true;
        }
      }
     }
     // Oxford End

     return filteredOptions;
  }

  const isElementAndAvailable = (input: any) => {
    if (props.show.show_type === Constants.show_type.scentwork)  {
      if (props.show.show_elements.filter((se: any) => se.show_element === input.label).length === 0) {
        return false;
      }
    }

    return true;
  }

  const isTimeSlotDisabled = (t: IShowRunTimeSlot) => {
    return (t.num_available - t.num_reserved <= 0) || ((props.show.show_type !== Constants.show_type.fast_cat) && props.classes.length === 0);
  }

  const getTimeSlotClass = (t: IShowRunTimeSlot, tIndex: number) => {
    let _class:string = "no-select ";

    if (isTimeSlotDisabled(t)) {
      _class += "disabled";
    } else if (selectedTimeslot === tIndex) {
      _class += "active";
    }

    //selectedTimeslot === tIndex ? "no-select active" : t.num_available - t.num_reserved <= 0 ? "disabled no-select" : "no-select"

    return _class;
  }

  return(
    <div className="FormBuilder">
      <div>
        <div className="form">
          {form !== undefined && form.map((row: IEventRegisterRowElement, rIndex: number) => {
            return(
              <div className={row.inputs.length <= 1 ? row.duplicate ? "row single duplicate" : "row single" : row.duplicate ? "row duplicate" : "row"} key={rIndex}>
                <div className="rowInputs">
                {row.inputs.map((input: IEventRegisterInputElement, iIndex: number) => {
                  return(
                    <div key={iIndex} style={{ flex: input.flex ? input.flex : 1 }} className="inputWrap">
                      <div className="input">
                        {input.type === "dropdown" && <Dropdown
                            id={input.id}
                            placeholder=""
                            value={getValue(input)}
                            onChange={(e: any, value: IOption) => { if (e.target.value.length > 0) { updateClasses(input, value); } }}
                            label={getLabel(input)}
                            alignRight={input.label === Constants.dog_class_element.conformation_altered ? true : false}
                            options={ageRestrictedElements.includes(input.label) ? ageRestrictOptions(input.options) : (row.allowDuplicates || row.duplicate) ? dupCheckOptions(input) : input.options} 
                            dropUp={input.dropUp ? input.dropUp : false}
                            disabled={isDropdownDisabled(input)}
                          />
                        }
                        {(input.type === "checkbox") && isElementAndAvailable(input) && !props.moveUp && <div className="checkboxWrap">
                          <div className="inputTitle">{input.label}</div>
                          <ul className="checkboxList">
                            {input.options.map((option:IOption, cIndex: number) => {
                              if (!isCheckboxDisabled(option.id)) {
                                return(<li key={input.id+"-"+cIndex}><Checkbox onChange={(e:any) => { updateClasses(input, option); }} value={classes.filter((c: IDogClassElement) => (input.label === c.show_element && c.level === option.id)).length > 0} id={input.id+"-"+cIndex} label={option.value} /></li>);
                              } else {
                                return(<li key={input.id+"-"+cIndex}><Checkbox disabled={true} onChange={(e:any) => { /* Do Nothing */ }} value={classes.filter((c: IDogClassElement) => (input.label === c.show_element && c.level === option.id)).length > 0} id={input.id+"-"+cIndex} label={option.value} /></li>);
                              }
                            })}
                          </ul>
                          {/* {classes.filter((c: IDogClassElement) => c.level === Constants.dog_class_element_level.dock_jumping_air).length > 0 &&
                            <div className="otherWrap">
                              <Input required label={"Number of Splashes"} id={input.id+"-multiplier"} type="number" onChange={(e:any) => { e.stopPropagation(); updateClasses(input, { id: "multiplier", value: e.target.value }, undefined, undefined, e.target.value); }} defaultValue={classes.filter((c: IDogClassElement) => c.level === Constants.dog_class_element_level.dock_jumping_air)[0].multiplier?.toString() || "0"} />
                            </div>
                          } */}
                          {classes.filter((c: IDogClassElement) => c.level === "Other" && (input.label === c.show_element || input.label === c.level)).length > 0 &&
                            <div className="otherWrap">
                              <Input required label="Other" id={input.id+"-other"} type="text" onChange={(e:any) => { e.stopPropagation(); updateClasses(input, { id: "Other", value: e.target.value }, e.target.value); }} />
                            </div>
                          }
                          {classes.filter((c: IDogClassElement) => (c.level === Constants.dog_class_element_level.brace || c.level === Constants.dog_class_element_level.pairs) && (input.label === c.show_element || input.label === c.level)).length > 0 &&
                            <div className="otherWrap">
                              <Input required label={props.event?.sanctioning_club === Constants.sanctioning_club.UKC ? "Brace/Pairs: Second Dog UKC Registration #" : "Brace/Pairs: Second Dog Registration #"} id={input.id+"-extra"} type="text" onChange={(e:any) => { e.stopPropagation(); updateClasses(input, { id: "extra", value: e.target.value }, e.target.value); }} defaultValue={classes.filter((c: IDogClassElement) => c.level === Constants.dog_class_element_level.brace || c.level === Constants.dog_class_element_level.pairs)[0].extra} />
                            </div>
                          }
                          {classes.filter((c: IDogClassElement) => c.level === Constants.dog_class_element_level.shed_dog_youth && (input.label === c.show_element || input.label === c.level)).length > 0 &&
                            <div className="otherWrap">
                              <Input required label={"Youth Name"} id={input.id+"-extra"} type="text" onChange={(e:any) => { e.stopPropagation(); updateClasses(input, { id: "extra", value: e.target.value }, e.target.value); }} defaultValue={classes.filter((c: IDogClassElement) => c.level === Constants.dog_class_element_level.shed_dog_youth)[0].extra} />
                            </div>
                          }
                          {classes.filter((c: IDogClassElement) => c.level === Constants.dog_class_element_level.team && (input.label === c.show_element || input.label === c.level)).length > 0 && <>
                            <div className="otherWrap team">
                              <Input required label={"Team: Member 2 Registration #"} id={input.id+"-extra_arr_0"} type="text" onChange={(e:any) => { e.stopPropagation(); updateClasses(input, { id: "extra_arr_0", value: e.target.value }, undefined, e.target.value); }} defaultValue={(classes.filter((c: IDogClassElement) => c.level === (Constants.dog_class_element_level.team))[0].extra_arr || [])[0]} />
                              <Input required label={"Team: Member 3 Registration #"} id={input.id+"-extra_arr_1"} type="text" onChange={(e:any) => { e.stopPropagation(); updateClasses(input, { id: "extra_arr_1", value: e.target.value }, undefined, e.target.value); }} defaultValue={(classes.filter((c: IDogClassElement) => c.level === (Constants.dog_class_element_level.team))[0].extra_arr || [])[1]} />
                              <Input required label={"Team: Member 4 Registration #"} id={input.id+"-extra_arr_2"} type="text" onChange={(e:any) => { e.stopPropagation(); updateClasses(input, { id: "extra_arr_2", value: e.target.value }, undefined, e.target.value); }} defaultValue={(classes.filter((c: IDogClassElement) => c.level === (Constants.dog_class_element_level.team))[0].extra_arr || [])[2]} />
                            </div>
                          </>}
                        </div>}
                      </div>
                    </div>
                  );
                })}
                </div>
                {row.allowDuplicates && !props.edit && <div className="rowActions"><div className="duplicateBtn" onClick={(e) => { duplicateRow(rIndex); }}><span className="icon"></span></div></div>}
                {row.duplicate && !props.edit && <div className="rowActions"><div className="removeBtn" onClick={(e) => { removeRow(rIndex); }}><span className="icon"></span></div></div>}
              </div>
            );
          })}
          {helperService.timeSlotShows.includes(props.show.show_type) && <div className="timeSlot-wrap">
            {timeslots.map((t: IShowRunTimeSlot, tIndex: number) => {
              return(
                <TimeSlot
                  className={getTimeSlotClass(t, tIndex)}
                  timeslot={t}
                  key={tIndex}
                  onClick={(e) => {
                    /*if (t.status === "available") {*/
                    if (!isTimeSlotDisabled(t)) {
                      setSelectedTimeslot(tIndex);

                      updateClasses({
                        id: props.show.show_type,
                        type: "timeslot",
                        label: props.show.show_type,
                        options: []
                      }, { id: t._id, value: t.time_slot});
                    }
                    /*}*/
                  }}
                />
              );
            })}
          </div>}
        </div>
      </div>
    </div>
  );
}

export default FormBuilder;