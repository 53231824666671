import React, { useRef, useState, type ChangeEvent, type FormEvent } from "react";
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';

import { IEventMessage } from "@eagerdog/interfaces";

import { toast } from "src/components/Toast/ToastManager";
import Button from "src/components/Button/Button";
import Checkbox from "src/components/Checkbox/Checkbox";

import "./SendMessage.scss";

interface IProps {
  handleSendMessage: (formData: IEventMessage) => void
}

const SendMessage: React.FC<IProps> = (props) => {
  const editorRef = useRef<TinyMCEEditor | null>(null);
  const uploadInputRef = useRef<HTMLInputElement | null>(null);
  const [attachFinalConfirmation, setAttachFinalConfirmation] = useState<boolean>(false);
  const [attachedFiles, setAttachedFiles] = useState<File[]>([]);

  const handleAttachmentSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (!validateAttachmentsSize(file)) return;
      setAttachedFiles([file, ...attachedFiles]);
      // NOTE: allows to select the same file after it has been removed for example
      // we dont use inputs values anyway
      event.target.value = "";
    }
  }

  const handleAttachmentRemove = (file: File) => {
    setAttachedFiles(attachedFiles.filter((attachment) => {
      return attachment.name !== file.name && attachment.lastModified !== file.lastModified;
    }));
  }

  // NOTE: check on current attachments + new input file
  const validateAttachmentsSize = (file: File): boolean => {
    const maxSize = 1e+7; // 10 MB
    const summary: number = attachedFiles.map((attachment) => attachment.size)
      .reduce((a, b) => a + b, file.size);
    if (summary > maxSize) {
      toast.show({
        title: "Attachments limit",
        content: "Overall files size should not exceed 10 megabytes",
        duration: 10000,
        type: "fail"
      });
      return false;
    }
    return true;
  }

  const truncateFileName = (file: File) => {
    const name = file.name;
    const maxLength = 14;
    if (name.length > maxLength) return name.slice(0, maxLength - 1) + "…";
    return name;
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!editorRef.current) return;

    if (!editorRef.current.getContent()) {
      toast.show({
        title: "Event Message",
        content: "Please type in a message",
        duration: 10000,
        type: "fail"
      });
      return;
    }

    const payload: IEventMessage = {
      content: editorRef.current.getContent(),
      attach_registration_summary: attachFinalConfirmation,
      attachments: attachedFiles,
    };

    props.handleSendMessage(payload);
  }

  return (
    <form className='SendMessage' onSubmit={handleSubmit}>
      <div className="title">Send Message</div>
      <div className="formWrap">
        <Editor
          apiKey='e4kmr66e3tqyrdxqk84c9ucybc5l3qzaum53urpf5q3ccg54'
          onInit={(_evt, editor) => editorRef.current = editor}
          init={{
            plugins: 'emoticons link lists media searchreplace table visualblocks wordcount',
            toolbar: 'undo redo | fontfamily fontsize | bold italic underline strikethrough | link image mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
            tinycomments_mode: 'embedded',
            tinycomments_author: 'Author name',
            menubar: false,
            branding: false,
            mergetags_list: [
              { value: 'First.Name', title: 'First Name' },
              { value: 'Email', title: 'Email' },
            ],
          }}
          initialValue=""
        />
        <div className="inputsWrap">
          <div className="checkWrap">
            <Checkbox id={"attachFinalConfirmation"} onChange={() => {
              setAttachFinalConfirmation(!attachFinalConfirmation);
            }} value={attachFinalConfirmation} label={"Attach Registrants Final Confirmation to Email"}/>
          </div>
          <div className="actions">
            <Button type="button" disabled={attachedFiles.length >= 3} onClick={(event) => {
              event.stopPropagation();
              uploadInputRef?.current?.click();
            }}>
              Upload attachment
              <input
                ref={uploadInputRef}
                style={{ display: 'none' }}
                type='file'
                accept='image/*, application/pdf'
                onChange={handleAttachmentSelect}
              />
            </Button>
            <div className="attachedFiles">
              {attachedFiles.map((file, index) => (
                <Button key={index} type="button" className="attachment">
                  {truncateFileName(file)}
                  <span className="icon" onClick={() => handleAttachmentRemove(file)}></span>
                </Button>
              ))}
            </div>
            <Button type="submit" className="submit">Send Message</Button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default SendMessage;
