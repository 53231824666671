import { Constants } from "./constants";

export const Deductions: any = {
  [Constants.sanctioning_club.UKC]: {
    [Constants.show_type.obedience]: {
      [Constants.dog_class_element_level.pre_novice]: {
        I: [
          { exercise: Constants.deductions.heel_on_leash },
          { exercise: Constants.deductions.serpentine_loop_three_cones },
          { exercise: Constants.deductions.sit_for_exam },
          { exercise: Constants.deductions.down_stay },
          { exercise: Constants.deductions.straight_recall },
        ],
      },
      [Constants.dog_class_element_level.beginner_novice]: {
        I: [
          { exercise: Constants.deductions.heel_on_leash_figure_eight },
          { exercise: Constants.deductions.stand_sit_for_exam },
          { exercise: Constants.deductions.down_stay_walk_away },
          { exercise: Constants.deductions.recall_over_high_jump },
          { exercise: Constants.deductions.sit_stay },
        ],
      },
      [Constants.dog_class_element_level.novice_a]: {
        I: [
          { exercise: Constants.deductions.greeting },
          { exercise: Constants.deductions.honor },
          { exercise: Constants.deductions.heel_on_leash_figure_eight },
          { exercise: Constants.deductions.stand_for_exam },
          { exercise: Constants.deductions.heel_off_leash },
          { exercise: Constants.deductions.recall_over_high_jump },
        ],
      },
      [Constants.dog_class_element_level.novice_b]: {
        I: [
          { exercise: Constants.deductions.greeting },
          { exercise: Constants.deductions.honor },
          { exercise: Constants.deductions.heel_on_leash_figure_eight },
          { exercise: Constants.deductions.stand_for_exam },
          { exercise: Constants.deductions.heel_off_leash },
          { exercise: Constants.deductions.recall_over_high_jump },
        ],
      },
      [Constants.dog_class_element_level.novice_c]: {
        I: [
          { exercise: Constants.deductions.greeting },
          { exercise: Constants.deductions.honor },
          { exercise: Constants.deductions.heel_on_leash_figure_eight },
          { exercise: Constants.deductions.stand_for_exam },
          { exercise: Constants.deductions.heel_off_leash },
          { exercise: Constants.deductions.recall_over_high_jump },
        ],
      },
      [Constants.dog_class_element_level.advance_novice]: {
        I: [
          { exercise: Constants.deductions.greeting },
          { exercise: Constants.deductions.honor },
          { exercise: Constants.deductions.heel_off_leash },
          { exercise: Constants.deductions.moving_down },
          { exercise: Constants.deductions.recall_over_jump },
          { exercise: Constants.deductions.recall_over_broad_jump },
        ],
      },
      [Constants.dog_class_element_level.open_a]: {
        I: [
          { exercise: Constants.deductions.greeting },
          { exercise: Constants.deductions.heel_off_leash_figure_eight },
          { exercise: Constants.deductions.drop_on_recall },
          { exercise: Constants.deductions.retrieve_on_flat },
          { exercise: Constants.deductions.retrieve_over_high_jump },
          { exercise: Constants.deductions.broad_jump },
          { exercise: Constants.deductions.socialized_heeling },
        ],
      },
      [Constants.dog_class_element_level.open_b]: {
        I: [
          { exercise: Constants.deductions.greeting },
          { exercise: Constants.deductions.heel_off_leash_figure_eight },
          { exercise: Constants.deductions.drop_on_recall },
          { exercise: Constants.deductions.retrieve_on_flat },
          { exercise: Constants.deductions.retrieve_over_high_jump },
          { exercise: Constants.deductions.broad_jump },
          { exercise: Constants.deductions.socialized_heeling },
        ],
      },
      [Constants.dog_class_element_level.advance_open]: {
        I: [
          { exercise: Constants.deductions.heel_off_leash_with_moving_exercise },
          { exercise: Constants.deductions.figure_eight_with_down_stay },
          { exercise: Constants.deductions.retrieve_of_an_object },
          { exercise: Constants.deductions.scent_discrimination },
          { exercise: Constants.deductions.directed_retrieve },
          { exercise: Constants.deductions.send_away_to_box },
        ],
      },
      [Constants.dog_class_element_level.utility_a]: {
        I: [
          { exercise: Constants.deductions.signal_heeling },
          { exercise: Constants.deductions.scent_work },
          // Constants.deductions.option_1_turn_and_send,
          // Constants.deductions.option_2_sit,
          { exercise: Constants.deductions.directed_marked_retrieve },
          { exercise: Constants.deductions.directed_signal_retrieve },
          { exercise: Constants.deductions.consecutive_recall },
          { exercise: Constants.deductions.directed_jumping },
        ],
      },
      [Constants.dog_class_element_level.utility_b]: {
        I: [
          { exercise: Constants.deductions.signal_heeling },
          { exercise: Constants.deductions.scent_work },
          // Constants.deductions.option_1_turn_and_send,
          // Constants.deductions.option_2_sit,
          { exercise: Constants.deductions.directed_marked_retrieve },
          { exercise: Constants.deductions.directed_signal_retrieve },
          { exercise: Constants.deductions.consecutive_recall },
          { exercise: Constants.deductions.directed_jumping },
        ],
      },
      [Constants.dog_class_element_level.elite]: {
        I: [
          { exercise: Constants.deductions.signal_heeling_with_moving_stand_for_exam },
          { exercise: Constants.deductions.food_refusal },
          { exercise: Constants.deductions.recall_with_stand_and_down },
          { exercise: Constants.deductions.advanced_directed_retrieve_and_jumping },
          { exercise: Constants.deductions.unfamiliar_scent_discrimination },
        ],
      },
      [Constants.dog_class_element_level.master]: {
        I: [
          { exercise: Constants.deductions.seek_back },
          { exercise: Constants.deductions.positions_with_recall },
          { exercise: Constants.deductions.send_to_cone_and_return },
          { exercise: Constants.deductions.send_away_with_dumbbell_retrieve },
          { exercise: Constants.deductions.handler_discrimination },
        ],
      },
    },
    [Constants.show_type.rally_obedience]: {
      [Constants.dog_class_element_level.ro1_a]: {
        I: [
          { sign: 101, exercise: Constants.deductions.halt },
          { sign: 102, exercise: Constants.deductions.halt_stand },
          { sign: 103, exercise: Constants.deductions.halt_down },
          { sign: 104, exercise: Constants.deductions.halt_down_sit },
          { sign: 105, exercise: Constants.deductions.halt_walk_around_dog },
          { sign: 106, exercise: Constants.deductions.halt_down_walk_around_dog },
          { sign: 107, exercise: Constants.deductions.right_turn },
          { sign: 108, exercise: Constants.deductions.left_turn },
          { sign: 109, exercise: Constants.deductions.about_turn_right },
          { sign: 110, exercise: Constants.deductions.about_turn_right_left_turn },
          { sign: 111, exercise: Constants.deductions.about_u_turn },
          { sign: 112, exercise: Constants.deductions.about_u_turn_left_right_turn },
          { sign: 113, exercise: Constants.deductions.turn_270_right },
          { sign: 114, exercise: Constants.deductions.turn_270_left },
          { sign: 115, exercise: Constants.deductions.turn_360_right },
          { sign: 116, exercise: Constants.deductions.turn_360_left },
          { sign: 117, exercise: Constants.deductions.halt_90_pivot_right_halt },
          { sign: 118, exercise: Constants.deductions.halt_90_pivot_right_forward },
          { sign: 119, exercise: Constants.deductions.call_dog_front_handler_returns },
          { sign: 120, exercise: Constants.deductions.call_dog_front_dog_right_forward },
          { sign: 121, exercise: Constants.deductions.call_dog_front_dog_left_forward },
          { sign: 122, exercise: Constants.deductions.call_dog_front_finish_right_halt },
          { sign: 123, exercise: Constants.deductions.call_dog_front_finish_left_halt },
          { sign: 124, exercise: Constants.deductions.slow_pace },
          { sign: 125, exercise: Constants.deductions.fast_pace },
          { sign: 126, exercise: Constants.deductions.normal_pace },
          { sign: 127, exercise: Constants.deductions.moving_side_step_right },
          { sign: 128, exercise: Constants.deductions.halt_side_step_right_forward },
          { sign: 129, exercise: Constants.deductions.spiral_right_dog_outside },
          { sign: 130, exercise: Constants.deductions.spiral_left_dog_inside },
          { sign: "131a", exercise: Constants.deductions.serpentine_4_cones },
          { sign: "131b", exercise: Constants.deductions.serpentine_5_cones },
          { sign: "132a", exercise: Constants.deductions.serpentine_loop_4_cones },
          { sign: "132b", exercise: Constants.deductions.serpentine_loop_5_cones },
          { sign: 133, exercise: Constants.deductions.curl_dog_inside_3_cones },
          { sign: 134, exercise: Constants.deductions.curl_dog_outside_3_cones },
          { sign: 135, exercise: Constants.deductions.ribbon_loop_right },
          { sign: 136, exercise: Constants.deductions.ribbon_loop_left },
          { sign: 137, exercise: Constants.deductions.figure_8_to_right },
          { sign: 138, exercise: Constants.deductions.figure_8_to_left },
          { sign: 139, exercise: Constants.deductions.halt_1_2_3_steps_forward },
          { sign: 150, exercise: Constants.deductions.right_turn_150 },
          { sign: 151, exercise: Constants.deductions.right_turn_151 },
          { sign: 152, exercise: Constants.deductions.left_turn_152 },
          { sign: 153, exercise: Constants.deductions.left_turn_153 },
          { sign: 154, exercise: Constants.deductions.about_turn_right_154 },
          { sign: 155, exercise: Constants.deductions.about_turn_left_155 },
          { sign: 156, exercise: Constants.deductions.turn_270_right_156 },
          { sign: 157, exercise: Constants.deductions.turn_270_left_157 },
          { sign: "START", exercise: Constants.deductions.start },
          { sign: "FINISH", exercise: Constants.deductions.finish },
          { sign: "ENTER", exercise: Constants.deductions.enter },
          { sign: "EXIT", exercise: Constants.deductions.exit },
        ],
      },
      [Constants.dog_class_element_level.ro1_b]: {
        I: [
          { sign: 101, exercise: Constants.deductions.halt },
          { sign: 102, exercise: Constants.deductions.halt_stand },
          { sign: 103, exercise: Constants.deductions.halt_down },
          { sign: 104, exercise: Constants.deductions.halt_down_sit },
          { sign: 105, exercise: Constants.deductions.halt_walk_around_dog },
          { sign: 106, exercise: Constants.deductions.halt_down_walk_around_dog },
          { sign: 107, exercise: Constants.deductions.right_turn },
          { sign: 108, exercise: Constants.deductions.left_turn },
          { sign: 109, exercise: Constants.deductions.about_turn_right },
          { sign: 110, exercise: Constants.deductions.about_turn_right_left_turn },
          { sign: 111, exercise: Constants.deductions.about_u_turn },
          { sign: 112, exercise: Constants.deductions.about_u_turn_left_right_turn },
          { sign: 113, exercise: Constants.deductions.turn_270_right },
          { sign: 114, exercise: Constants.deductions.turn_270_left },
          { sign: 115, exercise: Constants.deductions.turn_360_right },
          { sign: 116, exercise: Constants.deductions.turn_360_left },
          { sign: 117, exercise: Constants.deductions.halt_90_pivot_right_halt },
          { sign: 118, exercise: Constants.deductions.halt_90_pivot_right_forward },
          { sign: 119, exercise: Constants.deductions.call_dog_front_handler_returns },
          { sign: 120, exercise: Constants.deductions.call_dog_front_dog_right_forward },
          { sign: 121, exercise: Constants.deductions.call_dog_front_dog_left_forward },
          { sign: 122, exercise: Constants.deductions.call_dog_front_finish_right_halt },
          { sign: 123, exercise: Constants.deductions.call_dog_front_finish_left_halt },
          { sign: 124, exercise: Constants.deductions.slow_pace },
          { sign: 125, exercise: Constants.deductions.fast_pace },
          { sign: 126, exercise: Constants.deductions.normal_pace },
          { sign: 127, exercise: Constants.deductions.moving_side_step_right },
          { sign: 128, exercise: Constants.deductions.halt_side_step_right_forward },
          { sign: 129, exercise: Constants.deductions.spiral_right_dog_outside },
          { sign: 130, exercise: Constants.deductions.spiral_left_dog_inside },
          { sign: "131a", exercise: Constants.deductions.serpentine_4_cones },
          { sign: "131a", exercise: Constants.deductions.serpentine_5_cones },
          { sign: "132a", exercise: Constants.deductions.serpentine_loop_4_cones },
          { sign: "132b", exercise: Constants.deductions.serpentine_loop_5_cones },
          { sign: 133, exercise: Constants.deductions.curl_dog_inside_3_cones },
          { sign: 134, exercise: Constants.deductions.curl_dog_outside_3_cones },
          { sign: 135, exercise: Constants.deductions.ribbon_loop_right },
          { sign: 136, exercise: Constants.deductions.ribbon_loop_left },
          { sign: 137, exercise: Constants.deductions.figure_8_to_right },
          { sign: 138, exercise: Constants.deductions.figure_8_to_left },
          { sign: 139, exercise: Constants.deductions.halt_1_2_3_steps_forward },
          { sign: 150, exercise: Constants.deductions.right_turn_150 },
          { sign: 151, exercise: Constants.deductions.right_turn_151 },
          { sign: 152, exercise: Constants.deductions.left_turn_152 },
          { sign: 153, exercise: Constants.deductions.left_turn_153 },
          { sign: 154, exercise: Constants.deductions.about_turn_right_154 },
          { sign: 155, exercise: Constants.deductions.about_turn_left_155 },
          { sign: 156, exercise: Constants.deductions.turn_270_right_156 },
          { sign: 157, exercise: Constants.deductions.turn_270_left_157 },
          { sign: "START", exercise: Constants.deductions.start },
          { sign: "FINISH", exercise: Constants.deductions.finish },
          { sign: "ENTER", exercise: Constants.deductions.enter },
          { sign: "EXIT", exercise: Constants.deductions.exit },
        ],
      },
      [Constants.dog_class_element_level.ro1_c]: {
        I: [
          { sign: 101, exercise: Constants.deductions.halt },
          { sign: 102, exercise: Constants.deductions.halt_stand },
          { sign: 103, exercise: Constants.deductions.halt_down },
          { sign: 104, exercise: Constants.deductions.halt_down_sit },
          { sign: 105, exercise: Constants.deductions.halt_walk_around_dog },
          { sign: 106, exercise: Constants.deductions.halt_down_walk_around_dog },
          { sign: 107, exercise: Constants.deductions.right_turn },
          { sign: 108, exercise: Constants.deductions.left_turn },
          { sign: 109, exercise: Constants.deductions.about_turn_right },
          { sign: 110, exercise: Constants.deductions.about_turn_right_left_turn },
          { sign: 111, exercise: Constants.deductions.about_u_turn },
          { sign: 112, exercise: Constants.deductions.about_u_turn_left_right_turn },
          { sign: 113, exercise: Constants.deductions.turn_270_right },
          { sign: 114, exercise: Constants.deductions.turn_270_left },
          { sign: 115, exercise: Constants.deductions.turn_360_right },
          { sign: 116, exercise: Constants.deductions.turn_360_left },
          { sign: 117, exercise: Constants.deductions.halt_90_pivot_right_halt },
          { sign: 118, exercise: Constants.deductions.halt_90_pivot_right_forward },
          { sign: 119, exercise: Constants.deductions.call_dog_front_handler_returns },
          { sign: 120, exercise: Constants.deductions.call_dog_front_dog_right_forward },
          { sign: 121, exercise: Constants.deductions.call_dog_front_dog_left_forward },
          { sign: 122, exercise: Constants.deductions.call_dog_front_finish_right_halt },
          { sign: 123, exercise: Constants.deductions.call_dog_front_finish_left_halt },
          { sign: 124, exercise: Constants.deductions.slow_pace },
          { sign: 125, exercise: Constants.deductions.fast_pace },
          { sign: 126, exercise: Constants.deductions.normal_pace },
          { sign: 127, exercise: Constants.deductions.moving_side_step_right },
          { sign: 128, exercise: Constants.deductions.halt_side_step_right_forward },
          { sign: 129, exercise: Constants.deductions.spiral_right_dog_outside },
          { sign: 130, exercise: Constants.deductions.spiral_left_dog_inside },
          { sign: "131a", exercise: Constants.deductions.serpentine_4_cones },
          { sign: "131a", exercise: Constants.deductions.serpentine_5_cones },
          { sign: "132a", exercise: Constants.deductions.serpentine_loop_4_cones },
          { sign: "132b", exercise: Constants.deductions.serpentine_loop_5_cones },
          { sign: 133, exercise: Constants.deductions.curl_dog_inside_3_cones },
          { sign: 134, exercise: Constants.deductions.curl_dog_outside_3_cones },
          { sign: 135, exercise: Constants.deductions.ribbon_loop_right },
          { sign: 136, exercise: Constants.deductions.ribbon_loop_left },
          { sign: 137, exercise: Constants.deductions.figure_8_to_right },
          { sign: 138, exercise: Constants.deductions.figure_8_to_left },
          { sign: 139, exercise: Constants.deductions.halt_1_2_3_steps_forward },
          { sign: 150, exercise: Constants.deductions.right_turn_150 },
          { sign: 151, exercise: Constants.deductions.right_turn_151 },
          { sign: 152, exercise: Constants.deductions.left_turn_152 },
          { sign: 153, exercise: Constants.deductions.left_turn_153 },
          { sign: 154, exercise: Constants.deductions.about_turn_right_154 },
          { sign: 155, exercise: Constants.deductions.about_turn_left_155 },
          { sign: 156, exercise: Constants.deductions.turn_270_right_156 },
          { sign: 157, exercise: Constants.deductions.turn_270_left_157 },
          { sign: "START", exercise: Constants.deductions.start },
          { sign: "FINISH", exercise: Constants.deductions.finish },
          { sign: "ENTER", exercise: Constants.deductions.enter },
          { sign: "EXIT", exercise: Constants.deductions.exit },
        ],
      },
      [Constants.dog_class_element_level.ro2_a]: {
        I: [
          { sign: 201, exercise: Constants.deductions.halt_leave_face_dog_call_front_finish_right },
          { sign: 202, exercise: Constants.deductions.halt_leave_face_dog_call_front_finish_left },
          { sign: 203, exercise: Constants.deductions.halt_sit_walk_around_cone },
          { sign: 204, exercise: Constants.deductions.halt_down_walk_around_cone },
          { sign: 205, exercise: Constants.deductions.halt_leave_face_dog_down_return_forward },
          { sign: 206, exercise: Constants.deductions.offset_figure_8 },
          { sign: 207, exercise: Constants.deductions.call_front_1_2_3_steps_back_right_forward },
          { sign: 208, exercise: Constants.deductions.call_front_1_2_3_steps_back_left_forward },
          { sign: 209, exercise: Constants.deductions.double_pivot_90_left_halt },
          { sign: 210, exercise: Constants.deductions.double_pivot_90_right_halt },
          { sign: 211, exercise: Constants.deductions.triple_loop_left },
          { sign: 212, exercise: Constants.deductions.triple_loop_right },
          { sign: 213, exercise: Constants.deductions.halt_90_pivot_left_forward },
          { sign: 214, exercise: Constants.deductions.halt_90_pivot_left_halt },
          { sign: 215, exercise: Constants.deductions.halt_about_turn_right_forward },
          { sign: 216, exercise: Constants.deductions.halt_about_u_turn_forward },
          { sign: 217, exercise: Constants.deductions.halt_90_pivot_right_halt_90_pivot_right_forward },
          { sign: 218, exercise: Constants.deductions.halt_90_pivot_left_halt_90_pivot_left_forward },
          { sign: 219, exercise: Constants.deductions.moving_down_forward_from_down },
          { sign: 220, exercise: Constants.deductions.halt_pivot_90_left_call_heel },
          { sign: 221, exercise: Constants.deductions.halt_pivot_90_right_call_heel },
          { sign: 222, exercise: Constants.deductions.halt_turn_180_right_call_heel_halt },
          { sign: 223, exercise: Constants.deductions.halt_leave_dog_face_dog_call_front_handler_return_left },
          { sign: 224, exercise: Constants.deductions.halt_leave_dog_face_dog_call_front_handler_return_right },
        ],
      },
      [Constants.dog_class_element_level.ro2_b]: {
        I: [
          { sign: 201, exercise: Constants.deductions.halt_leave_face_dog_call_front_finish_right },
          { sign: 202, exercise: Constants.deductions.halt_leave_face_dog_call_front_finish_left },
          { sign: 203, exercise: Constants.deductions.halt_sit_walk_around_cone },
          { sign: 204, exercise: Constants.deductions.halt_down_walk_around_cone },
          { sign: 205, exercise: Constants.deductions.halt_leave_face_dog_down_return_forward },
          { sign: 206, exercise: Constants.deductions.offset_figure_8 },
          { sign: 207, exercise: Constants.deductions.call_front_1_2_3_steps_back_right_forward },
          { sign: 208, exercise: Constants.deductions.call_front_1_2_3_steps_back_left_forward },
          { sign: 209, exercise: Constants.deductions.double_pivot_90_left_halt },
          { sign: 210, exercise: Constants.deductions.double_pivot_90_right_halt },
          { sign: 211, exercise: Constants.deductions.triple_loop_left },
          { sign: 212, exercise: Constants.deductions.triple_loop_right },
          { sign: 213, exercise: Constants.deductions.halt_90_pivot_left_forward },
          { sign: 214, exercise: Constants.deductions.halt_90_pivot_left_halt },
          { sign: 215, exercise: Constants.deductions.halt_about_turn_right_forward },
          { sign: 216, exercise: Constants.deductions.halt_about_u_turn_forward },
          { sign: 217, exercise: Constants.deductions.halt_90_pivot_right_halt_90_pivot_right_forward },
          { sign: 218, exercise: Constants.deductions.halt_90_pivot_left_halt_90_pivot_left_forward },
          { sign: 219, exercise: Constants.deductions.moving_down_forward_from_down },
          { sign: 220, exercise: Constants.deductions.halt_pivot_90_left_call_heel },
          { sign: 221, exercise: Constants.deductions.halt_pivot_90_right_call_heel },
          { sign: 222, exercise: Constants.deductions.halt_turn_180_right_call_heel_halt },
          { sign: 223, exercise: Constants.deductions.halt_leave_dog_face_dog_call_front_handler_return_left },
          { sign: 224, exercise: Constants.deductions.halt_leave_dog_face_dog_call_front_handler_return_right },
        ],
      },
      [Constants.dog_class_element_level.ro3_a]: {
        I: [
          { sign: 301, exercise: Constants.deductions.halt_180_pivot_right_halt },
          { sign: 302, exercise: Constants.deductions.halt_180_pivot_left_halt },
          { sign: 303, exercise: Constants.deductions.halt_180_pivot_right_halt_90_pivot_left_halt },
          { sign: 304, exercise: Constants.deductions.halt_180_pivot_left_halt_90_pivot_right_halt },
          { sign: 305, exercise: Constants.deductions.moving_down_handler_forward_face_dog_call_dog_finish_right },
          { sign: 306, exercise: Constants.deductions.moving_down_handler_forward_face_dog_call_dog_finish_left },
          { sign: 307, exercise: Constants.deductions.halt_send_dog_around_front_back_to_heel },
          { sign: 308, exercise: Constants.deductions.halt_fast_forward_from_sit },
          { sign: 309, exercise: Constants.deductions.left_about_turn },
          { sign: 310, exercise: Constants.deductions.halt_stand_sit },
          { sign: 311, exercise: Constants.deductions.halt_stand_down },
          { sign: 312, exercise: Constants.deductions.halt_stand_walk_around_dog },
          { sign: 313, exercise: Constants.deductions.halt_stand_heel_backward_3_steps_halt },
          { sign: 314, exercise: Constants.deductions.halt_handler_walks_to_cone_call_dog_to_heel },
          { sign: 315, exercise: Constants.deductions.send_over_jump_handler_runs_by },
          { sign: 316, exercise: Constants.deductions.turn_left_90_double_step_back_forward },
          { sign: 317, exercise: Constants.deductions.turn_right_90_double_step_back_forward },
          { sign: 318, exercise: Constants.deductions.halt_leave_dog_face_dog_call_to_heel_right },
          { sign: 319, exercise: Constants.deductions.halt_leave_dog_face_dog_call_to_heel_left },
          { sign: 320, exercise: Constants.deductions.moving_stand_walk_around_cone_back_to_dog },
          { sign: 321, exercise: Constants.deductions.moving_sit_walk_around_cone_back_to_dog },
          { sign: 322, exercise: Constants.deductions.moving_down_walk_around_cone_back_to_dog },
          { sign: 323, exercise: Constants.deductions.moving_down_handler_forward_call_dog_to_heel },
          { sign: 324, exercise: Constants.deductions.moving_sit_handler_forward_call_dog_to_heel },
          { sign: 325, exercise: Constants.deductions.moving_stand_handler_forward_call_dog_to_heel },
          { sign: 326, exercise: Constants.deductions.honor_sit },
          { sign: 327, exercise: Constants.deductions.honor_down },
        ],
      },
      [Constants.dog_class_element_level.ro3_b]: {
        I: [
          { sign: 301, exercise: Constants.deductions.halt_180_pivot_right_halt },
          { sign: 302, exercise: Constants.deductions.halt_180_pivot_left_halt },
          { sign: 303, exercise: Constants.deductions.halt_180_pivot_right_halt_90_pivot_left_halt },
          { sign: 304, exercise: Constants.deductions.halt_180_pivot_left_halt_90_pivot_right_halt },
          { sign: 305, exercise: Constants.deductions.moving_down_handler_forward_face_dog_call_dog_finish_right },
          { sign: 306, exercise: Constants.deductions.moving_down_handler_forward_face_dog_call_dog_finish_left },
          { sign: 307, exercise: Constants.deductions.halt_send_dog_around_front_back_to_heel },
          { sign: 308, exercise: Constants.deductions.halt_fast_forward_from_sit },
          { sign: 309, exercise: Constants.deductions.left_about_turn },
          { sign: 310, exercise: Constants.deductions.halt_stand_sit },
          { sign: 311, exercise: Constants.deductions.halt_stand_down },
          { sign: 312, exercise: Constants.deductions.halt_stand_walk_around_dog },
          { sign: 313, exercise: Constants.deductions.halt_stand_heel_backward_3_steps_halt },
          { sign: 314, exercise: Constants.deductions.halt_handler_walks_to_cone_call_dog_to_heel },
          { sign: 315, exercise: Constants.deductions.send_over_jump_handler_runs_by },
          { sign: 316, exercise: Constants.deductions.turn_left_90_double_step_back_forward },
          { sign: 317, exercise: Constants.deductions.turn_right_90_double_step_back_forward },
          { sign: 318, exercise: Constants.deductions.halt_leave_dog_face_dog_call_to_heel_right },
          { sign: 319, exercise: Constants.deductions.halt_leave_dog_face_dog_call_to_heel_left },
          { sign: 320, exercise: Constants.deductions.moving_stand_walk_around_cone_back_to_dog },
          { sign: 321, exercise: Constants.deductions.moving_sit_walk_around_cone_back_to_dog },
          { sign: 322, exercise: Constants.deductions.moving_down_walk_around_cone_back_to_dog },
          { sign: 323, exercise: Constants.deductions.moving_down_handler_forward_call_dog_to_heel },
          { sign: 324, exercise: Constants.deductions.moving_sit_handler_forward_call_dog_to_heel },
          { sign: 325, exercise: Constants.deductions.moving_stand_handler_forward_call_dog_to_heel },
          { sign: 326, exercise: Constants.deductions.honor_sit },
          { sign: 327, exercise: Constants.deductions.honor_down },
        ],
      },
      [Constants.dog_class_element_level.rom_a]: {
        I: [
          { sign: 401, exercise: Constants.deductions.call_dog_front_90_pivot_right_finish_left },
          { sign: 402, exercise: Constants.deductions.call_dog_front_90_pivot_left_finish_right },
          { sign: 403, exercise: Constants.deductions.halt_leave_call_dog_over_broad_jump_front_finish_right },
          { sign: 404, exercise: Constants.deductions.halt_leave_call_dog_over_broad_jump_front_finish_left },
          { sign: 405, exercise: Constants.deductions.halt_90_pivot_right_halt_180_pivot_left_halt },
          { sign: 406, exercise: Constants.deductions.halt_90_pivot_left_halt_180_pivot_right_halt },
          { sign: 407, exercise: Constants.deductions.forward_reverse_3_steps_pivot_90_left_forward },
          { sign: 408, exercise: Constants.deductions.forward_reverse_3_steps_pivot_90_right_forward },
          { sign: 409, exercise: Constants.deductions.spiral_in_dog_outside_spiral_out_dog_inside },
          { sign: 410, exercise: Constants.deductions.spiral_in_dog_inside_spiral_out_dog_outside },
          { sign: 411, exercise: Constants.deductions.halt_side_step_right_halt_270_right_turn_forward },
          { sign: 412, exercise: Constants.deductions.halt_side_step_right_halt_270_left_turn_forward },
          { sign: 413, exercise: Constants.deductions.moving_stand_walk_to_cone_halt_call_dog_to_heel },
          { sign: 414, exercise: Constants.deductions.moving_sit_walk_to_cone_halt_call_dog_to_heel },
          { sign: 415, exercise: Constants.deductions.moving_down_walk_to_cone_halt_call_dog_to_heel },
          { sign: 416, exercise: Constants.deductions.moving_stand_handler_forward_face_dog_down_sit_finish_right },
          { sign: 417, exercise: Constants.deductions.moving_stand_handler_forward_face_dog_down_sit_finish_left },
          { sign: 418, exercise: Constants.deductions.cloverleaf },
          { sign: 419, exercise: Constants.deductions.halt_send_dog_around_cone_dog_front_handler_returns_right },
          { sign: 420, exercise: Constants.deductions.halt_send_dog_around_cone_front_finish_right_180_pivot_right_forward },
          { sign: 421, exercise: Constants.deductions.halt_send_dog_around_cone_front_finish_left_180_pivot_right_forward },
          { sign: 422, exercise: Constants.deductions.halt_send_dog_around_cone_return_to_heel_right_180_pivot_right_forward },
          { sign: 423, exercise: Constants.deductions.halt_send_dog_forward_around_cone_return_to_heel_left_180_pivot_right_forward },
          { sign: 424, exercise: Constants.deductions.halt_leave_dog_call_dog_over_jump_dog_front_handler_returns_right },
        ],
      },
      [Constants.dog_class_element_level.rom_b]: {
        I: [
          { sign: 401, exercise: Constants.deductions.call_dog_front_90_pivot_right_finish_left },
          { sign: 402, exercise: Constants.deductions.call_dog_front_90_pivot_left_finish_right },
          { sign: 403, exercise: Constants.deductions.halt_leave_call_dog_over_broad_jump_front_finish_right },
          { sign: 404, exercise: Constants.deductions.halt_leave_call_dog_over_broad_jump_front_finish_left },
          { sign: 405, exercise: Constants.deductions.halt_90_pivot_right_halt_180_pivot_left_halt },
          { sign: 406, exercise: Constants.deductions.halt_90_pivot_left_halt_180_pivot_right_halt },
          { sign: 407, exercise: Constants.deductions.forward_reverse_3_steps_pivot_90_left_forward },
          { sign: 408, exercise: Constants.deductions.forward_reverse_3_steps_pivot_90_right_forward },
          { sign: 409, exercise: Constants.deductions.spiral_in_dog_outside_spiral_out_dog_inside },
          { sign: 410, exercise: Constants.deductions.spiral_in_dog_inside_spiral_out_dog_outside },
          { sign: 411, exercise: Constants.deductions.halt_side_step_right_halt_270_right_turn_forward },
          { sign: 412, exercise: Constants.deductions.halt_side_step_right_halt_270_left_turn_forward },
          { sign: 413, exercise: Constants.deductions.moving_stand_walk_to_cone_halt_call_dog_to_heel },
          { sign: 414, exercise: Constants.deductions.moving_sit_walk_to_cone_halt_call_dog_to_heel },
          { sign: 415, exercise: Constants.deductions.moving_down_walk_to_cone_halt_call_dog_to_heel },
          { sign: 416, exercise: Constants.deductions.moving_stand_handler_forward_face_dog_down_sit_finish_right },
          { sign: 417, exercise: Constants.deductions.moving_stand_handler_forward_face_dog_down_sit_finish_left },
          { sign: 418, exercise: Constants.deductions.cloverleaf },
          { sign: 419, exercise: Constants.deductions.halt_send_dog_around_cone_dog_front_handler_returns_right },
          { sign: 420, exercise: Constants.deductions.halt_send_dog_around_cone_front_finish_right_180_pivot_right_forward },
          { sign: 421, exercise: Constants.deductions.halt_send_dog_around_cone_front_finish_left_180_pivot_right_forward },
          { sign: 422, exercise: Constants.deductions.halt_send_dog_around_cone_return_to_heel_right_180_pivot_right_forward },
          { sign: 423, exercise: Constants.deductions.halt_send_dog_forward_around_cone_return_to_heel_left_180_pivot_right_forward },
          { sign: 424, exercise: Constants.deductions.halt_leave_dog_call_dog_over_jump_dog_front_handler_returns_right },
        ],
      },
    },
  },
  [Constants.sanctioning_club.AKC]: {
    [Constants.show_type.rally_obedience]: {
      [Constants.dog_class_element_level.novice_a]: {
        I: [
          Constants.deductions.minor_deduction,
          Constants.deductions.minor_or_substantial_deduction,
          Constants.deductions.substantial_deduction,
          Constants.deductions.non_qualfying,
        ],
      },
      [Constants.dog_class_element_level.novice_b]: {
        I: [
          Constants.deductions.minor_deduction,
          Constants.deductions.minor_or_substantial_deduction,
          Constants.deductions.substantial_deduction,
          Constants.deductions.non_qualfying,
        ],
      },
      [Constants.dog_class_element_level.intermediate]: {
        I: [
          Constants.deductions.minor_deduction,
          Constants.deductions.minor_or_substantial_deduction,
          Constants.deductions.substantial_deduction,
          Constants.deductions.non_qualfying,
        ],
      },
      [Constants.dog_class_element_level.advanced_a]: {
        I: [
          Constants.deductions.minor_deduction,
          Constants.deductions.minor_or_substantial_deduction,
          Constants.deductions.substantial_deduction,
          Constants.deductions.non_qualfying,
        ],
      },
      [Constants.dog_class_element_level.advanced_b]: {
        I: [
          Constants.deductions.minor_deduction,
          Constants.deductions.minor_or_substantial_deduction,
          Constants.deductions.substantial_deduction,
          Constants.deductions.non_qualfying,
        ],
      },
      [Constants.dog_class_element_level.excellent_a]: {
        I: [
          Constants.deductions.minor_deduction,
          Constants.deductions.minor_or_substantial_deduction,
          Constants.deductions.substantial_deduction,
          Constants.deductions.non_qualfying,
        ],
      },
      [Constants.dog_class_element_level.excellent_b]: {
        I: [
          Constants.deductions.minor_deduction,
          Constants.deductions.minor_or_substantial_deduction,
          Constants.deductions.substantial_deduction,
          Constants.deductions.non_qualfying,
        ],
      },
      [Constants.dog_class_element_level.master]: {
        I: [
          Constants.deductions.minor_deduction,
          Constants.deductions.minor_or_substantial_deduction,
          Constants.deductions.substantial_deduction,
          Constants.deductions.non_qualfying,
        ],
      },
      [Constants.dog_class_element_level.choice]: {
        I: [
          Constants.deductions.minor_deduction,
          Constants.deductions.minor_or_substantial_deduction,
          Constants.deductions.substantial_deduction,
          Constants.deductions.non_qualfying,
        ],
      },
      [Constants.dog_class_element_level.pairs]: {
        I: [
          Constants.deductions.minor_deduction,
          Constants.deductions.minor_or_substantial_deduction,
          Constants.deductions.substantial_deduction,
          Constants.deductions.non_qualfying,
        ],
      },
      [Constants.dog_class_element_level.team]: {
        I: [
          Constants.deductions.minor_deduction,
          Constants.deductions.minor_or_substantial_deduction,
          Constants.deductions.substantial_deduction,
          Constants.deductions.non_qualfying,
        ],
      },
      [Constants.dog_class_element_level.rae]: {
        I: [
          Constants.deductions.minor_deduction,
          Constants.deductions.minor_or_substantial_deduction,
          Constants.deductions.substantial_deduction,
          Constants.deductions.non_qualfying,
        ],
      },
      [Constants.dog_class_element_level.raem]: {
        I: [
          Constants.deductions.minor_deduction,
          Constants.deductions.minor_or_substantial_deduction,
          Constants.deductions.substantial_deduction,
          Constants.deductions.non_qualfying,
        ],
      },
    },
    [Constants.show_type.obedience]: {
      [Constants.dog_class_element_level.beginner_novice_a]: {
        I: [
          Constants.deductions.heel_on_leash,
          Constants.deductions.figure_eight,
          Constants.deductions.sit_for_exam,
          Constants.deductions.sit_stay,
          Constants.deductions.recall,
          Constants.deductions.misc,
        ],
      },
      [Constants.dog_class_element_level.beginner_novice_b]: {
        I: [
          Constants.deductions.heel_on_leash,
          Constants.deductions.figure_eight,
          Constants.deductions.sit_for_exam,
          Constants.deductions.sit_stay,
          Constants.deductions.recall,
          Constants.deductions.misc,
        ],
      },
      [Constants.dog_class_element_level.preferred_novice]: {
        I: [
          Constants.deductions.heel_on_leash_figure_eight,
          Constants.deductions.stand_for_exam,
          Constants.deductions.heel_free,
          Constants.deductions.recall,
          Constants.deductions.stay_sit_or_down,
          Constants.deductions.sit_stay_get_your_leash,
          Constants.deductions.misc,
        ],
      },
      [Constants.dog_class_element_level.graduate_novice]: {
        I: [
          Constants.deductions.heel_free_figure_eight_off_leash,
          Constants.deductions.drop_on_recall,
          Constants.deductions.dumbbell_recall,
          Constants.deductions.dumbbell_recall_over_high_jump,
          Constants.deductions.recall_over_broad_jump,
          Constants.deductions.stand_stay_get_your_leash,
          Constants.deductions.misc,
        ],
      },
      [Constants.dog_class_element_level.novice_a]: {
        I: [
          Constants.deductions.heel_on_leash_figure_eight,
          Constants.deductions.stand_for_exam,
          Constants.deductions.heel_free,
          Constants.deductions.recall,
          Constants.deductions.sit_stay_get_your_leash,
          Constants.deductions.group_sit_and_down,
          Constants.deductions.misc,
        ],
      },
      [Constants.dog_class_element_level.novice_b]: {
        I: [
          Constants.deductions.heel_on_leash_figure_eight,
          Constants.deductions.stand_for_exam,
          Constants.deductions.heel_free,
          Constants.deductions.recall,
          Constants.deductions.sit_stay_get_your_leash,
          Constants.deductions.group_sit_and_down,
          Constants.deductions.misc,
        ],
      },
      [Constants.dog_class_element_level.open_a]: {
        I: [
          Constants.deductions.heel_free_figure_eight,
          Constants.deductions.cmd_disc_stand_down_sit,
          Constants.deductions.drop_on_recall,
          Constants.deductions.retrieve_on_flat,
          Constants.deductions.retrieve_over_high_jump,
          Constants.deductions.broad_jump,
          Constants.deductions.stand_stay_get_your_leash,
          Constants.deductions.misc,
        ],
      },
      [Constants.dog_class_element_level.open_b]: {
        I: [
          Constants.deductions.heel_free_figure_eight,
          Constants.deductions.cmd_disc_stand_down_sit,
          Constants.deductions.drop_on_recall,
          Constants.deductions.retrieve_on_flat,
          Constants.deductions.retrieve_over_high_jump,
          Constants.deductions.broad_jump,
          Constants.deductions.stand_stay_get_your_leash,
          Constants.deductions.misc,
        ],
        II: [
          Constants.deductions.broad_jump,
          Constants.deductions.retrieve_over_high_jump,
          Constants.deductions.retrieve_on_flat,
          Constants.deductions.drop_on_recall,
          Constants.deductions.cmd_disc_down_sit_stand,
          Constants.deductions.heel_free_figure_eight,
          Constants.deductions.stand_stay_get_your_leash,
          Constants.deductions.misc,
        ],
        IV: [
          Constants.deductions.drop_on_recall,
          Constants.deductions.heel_free_figure_eight,
          Constants.deductions.retrieve_over_high_jump,
          Constants.deductions.cmd_disc_down_stand_sit,
          Constants.deductions.broad_jump,
          Constants.deductions.retrieve_on_flat,
          Constants.deductions.stand_stay_get_your_leash,
          Constants.deductions.misc,
        ],
        V: [
          Constants.deductions.retrieve_over_high_jump,
          Constants.deductions.drop_on_recall,
          Constants.deductions.cmd_disc_stand_sit_down,
          Constants.deductions.figure_eight_heel_free,
          Constants.deductions.retrieve_on_flat,
          Constants.deductions.broad_jump,
          Constants.deductions.stand_stay_get_your_leash,
          Constants.deductions.misc,
        ],
        VI: [
          Constants.deductions.cmd_disc_down_stand_sit,
          Constants.deductions.broad_jump,
          Constants.deductions.figure_eight_heel_free,
          Constants.deductions.drop_on_recall,
          Constants.deductions.retrieve_over_high_jump,
          Constants.deductions.retrieve_on_flat,
          Constants.deductions.stand_stay_get_your_leash,
          Constants.deductions.misc,
        ],
      },
      [Constants.dog_class_element_level.preferred_open]: {
        I: [
          Constants.deductions.heel_free_figure_eight,
          Constants.deductions.cmd_disc_stand_down_sit,
          Constants.deductions.drop_on_recall,
          Constants.deductions.retrieve_on_flat,
          Constants.deductions.retrieve_over_high_jump,
          Constants.deductions.broad_jump,
          Constants.deductions.stand_stay_get_your_leash,
          Constants.deductions.misc,
        ],
      },
      [Constants.dog_class_element_level.graduate_open]: {
        I: [
          Constants.deductions.signal_exercise,
          Constants.deductions.scent_disc,
          Constants.deductions.go_out,
          Constants.deductions.directed_jumping,
          Constants.deductions.moving_stand_and_exam,
          Constants.deductions.directed_retrieve,
          Constants.deductions.misc,
        ],
      },
      [Constants.dog_class_element_level.utility_a]: {
        I: [
          Constants.deductions.signal_exercise,
          Constants.deductions.scent_disc_art_1,
          Constants.deductions.scent_disc_art_2,
          Constants.deductions.directed_retrieve,
          Constants.deductions.moving_stand_and_exam,
          Constants.deductions.directed_jumping,
          Constants.deductions.misc,
        ],
      },
      [Constants.dog_class_element_level.utility_b]: {
        I: [
          Constants.deductions.signal_exercise,
          Constants.deductions.scent_disc_art_1,
          Constants.deductions.scent_disc_art_2,
          Constants.deductions.directed_retrieve,
          Constants.deductions.moving_stand_and_exam,
          Constants.deductions.directed_jumping,
          Constants.deductions.misc,
        ],
        II: [
          Constants.deductions.directed_jumping,
          Constants.deductions.moving_stand_and_exam,
          Constants.deductions.directed_retrieve,
          Constants.deductions.scent_disc_art_1,
          Constants.deductions.scent_disc_art_2,
          Constants.deductions.signal_exercise,
          Constants.deductions.misc,
        ],
        III: [
          Constants.deductions.scent_disc_art_1,
          Constants.deductions.scent_disc_art_2,
          Constants.deductions.directed_retrieve,
          Constants.deductions.signal_exercise,
          Constants.deductions.moving_stand_and_exam,
          Constants.deductions.directed_jumping,
          Constants.deductions.misc,
        ],
      },
      [Constants.dog_class_element_level.preferred_utility]: {
        I: [
          Constants.deductions.signal_exercise,
          Constants.deductions.scent_disc_art_1,
          Constants.deductions.scent_disc_art_2,
          Constants.deductions.directed_jumping,
          Constants.deductions.moving_stand_and_exam,
          Constants.deductions.directed_retrieve,
          Constants.deductions.misc,
        ],
      },
      [Constants.dog_class_element_level.versatility]: {
        I: [
          Constants.deductions.drop_on_recall,
          Constants.deductions.retrieve_on_flat,
          Constants.deductions.scent_discrimination_leather,
          Constants.deductions.directed_retrieve,
          Constants.deductions.directed_jumping
          ],
          II: [
           Constants.deductions.heel_free,
           Constants.deductions.broad_jump,
           Constants.deductions.scent_discrimination_metal,
           Constants.deductions.signal_exercise,
           Constants.deductions.directed_jumping
          ],
          III: [
           Constants.deductions.retrieve_over_high_jump,
           Constants.deductions.broad_jump,
           Constants.deductions.scent_discrimination_metal,
           Constants.deductions.directed_retrieve,
           Constants.deductions.moving_stand_and_exam
          ],
          IV: [
           Constants.deductions.retrieve_on_flat,
           Constants.deductions.heel_free,
           Constants.deductions.directed_retrieve,
           Constants.deductions.scent_discrimination_leather,
           Constants.deductions.signal_exercise
          ],
          V: [
           Constants.deductions.drop_on_recall,
           Constants.deductions.broad_jump,
           Constants.deductions.scent_discrimination_leather,
           Constants.deductions.moving_stand_and_exam,
           Constants.deductions.directed_jumping
          ],
          VI: [
           Constants.deductions.retrieve_over_high_jump,
           Constants.deductions.drop_on_recall,
           Constants.deductions.signal_exercise,
           Constants.deductions.directed_retrieve,
           Constants.deductions.scent_discrimination_metal
          ],
          VII: [
           Constants.deductions.broad_jump,
           Constants.deductions.heel_free,
           Constants.deductions.moving_stand_and_exam,
           Constants.deductions.scent_discrimination_metal,
           Constants.deductions.directed_jumping
          ],
          VIII: [
           Constants.deductions.retrieve_on_flat,
           Constants.deductions.drop_on_recall,
           Constants.deductions.scent_discrimination_leather,
           Constants.deductions.directed_retrieve,
           Constants.deductions.moving_stand_and_exam
          ],
          IX: [
           Constants.deductions.heel_free,
           Constants.deductions.retrieve_over_high_jump,
           Constants.deductions.scent_discrimination_metal,
           Constants.deductions.directed_jumping,
           Constants.deductions.signal_exercise
          ],
          X: [
           Constants.deductions.broad_jump,
           Constants.deductions.drop_on_recall,
           Constants.deductions.scent_discrimination_leather,
           Constants.deductions.signal_exercise,
           Constants.deductions.directed_retrieve
          ],
          XI: [
           Constants.deductions.retrieve_on_flat,
           Constants.deductions.broad_jump,
           Constants.deductions.scent_discrimination_metal,
           Constants.deductions.directed_jumping,
           Constants.deductions.moving_stand_and_exam
          ],
          XII: [
           Constants.deductions.retrieve_over_high_jump,
           Constants.deductions.heel_free,
           Constants.deductions.signal_exercise,
           Constants.deductions.directed_retrieve,
           Constants.deductions.scent_discrimination_leather
          ]
      },
      [Constants.dog_class_element_level.veteran]: {
        I: [
          Constants.deductions.heel_on_leash_figure_eight,
          Constants.deductions.stand_for_exam,
          Constants.deductions.heel_free,
          Constants.deductions.recall,
          Constants.deductions.sit_stay_get_your_leash,
          Constants.deductions.group_sit_and_down,
          Constants.deductions.misc,
        ],
      },
      [Constants.dog_class_element_level.brace]: [
        /* UNKNOWN */
      ],
      [Constants.dog_class_element_level.team]: [
        /* UNKNOWN */
      ],
      [Constants.dog_class_element_level.wc_novice]: [
        /* UNKNOWN */
      ],
      [Constants.dog_class_element_level.wc_open]: [
        /* UNKNOWN */
      ],
      [Constants.dog_class_element_level.wc_utility]: [
        /* UNKNOWN */
      ],
      [Constants.dog_class_element_level.udx]: [
        /* UNKNOWN */
      ],
    },
  },
};
