import { Constants } from "@eagerdog/constants";
import { IEventRegisterRow } from "../../../services/helper.service.js";

export namespace Obedience {
  export const form:IEventRegisterRow[] = [{
    allowDuplicates: false,
    inputs: [{
      type: "checkbox", label: Constants.dog_class_element.performance_obedience, multiSelect: true, options: [
        { value: Constants.dog_class_element_level.pre_novice, id: Constants.dog_class_element_level.pre_novice },
        { value: Constants.dog_class_element_level.beginner_novice, id: Constants.dog_class_element_level.beginner_novice },
        { value: Constants.dog_class_element_level.novice_a, id: Constants.dog_class_element_level.novice_a },
        { value: Constants.dog_class_element_level.novice_b, id: Constants.dog_class_element_level.novice_b },
        { value: Constants.dog_class_element_level.novice_c, id: Constants.dog_class_element_level.novice_c },
        { value: Constants.dog_class_element_level.advance_novice, id: Constants.dog_class_element_level.advance_novice },
        { value: Constants.dog_class_element_level.open_a, id: Constants.dog_class_element_level.open_a },
        { value: Constants.dog_class_element_level.open_b, id: Constants.dog_class_element_level.open_b },
        { value: Constants.dog_class_element_level.advance_open, id: Constants.dog_class_element_level.advance_open },
        { value: Constants.dog_class_element_level.utility_a, id: Constants.dog_class_element_level.utility_a },
        { value: Constants.dog_class_element_level.utility_b, id: Constants.dog_class_element_level.utility_b },
        { value: Constants.dog_class_element_level.master, id: Constants.dog_class_element_level.master },
        { value: Constants.dog_class_element_level.elite, id: Constants.dog_class_element_level.elite }
     ]
    }]
   }, {
    allowDuplicates: false,
    inputs: [{
      type: "checkbox", label: Constants.dog_class_element.performance_non_licensed_obedience, multiSelect: true, options: [
        { value: Constants.dog_class_element_level.brace, id: Constants.dog_class_element_level.brace },
        { value: Constants.dog_class_element_level.pairs, id: Constants.dog_class_element_level.pairs },
        { value: Constants.dog_class_element_level.precision_heeling, id: Constants.dog_class_element_level.precision_heeling },
        { value: Constants.dog_class_element_level.team, id: Constants.dog_class_element_level.team },
        { value: Constants.dog_class_element_level.veteran, id: Constants.dog_class_element_level.veteran }
     ]
    }]
   }];
}