import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { Constants } from "@eagerdog/constants";
import { IDog, IUser, /*UserUpdateDto, */IUserTransactionSummary } from "@eagerdog/interfaces";

import { helperService } from "src/services/helper.service";
import { localStorageService } from "src/services/local-storage.service";

import Confirm from "src/components/Confirm/Confirm";
import TabContent, { ITab, useTabContent } from "../../components/TabContent/TabContent";
import { toast } from "../../components/Toast/ToastManager";
import Table, { IHeader } from "src/components/Table/Table";
import { apiService } from "src/services/api.service";
import { AxiosError } from "axios";
import { Modal, useModal } from "src/components/Modal/Modal";
import DogForm from "src/components/StepForm/DogForm/DogForm";
import Input from "src/components/Input/Input";
import keyIcon from "../../assets/icons/key.svg";
import Button from "src/components/Button/Button";
import Dropdown, { IOption, States, Provinces } from "src/components/Dropdown/Dropdown";
import Phone from "src/components/Phone/Phone";
import DogPortrait from "src/components/DogPortrait/DogPortrait";
import { download } from "src/components/Download/DownloadManager";

import UserWaitlistTab from "./UserWaitlistTab/UserWaitlistTab";

import flagIcon from "src/assets/icons/flag.svg";

import "../../styles/ManagePage.scss";
import "./Account.scss";

interface IProps { }

const Account: React.FC<IProps> = (props) => {
  const navigate = useNavigate();

  const user: IUser = helperService.getUser();
  const pUserType = helperService.usePrevious(user.user_type);

  const [tabs, setTabs] = useState<ITab[]>();
  const [loaded, setLoaded] = useState<boolean>(false);

  const tranLimit: number = 10;
  const [tranSkip, setTranSkip] = useState<number>(0);
  const [userTransactions, setUserTransactions] = useState<IUserTransactionSummary[]>([]);

  const transactionHeaders: IHeader[] = [
    { label: "Created At", id: "created_at" },
    { label: "Status", id: "status" },
    { label: "Amount", id: "amount" },
    { label: "Event Name", id: "event_name" },
    { label: "Actions", id: "actions" }
  ];

  // Dog Info
  const [dogs, setDogs] = useState<IDog[]>([]);
  const [currentDog, setCurrentDog] = useState<IDog>();
  const [showDogModal, setShowDogModal] = useState<boolean>(false);

  const dogTableHeaders: IHeader[] = [
    { label: 'Portrait', id: 'portrait' },
    { label: 'Name', id: 'call_name' },
    { label: 'Breed', id: 'breed' },
    // { label: 'Registered Clubs', id: 'registration_numbers' },
    { label: 'Actions', id: 'actions' }
  ];

  // Personal Info
  const [userInfoChanged, setUserInfoChanged] = useState<boolean>(false);

  const [firstName, setFirstName] = useState<string>(user?.first_name ?? "");
  const [lastName, setLastName] = useState<string>(user?.last_name ?? "");

  const [displayName, setDisplayName] = useState<string>(user?.display_name ?? "");

  const [website, setWebsite] = useState<string>(user?.website ?? "");
  const [line1, setLine1] = useState<string>(user?.address?.line1 ? user.address.line1 : "");
  const [line2, setLine2] = useState<string>(user?.address?.line2 ? user.address.line2 : "");
  const [city, setCity] = useState<string>(user?.address?.city ? user.address.city : "");
  const [country, setCountry] = useState<IOption>(user?.address?.country ? { id: user?.address?.country, value: user?.address?.country } : { value: "Canada", id: "Canada" });
  const [state, setState] = useState<IOption>(user?.address?.state ? Provinces.filter((o) => o.id === user?.address?.state)[0] || States.filter((o) => o.id === user?.address?.state)[0] || Provinces[0] : Provinces[0]);
  const [postalCode, setPostalCode] = useState<string>(user?.address?.postal_code ? user?.address?.postal_code : "");
  const [clubID, setClubID] = useState<string>(user?.club_number ? user?.club_number : "");
  const [phone, setPhone] = useState<string>(user?.phone ? user?.phone : "");
  const [eContactName, setEContactName] = useState<string>(user?.emergency_contact_name ? user?.emergency_contact_name : "");
  const [ePhone, setEPhone] = useState<string>(user?.emergency_contact_phone ? user?.emergency_contact_phone : "");

  const [parentName, setParentName] = useState<string>(user?.parent_name ? user?.parent_name : "");
  const [akcJuniorNum, setAkcJuniorNum] = useState<string>(user?.junior_numbers?.filter((num: any) => { return num.name === "AKC" })[0]?.value || "");
  const [ukcJuniorNum, setUkcJuniorNum] = useState<string>(user?.junior_numbers?.filter((num: any) => { return num.name === "UKC" })[0]?.value || "");
  const [ckcJuniorNum, setCkcJuniorNum] = useState<string>(user?.junior_numbers?.filter((num: any) => { return num.name === "CKC" })[0]?.value || "");

  const [isUpdatingPersonalInfo, setIsUpdatingPersonalInfo] = useState<boolean>(false);

  // Delete Dog Modal
  const { isShown, toggle } = useModal();

  const handleConfirm = () => {
    if (currentDog && currentDog?._id) {
      apiService.updateDog(currentDog._id, { ...currentDog, is_archived: true }).then(() => {
        toast.show({
          title: "My Dogs",
          content: "You've successfully deleted " + currentDog.call_name,
          duration: 10000,
          type: "success"

        })
        getDogs();
      }).catch((error: AxiosError) => {
        toast.show({
          title: "My Dogs",
          content: "Unable to delete dog.",
          duration: 10000,
          errorDetails: error,
          type: "fail"
        });
      });
    }

    setCurrentDog(undefined);
    toggle();
  };

  const handleCancel = () => {
    setCurrentDog(undefined);
    toggle();
  };

  // Password
  const [password, setPassword] = useState<string>("");
  const [repeat, setRepeat] = useState<string>("");

  const getUserTransactions = useCallback((id: string, _limit: number, _skip: number) => {
    let query:any = {
      //query: {
        "$and": [{
          attribute_name: "status",
          attribute_value: {
              operator: "$eq",
              value: "success"
          }
        }]
      //}
    };

    apiService.getUserTransactions(id, {
      $limit: _limit,
      $skip: _skip,
      sort: [{
        attribute_name: "created_at",
        sort: "desc"
      }],
      query
    }).then((response) => {
      setUserTransactions(response);
    });
  }, []);

  const getDogs = useCallback(() => {
    apiService.getUserDogs(user._id).then((response) => {
      setDogs(response);
    }).catch((error: AxiosError) => {
      toast.show({
        title: "My Dogs",
        content: "Unable to get dogs.",
        duration: 10000,
        errorDetails: error,
        type: "fail"
      });
    });
  }, [user._id]);

  const { activeTab, setTab } = useTabContent();

  const renderOwnerTabs = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const t = queryParameters.get("t");

    if (tabs) {
      return (<TabContent setTab={setTab} activeTab={activeTab} defaultTab={t ? parseInt(t) : undefined} tabs={tabs}>
        {renderPersonalInfo()}
        {renderDogs()}
        {renderPassword()}
        {renderTransactions()}
        {renderWaitlist()}
      </TabContent>);
    } else {
      return (null);
    }
  }

  const renderWaitlist = () => {
    return <UserWaitlistTab user={user} />;
  }

  const renderManagerTabs = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const t = queryParameters.get("t");

    if (tabs) {
      return (<TabContent setTab={setTab} activeTab={activeTab} defaultTab={t ? parseInt(t) : undefined} tabs={tabs}>
        {renderPersonalInfo()}
        {renderPassword()}
      </TabContent>);
    } else {
      return (null);
    }
  }

  const updateUserInfo = () => {
    setIsUpdatingPersonalInfo(true);

    let updatedUser: any = {
      first_name: firstName,
      last_name: lastName,
      display_name: user.user_type === Constants.user_type.club_manager ? displayName : firstName+" "+lastName,
      address: {
        line1,
        line2,
        country: country.id,
        city,
        state: state.id,
        postal_code: postalCode
      },
      phone: phone,
      website: website,
      emergency_contact_name: eContactName,
      emergency_contact_phone: ePhone

    };

    if (user.user_type === Constants.user_type.club_manager) {
      updatedUser["club_number"] = clubID;
    }

    if (user.is_junior) {
      updatedUser.junior_numbers = [];
      updatedUser.parent_name = parentName;

      if (akcJuniorNum.length > 0) {
        updatedUser.junior_numbers.push({ name: Constants.sanctioning_club.AKC, value: akcJuniorNum });
      }

      if (ukcJuniorNum.length > 0) {
        updatedUser.junior_numbers.push({ name: Constants.sanctioning_club.UKC, value: ukcJuniorNum });
      }

      if (ckcJuniorNum.length > 0) {
        updatedUser.junior_numbers.push({ name: Constants.sanctioning_club.CKC, value: ckcJuniorNum });
      }
    }

    apiService.updateSelf(updatedUser).then((response) => {
      toast.show({
        title: "Personal Information",
        content: "Successfully updated personal information.",
        duration: 10000,
        type: "success"
      });

      localStorageService.setItem(localStorageService.USER, JSON.stringify(response));
      setUserInfoChanged(false);
    }).catch((error: AxiosError) => {
      toast.show({
        title: "Personal Information",
        content: "Unable to update personal information.",
        duration: 10000,
        errorDetails: error,
        type: "fail"
      });
    }).finally(() => {
      setIsUpdatingPersonalInfo(false);
    });
  }

  const updateUserPassword = () => {
    apiService.updateSelfPassword({ new_password: password }).then((response) => {
      toast.show({
        title: "Password",
        content: "Successfully updated password.",
        duration: 10000,
        type: "success"
      })
    }).catch((error: AxiosError) => {
      toast.show({
        title: "Password",
        content: "Unable to update password.",
        duration: 10000,
        type: "fail",
        errorDetails: error
      })
    });
  };

  useEffect(() => {
    if (!helperService.isLoggedIn()) {
      toast.show({
        title: "Account",
        content: "Please login to access account information",
        duration: 10000,
        type: "fail"
      });

      navigate("/login?r=/account");
    }

    if (!loaded && user.user_type.length > 0 && (pUserType !== user.user_type) && helperService.isLoggedIn()) {
      switch (user.user_type) {
        case "owner":
          setTabs([
            { name: "Personal Information", actions: [] },
            { name: "My Dogs", actions: [] },
            { name: "Password", actions: [] },
            { name: "Transactions", actions: [] },
            { name: "Registrations", actions: [] }
          ]);

          getDogs();
          break;
        case "club_manager":
          setTabs([
            { name: "Account Information", actions: [] },
            { name: "Password", actions: [] }
          ]);
          break;
      }

      getUserTransactions(user._id, tranLimit, tranSkip);

      setLoaded(true);
    }
  }, [loaded, pUserType, user.user_type, navigate, getUserTransactions, tranSkip, user._id, getDogs]);

  const renderPersonalInfo = () => {
    return (<>
      <div className="settingsTab">
        <div className="description">
          <div className="title">{user.user_type === Constants.user_type.club_manager ? 'Account Information': 'Personal Information'}</div>
          <div className="text">Keep your personal information up-to-date and secure with your personal information settings.</div>
          {!user?.is_email_verified &&
            <div className="accountWarning">
              <div className="iconWrapWarning"><div className="icon alert"></div></div>
              <div className="details">
                <span>Your email isn't verified, this may hinder some functionality on Eagerdog. <Link to="/verify-email?r=/account&sendOnLoad=1">Verify Email</Link></span>
              </div>
            </div>
          }
        </div>
        <form className="inputs" onSubmit={(e) => { e.preventDefault(); updateUserInfo(); }}>
          {user.user_type === Constants.user_type.club_manager && <div className="inputRow">
            <Input required label="Account Name" id="displayName" type="text" onChange={(e) => { e.stopPropagation(); setDisplayName(e.target.value); setUserInfoChanged(true); }} defaultValue={displayName} placeholder="Update your clubs name" />
          </div>}
          {user.user_type !== Constants.user_type.club_manager &&  <div className="inputRow">
            <Input label="First Name" id="firstName" type="text" onChange={(e) => { e.stopPropagation(); setFirstName(e.target.value); setUserInfoChanged(true); }} defaultValue={firstName} placeholder="Update your first name" />            
          </div>}
          {user.user_type !== Constants.user_type.club_manager && <div className="inputRow">
            <Input label="Last Name" id="lastName" type="text" onChange={(e) => { e.stopPropagation(); setLastName(e.target.value); setUserInfoChanged(true); }} defaultValue={lastName} placeholder="Update your last name" />            
          </div>}
          <div className="inputRow">
            <Phone label="Phone Number" value={phone} onChange={(e: any) => { setPhone(e); setUserInfoChanged(true); }} />
          </div>
          <div className="inputRow">            
            <div className={!user.is_email_verified ? "emailWrap unverified" : "emailWrap"}><Input disabled required label="Email" onChange={(e:any) => {  }} defaultValue={user?.email} type="email" placeholder="Enter your email" />{!user?.is_email_verified && <div className="iconWrap"><div className="icon alert"></div></div>}</div>
          </div>
          <div className="inputRow">
            <Input label="Website" id="website" type="text" onChange={(e) => { e.stopPropagation(); setWebsite(e.target.value); setUserInfoChanged(true); }} defaultValue={user?.website} placeholder="Update your website" />            
          </div>
          <div className="inputRow">
          <Input label="Address Line 1" id="line1" type="text" onChange={(e) => { e.stopPropagation(); setLine1(e.target.value); setUserInfoChanged(true); }} defaultValue={line1} placeholder="Address Line 1" />
          </div>
          <div className="inputRow">
            <Input label="Line 2" id="line2" type="text" onChange={(e) => { e.stopPropagation(); setLine2(e.target.value); setUserInfoChanged(true); }} defaultValue={line2} placeholder="Line 2" />
          </div>
          <div className="inputRow">
            <Input label="City" id="city" type="text" onChange={(e) => { e.stopPropagation(); setCity(e.target.value); setUserInfoChanged(true); }} defaultValue={city} placeholder="City" /> 
          </div>
          <div className="inputRow">            
            <Dropdown value={state} onChange={(e: any, value: IOption) => { setState(value); setUserInfoChanged(true); }} icon={flagIcon} label="Province / State" options={country.value === "USA" ? States : Provinces} placeholder="Choose your state/province of residence" />
          </div>
          <div className="inputRow">
            <Dropdown value={country} onChange={(e: any, value: IOption) => { setCountry(value); }} icon={flagIcon} label="Country" options={[{ value: "Canada", id: "Canada" }, { value: "USA", id: "USA" }]} placeholder="Choose your country of residence" />
          </div>
          <div className="inputRow">
            <Input label="Postal/Zip Code" id="postalCode" type="text" onChange={(e) => { e.stopPropagation(); setPostalCode(e.target.value); setUserInfoChanged(true); }} defaultValue={postalCode} placeholder="Postal Code" />
          </div>
          {user?.is_junior && <>
            <div className="inputRow">
              <Input required label="Parents Name" id="parentName" type="text" onChange={(e) => { e.stopPropagation(); setUserInfoChanged(true); setParentName(e.target.value); }} defaultValue={parentName} placeholder="" />
            </div>
            <div className="inputRow">
              <Input label="AKC Junior #" id="akcJuniorNum" type="text" onChange={(e) => { e.stopPropagation(); setUserInfoChanged(true); setAkcJuniorNum(e.target.value); }} defaultValue={akcJuniorNum} placeholder="" />
            </div>
            <div className="inputRow">
              <Input label="UKC Junior #" id="ukcJuniorNum" type="text" onChange={(e) => { e.stopPropagation(); setUserInfoChanged(true); setUkcJuniorNum(e.target.value); }} defaultValue={ukcJuniorNum} placeholder="" />
            </div>
            <div className="inputRow">
              <Input label="CKC Junior #" id="ckcJuniorNum" type="text" onChange={(e) => { e.stopPropagation(); setUserInfoChanged(true); setCkcJuniorNum(e.target.value); }} defaultValue={ckcJuniorNum} placeholder="" />
            </div>
          </>}
          {user.user_type !== Constants.user_type.club_manager && <div className="inputRow">
            <Input label="Emergency Contact Name" id="eContactName" type="text" onChange={(e) => { e.stopPropagation(); setEContactName(e.target.value); setUserInfoChanged(true); }} defaultValue={eContactName} placeholder="Emergency Contact Name" />
          </div>}
          {user.user_type !== Constants.user_type.club_manager && <div className="inputRow">
            <Phone label="Emergency Contact Phone Number" value={ePhone} onChange={(e: any) => { setEPhone(e); setUserInfoChanged(true); }} />
          </div>}
          {user.user_type === Constants.user_type.club_manager && <div className="inputRow">            
            <Input label="Club ID" id="clubID" type="text" onChange={(e) => { e.stopPropagation(); setClubID(e.target.value); setUserInfoChanged(true); }} defaultValue={clubID} placeholder="Club ID" />
          </div>}
          <div className="actions">
            <Button type="submit" disabled={!userInfoChanged} isLoading={isUpdatingPersonalInfo}>Save</Button>
          </div>
        </form>
      </div>
    </>);
  }

  const renderPassword = () => {
    const passwordsMatch = password.length > 0 && repeat.length > 0 && password === repeat;
    return (<>
      <div className="settingsTab">
        <div className="description">
          <div className="title">Password</div>
          <div className="text">Update your password and keep your account safe.</div>
        </div>
        <div className="inputs">
          <Input onChange={(e) => { setPassword(e.target.value); }} required type="password" icon={keyIcon} label="Password" placeholder="Enter your password" />
          <Input onChange={(e) => { setRepeat(e.target.value); }} required type="password" icon={keyIcon} label="Repeated Password" placeholder="Enter your password again" />
          <div className="actions">
            <Button disabled={!passwordsMatch} onClick={updateUserPassword} isLoading={/*isUpdatingPassword*/false}>Update Password</Button>
          </div>
        </div>
      </div>
    </>);
  }

  const getDogData = () => {
    return dogs.map((dog: IDog, i: number) => {
      const row = {
        dog: <DogPortrait dog={dog} showName={false} />,
        name: dog.call_name,
        breed: dog.breed,
        // registration_numbers: dog.registration_numbers.map((num) => { return num.name +" "+ num.value + (num.type !== undefined ? " (" + num.type + ")" : "") }).join(", "),
        actions: [
          { icon: "delete", onClick: () => { setCurrentDog(dog); toggle(); } },
          { icon: "pencil", onClick: () => { onEditDog(dog) } }
        ]
      }
      return row;
    });
  }

  const getTransactionData = () => {
    return userTransactions.map((t:IUserTransactionSummary, i: number) => {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: t.amounts.currency.toUpperCase() || "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });

      const row = {
        created_at: moment(t.created_at).format("MMMM Do, YYYY  h:mm a"),
        status: <span className={"status " + t.status}>{t.status}</span>,
        amount: formatter.format(t.amounts.processed_total_amount ? t.amounts.processed_total_amount / 100 : t.amounts.calculated_total_amount / 100),
        event_name: <Link to={"/e/" + t.event.handle}>{t.event.title}</Link>,
        actions: t.status === "success" ? [
          {
            icon: "download",
            onClick: () => {
              if (t) {
                download.pushDownload({
                  type: "transaction",
                  transactionId: t._id
                });
              }
            }
          }
        ] : []
      }

      return row;
    });
  }

  const onEditDog = (dog: IDog) => {
    setCurrentDog(dog);
    setShowDogModal(true);
  }

  const renderDogs = () => {
    return (<>
      <div className="settingsTab">
        <div className="description">
          <div className="title">My Dogs</div>
          <div className="text">Keep your dogs information up-to-date and secure with your dog settings.</div>
          <div className="dog-actions">
            <Button onClick={() => setShowDogModal(true)}>Add Dog</Button>
          </div>
        </div>
        <div className="inputs my-dogs">
          <Table headers={dogTableHeaders} data={getDogData()} emptyMsg={<div className="emptyTable"><span>No Dogs Found</span></div>} />
        </div>
      </div>
    </>);
  }

  const renderTransactions = () => {
    return (<>
      <div className="settingsTab tableOnly">
        <div className="inputs">
          <Table
            headers={transactionHeaders}
            data={getTransactionData()}
            emptyMsg={<div className="emptyTable"><span>No Transactions Found</span></div>}
            onPageNext={() => {
              if (user) {
                getUserTransactions(user._id, tranLimit, tranSkip + 10); setTranSkip(tranSkip + 10);
              }
            }
            }
            onPagePrevious={() => {
              if (user) {
                getUserTransactions(user._id, tranLimit, Math.max(tranSkip - 10, 0)); setTranSkip(Math.max(tranSkip - 10, 0));
              }
            }
            }
            limit={tranLimit}
            skip={tranSkip}
          />
        </div>
      </div>
    </>);
  }

  return (
    <div className="Account ManagePage">
      <Helmet>
        <title>Account Management</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="accountInner">
        <div className="breadcrumb"><div className="path"><span>Profile</span> /</div><div className="current">Settings</div></div>
        <h1>Settings</h1>
        {user.user_type === "owner" ? renderOwnerTabs() : renderManagerTabs()}
      </div>
      <Modal
        className={"dogForm"}
        isShown={showDogModal}
        hide={() => { setShowDogModal(false); setCurrentDog(undefined); }}
        modalContent={<DogForm onFinish={() => { getDogs(); setShowDogModal(false); setCurrentDog(undefined); }} dog={currentDog} />}
      />
      <Modal className="Confirmation" isShown={isShown} hide={toggle} modalContent={
        <Confirm 
          message={<><p>Are you sure you want to delete this item?</p></>}
          onConfirm={handleConfirm} 
          onCancel={handleCancel} 
        />
      } />
    </div>
  );
};

export default Account;