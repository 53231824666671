import React from 'react';
import Button from '../Button/Button';

import './Confirm.scss';

interface IProps {
  message: string | JSX.Element;
  onConfirm: () => void;
  onCancel: () => void;
  buttonsDisabled?: boolean;
}

const Confirm: React.FC<IProps> = (props) => {
  return (
    <div className="Confirm">
      <div className="title">Confirm</div>
      {props.message}
      <div className="button-group">
        <Button disabled={props.buttonsDisabled ? props.buttonsDisabled : false} onClick={props.onCancel}>Cancel</Button>
        <Button disabled={props.buttonsDisabled ? props.buttonsDisabled : false} onClick={props.onConfirm}>Confirm</Button>
      </div>
    </div>
  );
}

export default Confirm;