import React, { useMemo, useCallback } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Constants } from "@eagerdog/constants";
import { helperService } from "../../services/helper.service";

import { IEventSummary, IAddress, IDog } from "@eagerdog/interfaces";
import Event from "../../routes/Event/Event";

import HorizontalScroll from "../HorizontalScroll/HorizontalScroll";
import DogPortrait from "../DogPortrait/DogPortrait";
import { Modal, useModal } from "../Modal/Modal";

import "./EventCard.scss";

interface IEventCardPrice {
  amount: number,
  isEarlyBird: boolean,
  earlyBirdEnd?: Date
}

interface IProps {
  event: IEventSummary,
  action: { text: string; link: string },
  isAdmin?: boolean
}

const EventCard: React.FC<IProps> = (props) => {
  const { isShown, toggle } = useModal();

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: props.event.currency?.toUpperCase() || "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const eventModalContent = (
    <React.Fragment>
      <div className="scrollWrap">
        <Event eid={props.event.handle} />
      </div>
    </React.Fragment>
  );

  const formatAddress = (address: IAddress) => {
    let result: string = "";

    if (address.line1) {
      result += address.line1 + ", ";
    }

    if (address.line2) {
      result += address.line2 + ", ";
    }

    if (address.city) {
      result += address.city + ", ";
    }

    if (address.state) {
      result += address.state + ", ";
    }

    if (address.country) {
      result += address.country;
    }

    return result;
  };

  const getShowTypes = () => {
    let _showTypes: string[] = [];

    for (let s in props.event.show_types) {
      if (!_showTypes.includes(props.event.show_types[s].show_type))
        _showTypes.push(props.event.show_types[s].show_type);
    }
  
    return _showTypes;
  };

  const getMin = useCallback(
    (event: IEventSummary) => {
      let min:IEventCardPrice = { amount: 99999, isEarlyBird: false };

      for (let s in props.event.show_types) {
        if (props.event.show_types[s].early_bird_fee) {
          let currentDate = new Date();

          if (props.event.show_types[s].early_bird_fee ? props.event.show_types[s].early_bird_fee : 99999 < min.amount) {
            const getAmount = () => {
              const showType = props.event.show_types[s];

              if (showType && showType?.early_bird_end_date) {
                return currentDate <= new Date(showType.early_bird_end_date) ? showType.early_bird_fee : showType.fee;
              }
            }

            if (moment(currentDate).format("X") > moment(props.event.show_types[s].early_bird_end_date).format("X")) {
              min = { amount: getAmount() || 99999, isEarlyBird: false };
            } else {
              min = { amount: getAmount() || 99999, isEarlyBird: true };
            }
            

            if (props.event.show_types[s].early_bird_end_date) {
              min["earlyBirdEnd"] = props.event.show_types[s].early_bird_end_date;
            }
          }
        } else if (props.event.show_types[s].fee < min.amount) {
          min = { amount: props.event.show_types[s].fee, isEarlyBird: false };
        }
      }

      return min;
    },
    [props.event.show_types]
  );

  const getMax = useCallback(
    (event: IEventSummary) => {
      let max:IEventCardPrice = { amount: -1, isEarlyBird: false };

      for (let s in props.event.show_types) {
        if (props.event.show_types[s].fee > max.amount) {
          max = { amount: props.event.show_types[s].fee, isEarlyBird: false };
        }
      }

      return max;
    },
    [props.event.show_types]
  );

  const min:IEventCardPrice = useMemo(() => getMin(props.event), [getMin, props.event]);
  const max:IEventCardPrice = useMemo(() => getMax(props.event), [getMax, props.event]);

  return (
    <div className="EventCard">
      <div className="name" onClick={(e) => { window.history.pushState("", "", "/e/" + props.event.handle); toggle(); }}>{props.event.title}</div>
      <div className="club">{props.event.club_name ?? props.event.club.club_name}</div>
      <span className={"eventStatus " + props.event.status.toLowerCase()}>{helperService.getEventStatus(props.event.status)}</span>
      <div className="eventType">{props.event.sanctioning_club}</div>
      <div className="date">
        <span>
          {moment(props.event.start_date).format("MMM D, YYYY")} - {moment(props.event.end_date).format("MMM D, YYYY")}
        </span>
      </div>
      <div className="location">{formatAddress(props.event.address)}</div>
      {getShowTypes().length > 0 && <HorizontalScroll>
        {getShowTypes().map((show: string, index: number) => {
          return (
            <li key={index} className="show">
              {show}
            </li>
          );
        })}
      </HorizontalScroll>}
      {getShowTypes().length === 0 && <HorizontalScroll>
        <li className="show">No Show Types</li>
      </HorizontalScroll>}
      {!props.event?.is_registered && props.event?.status === Constants.event_status.published && <div className="price">
        <div className="range">
          {(props.event.currency === "usd" || props.event.currency === "cad" || props.event.currency === undefined) && <>
            {(min.amount !== max.amount && max.amount > 0) && (
              <><div className="minPrice"><span>$</span>{min.amount}</div>-<div className="maxPrice"><span>$</span>{max.amount}</div></>
            )}
            {min.amount === max.amount && (
              <div className="minPrice"><span>$</span>{min.amount}</div>
            )}
            {(min.amount !== max.amount && max.amount < 0) && (
              <><div className="minPrice">No Price Set</div></>
            )}
          </>}
          {(props.event.currency !== "usd" && props.event.currency !== "cad" && props.event.currency !== undefined) && <>
            {(min.amount !== max.amount && max.amount > 0) && (
              <><div className="minPrice">{formatter.format(min.amount)}</div>-<div className="maxPrice">{formatter.format(max.amount)}</div></>
            )}
            {min.amount === max.amount && (
              <div className="minPrice">{formatter.format(min.amount)}</div>
            )}
            {(min.amount !== max.amount && max.amount < 0) && (
              <><div className="minPrice">No Price Set</div></>
            )}
          </>}
          {min.isEarlyBird && <span className="early-bird">Early Bird</span>}
        </div>
        {<div className="disclaimer">prices in {props.event.currency?.toUpperCase() || "USD"}{min.earlyBirdEnd && moment(new Date()).format("X") < moment(min.earlyBirdEnd).format("X") && ", pre-entry end date " + moment(min.earlyBirdEnd).format("MM/DD/YYYY")}</div>}
      </div>}
      {!props.event?.is_registered && props.event?.status === Constants.event_status.draft && <div className="price">
        <div className="openDateLabel">Event Opens On</div>
        <div className="openDate">{moment(props.event.publish_at).format("MMM Do, YYYY")}</div>
      </div>}
      {props.event?.is_registered && <div className="price registeredDogs">
        <ul>
          {props?.event?.registered_dogs?.map((dog: IDog, index:number) => {
            return(<li key={index}><DogPortrait showName={false} dog={dog} /></li>);
          })}
          <li className="tag">Registered</li>
        </ul>
      </div>}
      <div className="action">
        <Link to={props.action.link}>{props.action.text}</Link>
      </div>
      <Modal
        className="eventModal"
        isShown={isShown}
        hide={() => {
          toggle();
          window.history.pushState("", "", "/");
        }}
        expand={true}
        modalContent={eventModalContent}
      />
    </div>
  );
};

export default EventCard;
